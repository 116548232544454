/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneyOpenTenderResponse {
  id: number;
  name: string;
  description: string;
  deadline: DateTime;
  budgetItemId: number;
}

export const MoneyOpenTenderResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: 'Azonosító',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Pályázat neve',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Leírás',
    type: 'string',
  },
  'deadline': {
    name: 'deadline',
    required: true,
    nullable: false,
    label: 'Leadási határidő',
    type: 'datetime',
  },
  'budgetItemId': {
    name: 'budgetItemId',
    required: true,
    nullable: false,
    label: 'Házszintű tétel azonosítója',
    type: 'number',
      minimum: 1,
  },
}
export function instanceOfMoneyOpenTenderResponse(value: object): value is MoneyOpenTenderResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "deadline" in value;
    isInstance = isInstance && "budgetItemId" in value;

return isInstance;
}

export function MoneyOpenTenderResponseFromJSON(json: any): MoneyOpenTenderResponse {
return MoneyOpenTenderResponseFromJSONTyped(json, false);
}

export function MoneyOpenTenderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyOpenTenderResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
          'description': json['description'],
        'deadline': (DateTime.fromISO(json['deadline'])),
          'budgetItemId': json['budgetItemId'],
    };
  }

  export function MoneyOpenTenderResponseToJSON(value?: MoneyOpenTenderResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
                'description': value.description,
            'deadline': (value.deadline.toISO()),
                'budgetItemId': value.budgetItemId,
    };
  }

  export function MoneyOpenTenderResponseCreateEmpty(): MoneyOpenTenderResponse {
  return {
      'id': 0,
      'name': '',
      'description': '',
      'deadline': DateTime.now(),
      'budgetItemId': 0,
  };
  }

