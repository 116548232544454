<template>
    <PegasusModal header="Költés elszámolva">
        <PegasusForm ref="form">
            <PegasusForm part="data">
                <SwitchField
                    v-model="event.normal"
                    :schema="MoneySpendingEventSpendingSettledSchema.normal"
                    inline
                ></SwitchField>
            </PegasusForm>

            <hr>

            <TextField
                v-model="event.comment"
                :schema="MoneySpendingItemEventSchema.comment"
            ></TextField>

            <PegasusButton variant="success" @click="save">
                <i class="fa fa-save"></i> Mentés
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import PegasusModal from '@Components/base/PegasusModal.vue';
import TextField from '@Components/base/form/types/TextField.vue';
import {MoneySpendingEventSpendingSettledSchema, MoneySpendingItemEventSchema} from '@/api/models';
import {ref} from 'vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import toast from '@Utils/toast';
import type {FormRef} from '@/vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import {useReturn} from '@Utils/dialog';
import type {SpendingItem} from '@Models/money/SpendingItem';
import {SpendingSettled} from '@Models/money/spendingItemEvents/SpendingSettled';
import SwitchField from '@Components/base/form/types/SwitchField.vue';

const {spendingItem} = defineProps<{
    spendingItem: SpendingItem
}>();

const event = ref(new SpendingSettled());

const form = ref<FormRef>(null);

const returnValue = useReturn();

async function save() {
    if (!await toast.confirm('Biztos vagy benne?')) {
        return;
    }

    const request = event.value.store(spendingItem.id, form.value).then(() => {
        spendingItem.events[event.value.id] = event.value;
        returnValue(true);
    });

    toast.loading(request);
}
</script>
