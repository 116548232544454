import {BaseEntity} from '@Models/BaseEntity';
import {type MoneyBudgetProgramMilestoneResponse, MoneyBudgetProgramMilestoneType} from '@/api/models';
import {User} from '@Models/base/User';
import {DateTime} from '@Utils/DateTime';

export class BudgetProgramMilestone extends BaseEntity {
    type: MoneyBudgetProgramMilestoneType;

    user: User;

    comment: string | null;

    modifyData: Record<string, string> | null;

    createdAt: DateTime;

    static parseResponse(milestone: BudgetProgramMilestone, data: MoneyBudgetProgramMilestoneResponse): BudgetProgramMilestone {
        milestone.id = data.id;
        milestone.type = data.type;
        milestone.user = User.newSingle(data.user, User.parseMinimalResponse);
        milestone.createdAt = data.createdAt;
        milestone.comment = data.comment;

        return milestone;
    }

    static getIcons(): Record<MoneyBudgetProgramMilestoneType, string> {
        return {
            [MoneyBudgetProgramMilestoneType.CREATE]: 'fa-plus-circle bg-primary',
            [MoneyBudgetProgramMilestoneType.SUBMIT]: 'fa-paper-plane bg-info',
            [MoneyBudgetProgramMilestoneType.APPROVE]: 'fa-gavel bg-success',
            [MoneyBudgetProgramMilestoneType.REJECT]: 'fa-gavel bg-danger',
            [MoneyBudgetProgramMilestoneType.FINISH]: 'fa-check bg-primary',
            [MoneyBudgetProgramMilestoneType.ARCHIVE]: 'fa-box-archive bg-primary',
        };
    }
}
