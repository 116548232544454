<template>
    <PegasusHeading>{{ tender.displayName }} keretigényei</PegasusHeading>

    <PegasusPanel class="mb-3" header="Adatok">
        <div class="row">
            <div class="col-lg-4">
                <div>
                    <dt>Keretigények száma</dt>
                    <dd>{{ size(claims) }}</dd>
                    <dt>Elfogadott keretigények összege</dt>
                    <dd>{{ sum().huf() }}</dd>
                </div>
            </div>

            <div class="col-lg-4">
                <PegasusButton :params="{tender}" class="m-2" to="rvt.kpr.tender.show" variant="success">
                    <i class="fas fa-undo"></i> Vissza a KPR oldalára
                </PegasusButton>
            </div>
        </div>
    </PegasusPanel>

    <PegasusPanel header="Keretigények">
        <PegasusTable :data="claims" :per-page="10" :row-variant="rowClass" search>
            <Column field="idAsText" header="ID"></Column>
            <Column :field="item => item.createdAt.datetime()" header="Dátum"></Column>

            <Column field="group.name" header="Kör"></Column>
            <Column :field="item => item.objective?.limit(50)" header="Cél"></Column>
            <Column :field="item => item.sum.huf()" header="Összeg"></Column>

            <Column header="Műveletek">
                <template #body="{data}">
                    <PegasusLink :params="{claim: data}" to="rvt.claims.show" variant="success">
                        <i class="fas fa-eye"></i> Megnyitás
                    </PegasusLink>
                </template>
            </Column>
        </PegasusTable>
    </PegasusPanel>
</template>

<title>RVT Támogatási kérvények</title>

<script lang="ts" setup>
import {Claim} from "@Models/rvt/requests/Claim";
import {sumObject} from "@Models/Utils";
import {size} from "lodash-es";
import Column from "primevue/column";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusLink from "@Components/base/PegasusLink.vue";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import {useAuthStore} from "@/stores/auth";
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {ref} from 'vue';
import {Tender} from '@Models/rvt/kpr/Tender';

const {tenderId} = defineProps<{
    tenderId: number
}>();

useAuthStore().requireUser();

const tender = ref(await Tender.getById(tenderId));

const claims = ref(await Claim.getAllInTender(tenderId));

function rowClass(item: Claim) {
    if (item.decision === null) {
        return null;
    }

    return item.decision ? 'success' : 'danger';
}

function sum() {
    return sumObject(claims.value, claim => {
        return claim.decision ? claim.sum : 0;
    });
}
</script>
