/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneyOpenTenderUpdate {
  name: string;
  description: string;
  deadline: DateTime;
}

export const MoneyOpenTenderUpdateSchema = {
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Pályázat neve',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Leírás',
    type: 'string',
  },
  'deadline': {
    name: 'deadline',
    required: true,
    nullable: false,
    label: 'Leadási határidő',
    type: 'datetime',
  },
}
export function instanceOfMoneyOpenTenderUpdate(value: object): value is MoneyOpenTenderUpdate {
let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "deadline" in value;

return isInstance;
}

export function MoneyOpenTenderUpdateFromJSON(json: any): MoneyOpenTenderUpdate {
return MoneyOpenTenderUpdateFromJSONTyped(json, false);
}

export function MoneyOpenTenderUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyOpenTenderUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'name': json['name'],
          'description': json['description'],
        'deadline': (DateTime.fromISO(json['deadline'])),
    };
  }

  export function MoneyOpenTenderUpdateToJSON(value?: MoneyOpenTenderUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'name': value.name,
                'description': value.description,
            'deadline': (value.deadline.toISO()),
    };
  }

  export function MoneyOpenTenderUpdateCreateEmpty(): MoneyOpenTenderUpdate {
  return {
      'name': '',
      'description': '',
      'deadline': DateTime.now(),
  };
  }

