/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { KprTenderResponse } from './KprTenderResponse';
    import {
    KprTenderResponseFromJSON,
    KprTenderResponseFromJSONTyped,
    KprTenderResponseToJSON,
    KprTenderResponseCreateEmpty,
    } from './KprTenderResponse';
import {DateTime} from '@Utils/DateTime';

export interface KprTenderSummaryResponse {
  tender: KprTenderResponse;
  applicationsCount: number;
  declinedAmends: number;
  successfulAmends: number;
  amendsBalance: number;
  claimsSum: number;
  claimsBudget: number;
  claimsBalance: number;
  declinedClaims: number;
  successfulClaims: number;
}

export const KprTenderSummaryResponseSchema = {
  'tender': {
    name: 'tender',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'applicationsCount': {
    name: 'applicationsCount',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'declinedAmends': {
    name: 'declinedAmends',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'successfulAmends': {
    name: 'successfulAmends',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'amendsBalance': {
    name: 'amendsBalance',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'claimsSum': {
    name: 'claimsSum',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'claimsBudget': {
    name: 'claimsBudget',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'claimsBalance': {
    name: 'claimsBalance',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'declinedClaims': {
    name: 'declinedClaims',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'successfulClaims': {
    name: 'successfulClaims',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
}
export function instanceOfKprTenderSummaryResponse(value: object): value is KprTenderSummaryResponse {
let isInstance = true;
    isInstance = isInstance && "tender" in value;
    isInstance = isInstance && "applicationsCount" in value;
    isInstance = isInstance && "declinedAmends" in value;
    isInstance = isInstance && "successfulAmends" in value;
    isInstance = isInstance && "amendsBalance" in value;
    isInstance = isInstance && "claimsSum" in value;
    isInstance = isInstance && "claimsBudget" in value;
    isInstance = isInstance && "claimsBalance" in value;
    isInstance = isInstance && "declinedClaims" in value;
    isInstance = isInstance && "successfulClaims" in value;

return isInstance;
}

export function KprTenderSummaryResponseFromJSON(json: any): KprTenderSummaryResponse {
return KprTenderSummaryResponseFromJSONTyped(json, false);
}

export function KprTenderSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KprTenderSummaryResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'tender': KprTenderResponseFromJSON(json['tender']),
          'applicationsCount': json['applicationsCount'],
          'declinedAmends': json['declinedAmends'],
          'successfulAmends': json['successfulAmends'],
          'amendsBalance': json['amendsBalance'],
          'claimsSum': json['claimsSum'],
          'claimsBudget': json['claimsBudget'],
          'claimsBalance': json['claimsBalance'],
          'declinedClaims': json['declinedClaims'],
          'successfulClaims': json['successfulClaims'],
    };
  }

  export function KprTenderSummaryResponseToJSON(value?: KprTenderSummaryResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'tender': KprTenderResponseToJSON(value.tender),
                'applicationsCount': value.applicationsCount,
                'declinedAmends': value.declinedAmends,
                'successfulAmends': value.successfulAmends,
                'amendsBalance': value.amendsBalance,
                'claimsSum': value.claimsSum,
                'claimsBudget': value.claimsBudget,
                'claimsBalance': value.claimsBalance,
                'declinedClaims': value.declinedClaims,
                'successfulClaims': value.successfulClaims,
    };
  }

  export function KprTenderSummaryResponseCreateEmpty(): KprTenderSummaryResponse {
  return {
          'tender': KprTenderResponseCreateEmpty(),
      'applicationsCount': 0,
      'declinedAmends': 0,
      'successfulAmends': 0,
      'amendsBalance': 0,
      'claimsSum': 0,
      'claimsBudget': 0,
      'claimsBalance': 0,
      'declinedClaims': 0,
      'successfulClaims': 0,
  };
  }

