/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneyBudgetCategoryResponse {
  id: number;
  name: string;
  description: string;
  prefix: string | null;
  number: number;
}

export const MoneyBudgetCategoryResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: 'ID',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Név',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Leírás',
    type: 'string',
  },
  'prefix': {
    name: 'prefix',
    required: true,
    nullable: true,
    label: 'Prefix',
    type: 'string',
  },
  'number': {
    name: 'number',
    required: true,
    nullable: false,
    label: 'Sorszám',
    type: 'number',
  },
}
export function instanceOfMoneyBudgetCategoryResponse(value: object): value is MoneyBudgetCategoryResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "prefix" in value;
    isInstance = isInstance && "number" in value;

return isInstance;
}

export function MoneyBudgetCategoryResponseFromJSON(json: any): MoneyBudgetCategoryResponse {
return MoneyBudgetCategoryResponseFromJSONTyped(json, false);
}

export function MoneyBudgetCategoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyBudgetCategoryResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
          'description': json['description'],
          'prefix': json['prefix'],
          'number': json['number'],
    };
  }

  export function MoneyBudgetCategoryResponseToJSON(value?: MoneyBudgetCategoryResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
                'description': value.description,
                'prefix': value.prefix,
                'number': value.number,
    };
  }

  export function MoneyBudgetCategoryResponseCreateEmpty(): MoneyBudgetCategoryResponse {
  return {
      'id': 0,
      'name': '',
      'description': '',
      'prefix': '',
      'number': 0,
  };
  }

