/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface InventoryTerminationDecision {
  termination: number;
  decision: boolean;
  comment: string;
}

export const InventoryTerminationDecisionSchema = {
  'termination': {
    name: 'termination',
    required: true,
    nullable: false,
    label: 'Kivezetési kérelem',
    type: 'number',
      minimum: 0,
  },
  'decision': {
    name: 'decision',
    required: true,
    nullable: false,
    label: 'Döntés',
    type: 'boolean',
  },
  'comment': {
    name: 'comment',
    required: true,
    nullable: false,
    label: 'Megjegyzés',
    type: 'string',
      maxLength: 1000,
  },
}
export function instanceOfInventoryTerminationDecision(value: object): value is InventoryTerminationDecision {
let isInstance = true;
    isInstance = isInstance && "termination" in value;
    isInstance = isInstance && "decision" in value;
    isInstance = isInstance && "comment" in value;

return isInstance;
}

export function InventoryTerminationDecisionFromJSON(json: any): InventoryTerminationDecision {
return InventoryTerminationDecisionFromJSONTyped(json, false);
}

export function InventoryTerminationDecisionFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryTerminationDecision {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'termination': json['termination'],
          'decision': json['decision'],
          'comment': json['comment'],
    };
  }

  export function InventoryTerminationDecisionToJSON(value?: InventoryTerminationDecision | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'termination': value.termination,
                'decision': value.decision,
                'comment': value.comment,
    };
  }

  export function InventoryTerminationDecisionCreateEmpty(): InventoryTerminationDecision {
  return {
      'termination': 0,
      'decision': false,
      'comment': '',
  };
  }

