<template>
    <PegasusHeading>{{ period.name }} - {{ summary.group.name }}</PegasusHeading>

    <PegasusPanel v-if="summary.tender" :header="summary.tender.displayName">
        <div v-if="!summary.group.kprEnabled">A kör számára nem engedélyezett ez a funkció</div>

        <div v-else class="row">
            <div class="col-lg-6">
                <dt>KPR időszak</dt>
                <dd>{{ summary.tender.status }}</dd>
                <div v-if="summary.application">
                    <dt>Pályázat</dt>
                    <dd>
                        <PegasusLink :params="{application: summary.application}" to="rvt.kpr.application">
                            Megnyitás
                        </PegasusLink>
                    </dd>

                    <table class="table">
                        <thead>
                            <tr>
                                <th>Kategória</th>
                                <th>Elfogadott összeg</th>
                                <th>Leadott összeg</th>
                                <th>Műveletek</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="capp in summary.application.categories" :key="capp.id">
                                <td>{{ capp.category.name }}</td>
                                <td>{{ capp.itemGroup.calculateSumDecided().huf() }}</td>
                                <td>{{ capp.itemGroup.calculateSum().huf() }}</td>
                                <td>
                                    <a class="me-1" @click.prevent="Dialog.open(ShortCategoryDialog, {capp})">
                                        <i class="fa fa-eye"></i>
                                    </a>

                                    <PegasusLink :params="{application: summary.application, category: capp.category.shortName}" target="_blank" to="rvt.kpr.edit">
                                        <i class="fa fa-external-link-alt"></i>
                                    </PegasusLink>
                                </td>
                            </tr>

                            <tr v-if="isEmpty(summary.application.categories)">
                                <td colspan="4">Üres pályázat</td>
                            </tr>
                        </tbody>
                    </table>

                    <dt>Módosítások</dt>

                    <table class="table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Egyenleg</th>
                                <th>Állapot</th>
                                <th>Műveletek</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="amend in summary.amends" :key="amend.id">
                                <td>#{{ amend.id }}</td>
                                <td>{{ amend.balance.huf() }}</td>
                                <td>{{ amend.status }}</td>
                                <td>
                                    <a class="me-1" @click.prevent="Dialog.open(ShortAmendDialog, {amend})">
                                        <i class="fa fa-eye"></i>
                                    </a>
                                    <PegasusLink :params="{amend}" target="_blank" to="rvt.amends.show">
                                        <i class="fa fa-external-link-alt"></i>
                                    </PegasusLink>
                                </td>
                            </tr>

                            <tr v-if="isEmpty(summary.amends)">
                                <td colspan="4">Nem tartozik kérvény a pályázathoz</td>
                            </tr>
                        </tbody>
                    </table>

                    <div v-if="summary.tender.amendable && canEdit">
                        <PegasusButton variant="primary" @click="createAmend">
                            Új módosító kérvény
                        </PegasusButton>
                    </div>
                </div>

                <div v-else>
                    <dt>Pályázat állapota</dt>
                    <dd>Nincsen pályázat leadva</dd>
                    <dd>
                        <PegasusButton v-if="summary.tender.hasDrafting() && canEdit" variant="success" @click="createKpr">
                            Pályázat létrehozása
                        </PegasusButton>
                    </dd>
                </div>
            </div>

            <div class="col-lg-6">
                <dt>Keretigények</dt>

                <table class="table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Összeg</th>
                            <th>Állapot</th>
                            <th>Műveletek</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="claim in summary.claims" :key="claim.id">
                            <td>#{{ claim.id }}</td>
                            <td>{{ claim.sum.huf() }}</td>
                            <td>{{ claim.status }}</td>
                            <td>
                                <a class="me-1" @click.prevent="Dialog.open(ShortClaimDialog, {claim})">
                                    <i class="fa fa-eye"></i>
                                </a>

                                <PegasusLink :params="{claim}" target="_blank" to="rvt.claims.show">
                                    <i class="fa fa-external-link-alt"></i>
                                </PegasusLink>
                            </td>
                        </tr>

                        <tr v-if="isEmpty(summary.claims)">
                            <td colspan="4">Nem tartozik kérvény az időszakhoz</td>
                        </tr>
                    </tbody>
                </table>

                <div v-if="period.isClaimable() && summary.group.kprEnabled && canEdit">
                    <PegasusButton variant="primary" @click="createClaim">Új keretigény</PegasusButton>
                </div>
            </div>
        </div>
    </PegasusPanel>

    <span v-else>Az időszakra nem tartozik KPR kiírás</span>

    <PegasusButton :params="{group: summary.group}" to="rvt.groups.show" variant="primary">
        Vissza a kör oldalára
    </PegasusButton>
</template>

<title>Kör gazdasági adatlap</title>

<script lang="ts" setup>
import {Amend} from "@Models/rvt/requests/Amend";
import {Claim} from "@Models/rvt/requests/Claim";
import {Application} from "@Models/rvt/kpr/Application";
import {GroupPeriodSummary} from "@Models/rvt/GroupPeriodSummary";
import {computed} from "vue";
import {useRouter} from "vue-router";
import {isEmpty} from "lodash-es";
import toast from "@Utils/toast";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusLink from "@Components/base/PegasusLink.vue";
import {useAuthStore} from "@/stores/auth";
import {BaseGroupSpecificPermission} from '@/api/models';
import {Dialog} from '@Utils/dialog';
import ShortAmendDialog from '@Components/rvt/requests/amends/ShortAmendDialog.vue';
import ShortCategoryDialog from '@Components/kpr/ShortCategoryDialog.vue';
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import ShortClaimDialog from '@Components/rvt/requests/claims/ShortClaimDialog.vue';

const {groupId, periodId} = defineProps<{
    groupId: number,
    periodId: number
}>();

const user = useAuthStore().requireUser();

const summary = await GroupPeriodSummary.getById(groupId, periodId);
const period = summary.period;

const canEdit = computed(() => user.value.hasGroupPermission(summary.group, BaseGroupSpecificPermission.MANAGE_MONEY));

const router = useRouter();

async function createKpr() {
    if (!await toast.confirm('Biztosan létre akarsz hozni KPR pályázatot?')) {
        return;
    }

    if (!summary.tender) {
        return;
    }

    toast.info('Pályázat létrehozása folyamatban');
    Application.create(summary.group).store(summary.tender.id).then(application => {
        toast.success('Pályázat létrehozása sikeres', 'Átirányítás folyamatban');

        router.push({name: 'rvt.kpr.application', params: {application: '' + application.id}});
    });
}

async function createClaim() {
    if (!await toast.confirm('Biztosan létre akarsz hozni RVT keretigényt?')) {
        return;
    }

    if (!summary.tender) {
        return;
    }

    toast.info('Keretigény létrehozása folyamatban');

    Claim.create(summary.group).store(summary.tender.id).then(claim => {
        toast.success('Keretigény létrehozása sikeres', 'Átirányítás folyamatban');

        router.push({
            name: 'rvt.claims.show',
            params: {
                claim: '' + claim.id,
            },
        });
    });
}

async function createAmend() {
    if (!summary.application) {
        return;
    }

    if (!await toast.confirm('Biztosan létre akarsz hozni KPR módosító kérvényt?')) {
        return;
    }

    toast.info('Módosító kérvény létrehozása folyamatban');

    Amend.create(summary.group).store(summary.application.id).then(amend => {
        toast.success('Módosító kérvény létrehozása sikeres', 'Átirányítás folyamatban');

        router.push({
            name: 'rvt.amends.show',
            params: {
                amend: '' + amend.id,
            },
        });
    });
}
</script>
