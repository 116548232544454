<template>
    <PegasusModal header="Költségvetés lezárása">
        <PegasusForm ref="form">
            A költségvetés lezárása után már nincs lehetőség költeni, az elszámolás elindul.

            <hr>

            <TextareaField
                v-model="comment"
                :schema="MoneyBudgetProgramMilestoneAddSchema.comment"
            ></TextareaField>

            <PegasusButton
                variant="primary"
                @click="finish"
            >
                <i class="fa fa-check"></i> Lezárás
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import PegasusModal from '@Components/base/PegasusModal.vue';
import {MoneyBudgetProgramMilestoneAddSchema} from '@/api/models';
import {ref} from 'vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import toast from '@Utils/toast';
import type {BudgetProgram} from '@Models/money/BudgetProgram';
import {useReturn} from '@Utils/dialog';
import TextareaField from '@Components/base/form/types/TextareaField.vue';
import type {FormRef} from '@/vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';

const {program} = defineProps<{
    program: BudgetProgram
}>();

const returnValue = useReturn();

const form = ref<FormRef>(null);

const comment = ref('');

async function finish() {
    if (!await toast.confirm('Biztosan lezárod a költségvetést?')) {
        return;
    }

    const request = program.finish(comment.value, form.value).then(() => {
        returnValue(true);
    });

    toast.loading(request, 'Lezárás');
}
</script>
