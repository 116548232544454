import {SpendingItemEvent} from '@Models/money/SpendingItemEvent';
import {
    type MoneySpendingEventCashOut, MoneySpendingEventType,
    type MoneySpendingItemEvent,
    type MoneySpendingItemEventResponse
} from '@/api/models';
import type {FormRef} from '@/vue';
import {MoneyApi} from '@/api/apis';

export class CashOut extends SpendingItemEvent {
    money: number;

    static parseResponse(cashOut: CashOut, data: MoneySpendingItemEventResponse): CashOut {
        SpendingItemEvent.parseResponse(cashOut, data);

        const specific = data.data as MoneySpendingEventCashOut;
        cashOut.money = specific.money;

        return cashOut;
    }

    public store(itemId: number, form: FormRef): Promise<CashOut> {
        const data: MoneySpendingItemEvent = {
            type: MoneySpendingEventType.CASH_OUT,
            data: {
                type: MoneySpendingEventType.CASH_OUT,
                money: this.money
            },
            comment: this.comment
        };

        return MoneyApi.spendingEventsCashOut(itemId, data, {form}).then(response => {
            return CashOut.parseResponse(this, response.data);
        });
    }

    get text(): {key: string, value: string}[] {
        return [
            {key: 'Összeg', value: this.money.huf()},
        ];
    }
}
