<template>
    <PegasusModal header="Új KPR pályázat létrehozása">
        <PegasusForm ref="form">
            <SelectField
                v-model="tender.spendingPeriodId"
                :options="periodOptions"
                :schema="KprTenderStoreSchema.spendingPeriod"
            ></SelectField>

            <h5>Kategóriák</h5>
            <template v-for="category in categories" :key="category.name">
                <div class="row">
                    <div class="col-lg-6">
                        <TextField
                            v-model="category.name"
                            :schema="KprCategoryStoreSchema.name"
                        ></TextField>
                    </div>
                    <div class="col-lg-6">
                        <TextField
                            v-model="category.shortName"
                            :schema="KprCategoryStoreSchema.shortName"
                        ></TextField>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6">
                        <SwitchField
                            v-model="category.requireContributions"
                            :schema="KprCategoryStoreSchema.requireContributions"
                            inline
                        ></SwitchField>
                    </div>
                    <div class="col-lg-6">
                        <PegasusButton size="sm" variant="danger">
                            <i class="fa fa-times"></i> Törlés
                        </PegasusButton>
                    </div>
                </div>

                <hr>
            </template>

            <PegasusButton variant="success" @click="save">
                <i class="fa fa-save"></i> Mentés
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import PegasusModal from '@Components/base/PegasusModal.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import {computed, ref} from 'vue';
import SelectField from '@Components/base/form/types/SelectField.vue';
import {KprCategoryStoreSchema, KprTenderStoreSchema} from '@/api/models';
import {useConstantsStore} from '@/stores/constants';
import {Tender} from '@Models/rvt/kpr/Tender';
import {map, reverse, sortBy} from 'lodash-es';
import {Category} from '@Models/rvt/kpr/Category';
import type {FormRef} from '@/vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import toast from '@Utils/toast';
import TextField from '@Components/base/form/types/TextField.vue';
import SwitchField from '@Components/base/form/types/SwitchField.vue';
import {redirect} from '@/router';
import {useReturn} from '@Utils/dialog';

const form = ref<FormRef>(null);

const returnValue = useReturn();

const constants = useConstantsStore().constants;

const tender = ref(new Tender());

const categories = ref(Category.getDefaultCategories());

const periodOptions = computed(() => {
    const periods = reverse(sortBy(constants.money.spendingPeriods, period => period.name));

    return map(periods, period => {
        return {
            value: period.id,
            label: period.name
        };
    });
});

async function save() {
    if (!await toast.confirm('Biztos vagy benne, hogy létrehozod a pályázatot?')) {
        return;
    }

    toast.info('Mentés folyamatban');

    tender.value.store(categories.value, form.value).then(async() => {
        toast.success('Létrehozás sikeres', 'Átirányítás folyamatban');

        await redirect({
            name: 'rvt.kpr.tender.admin',
            params: {
                tender: tender.value.id
            }
        });

        returnValue(true);
    });
}
</script>
