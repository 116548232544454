/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { KprCategoryStatus } from './KprCategoryStatus';
    import {
    KprCategoryStatusFromJSON,
    KprCategoryStatusFromJSONTyped,
    KprCategoryStatusToJSON,
    KprCategoryStatusCreateEmpty,
    } from './KprCategoryStatus';
import {DateTime} from '@Utils/DateTime';
import { KprCategoryStatusValues } from './KprCategoryStatus';

export interface KprCategoryResponse {
  id: number;
  name: string;
  shortName: string;
  status: KprCategoryStatus;
  requireContributions: boolean;
  endDrafting: DateTime;
  endResulting: DateTime;
  budget: number | null;
  budgetItemId: number | null;
}

export const KprCategoryResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'shortName': {
    name: 'shortName',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'status': {
    name: 'status',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "",
        enumValues: KprCategoryStatusValues,
  },
  'requireContributions': {
    name: 'requireContributions',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'endDrafting': {
    name: 'endDrafting',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'endResulting': {
    name: 'endResulting',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'budget': {
    name: 'budget',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'budgetItemId': {
    name: 'budgetItemId',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 0,
  },
}
export function instanceOfKprCategoryResponse(value: object): value is KprCategoryResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "shortName" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "requireContributions" in value;
    isInstance = isInstance && "endDrafting" in value;
    isInstance = isInstance && "endResulting" in value;
    isInstance = isInstance && "budget" in value;
    isInstance = isInstance && "budgetItemId" in value;

return isInstance;
}

export function KprCategoryResponseFromJSON(json: any): KprCategoryResponse {
return KprCategoryResponseFromJSONTyped(json, false);
}

export function KprCategoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KprCategoryResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
          'shortName': json['shortName'],
              'status': KprCategoryStatusFromJSON(json['status']),
          'requireContributions': json['requireContributions'],
        'endDrafting': (DateTime.fromISO(json['endDrafting'])),
        'endResulting': (DateTime.fromISO(json['endResulting'])),
          'budget': json['budget'],
          'budgetItemId': json['budgetItemId'],
    };
  }

  export function KprCategoryResponseToJSON(value?: KprCategoryResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
                'shortName': value.shortName,
                'status': KprCategoryStatusToJSON(value.status),
                'requireContributions': value.requireContributions,
            'endDrafting': (value.endDrafting.toISO()),
            'endResulting': (value.endResulting.toISO()),
                'budget': value.budget,
                'budgetItemId': value.budgetItemId,
    };
  }

  export function KprCategoryResponseCreateEmpty(): KprCategoryResponse {
  return {
      'id': 0,
      'name': '',
      'shortName': '',
          'status': KprCategoryStatusCreateEmpty(),
      'requireContributions': false,
      'endDrafting': DateTime.now(),
      'endResulting': DateTime.now(),
      'budget': 0,
      'budgetItemId': 0,
  };
  }

