/* tslint:disable */
/* eslint-disable */

export enum InventoryTerminationReason {
    SCRAP = 1,
    SOLD = 2,
    PLANNED = 3,
    UNPLANNED = 4,
    OTHER = 5
}

export const InventoryTerminationReasonValues: Record<InventoryTerminationReason, string> = {
    1: 'Selejtezés',
    2: 'Elajándékozás / eladás',
    3: 'Tervezett amortizáció (lejárt lízing, licensz, stb)',
    4: 'Nem tervezett amortizáció (elveszett)',
    5: 'Egyéb (adminisztrációs hiba, stb)'
}


export function InventoryTerminationReasonFromJSON(json: any): InventoryTerminationReason {
    return InventoryTerminationReasonFromJSONTyped(json, false);
}

export function InventoryTerminationReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryTerminationReason {
    return json as InventoryTerminationReason;
}

export function InventoryTerminationReasonToJSON(value?: InventoryTerminationReason | null): any {
    return value as any;
}

export function InventoryTerminationReasonCreateEmpty(): InventoryTerminationReason {
    return InventoryTerminationReason.SCRAP;
}

