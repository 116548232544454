/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseMinimalUserResponse } from './BaseMinimalUserResponse';
    import {
    BaseMinimalUserResponseFromJSON,
    BaseMinimalUserResponseFromJSONTyped,
    BaseMinimalUserResponseToJSON,
    BaseMinimalUserResponseCreateEmpty,
    } from './BaseMinimalUserResponse';
    import type { MoneyBudgetProgramMilestoneResponse } from './MoneyBudgetProgramMilestoneResponse';
    import {
    MoneyBudgetProgramMilestoneResponseFromJSON,
    MoneyBudgetProgramMilestoneResponseFromJSONTyped,
    MoneyBudgetProgramMilestoneResponseToJSON,
    MoneyBudgetProgramMilestoneResponseCreateEmpty,
    } from './MoneyBudgetProgramMilestoneResponse';
    import type { MoneyItemGroupWithItemsResponse } from './MoneyItemGroupWithItemsResponse';
    import {
    MoneyItemGroupWithItemsResponseFromJSON,
    MoneyItemGroupWithItemsResponseFromJSONTyped,
    MoneyItemGroupWithItemsResponseToJSON,
    MoneyItemGroupWithItemsResponseCreateEmpty,
    } from './MoneyItemGroupWithItemsResponse';
import {DateTime} from '@Utils/DateTime';

export interface MoneyBudgetProgramResponse {
  id: number;
  name: string;
  description: string;
  groupId: number;
  budgetItemName: string;
  responsible: BaseMinimalUserResponse;
  itemGroup: MoneyItemGroupWithItemsResponse;
  milestones: Array<MoneyBudgetProgramMilestoneResponse>;
}

export const MoneyBudgetProgramResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: 'ID',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Név',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Leírás',
    type: 'string',
  },
  'groupId': {
    name: 'groupId',
    required: true,
    nullable: false,
    label: 'Kör azonosítója',
    type: 'number',
      minimum: 1,
  },
  'budgetItemName': {
    name: 'budgetItemName',
    required: true,
    nullable: false,
    label: 'Költségvetési tétel neve',
    type: 'string',
  },
  'responsible': {
    name: 'responsible',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'itemGroup': {
    name: 'itemGroup',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'milestones': {
    name: 'milestones',
    required: true,
    nullable: false,
    label: 'Állapot frissítések',
      type: 'array',
  },
}
export function instanceOfMoneyBudgetProgramResponse(value: object): value is MoneyBudgetProgramResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "budgetItemName" in value;
    isInstance = isInstance && "responsible" in value;
    isInstance = isInstance && "itemGroup" in value;
    isInstance = isInstance && "milestones" in value;

return isInstance;
}

export function MoneyBudgetProgramResponseFromJSON(json: any): MoneyBudgetProgramResponse {
return MoneyBudgetProgramResponseFromJSONTyped(json, false);
}

export function MoneyBudgetProgramResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyBudgetProgramResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
          'description': json['description'],
          'groupId': json['groupId'],
          'budgetItemName': json['budgetItemName'],
              'responsible': BaseMinimalUserResponseFromJSON(json['responsible']),
              'itemGroup': MoneyItemGroupWithItemsResponseFromJSON(json['itemGroup']),
            'milestones': ((json['milestones'] as Array<any>).map(MoneyBudgetProgramMilestoneResponseFromJSON)),
    };
  }

  export function MoneyBudgetProgramResponseToJSON(value?: MoneyBudgetProgramResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
                'description': value.description,
                'groupId': value.groupId,
                'budgetItemName': value.budgetItemName,
                'responsible': BaseMinimalUserResponseToJSON(value.responsible),
                'itemGroup': MoneyItemGroupWithItemsResponseToJSON(value.itemGroup),
              'milestones': ((value.milestones as Array<any>).map(MoneyBudgetProgramMilestoneResponseToJSON)),
    };
  }

  export function MoneyBudgetProgramResponseCreateEmpty(): MoneyBudgetProgramResponse {
  return {
      'id': 0,
      'name': '',
      'description': '',
      'groupId': 0,
      'budgetItemName': '',
          'responsible': BaseMinimalUserResponseCreateEmpty(),
          'itemGroup': MoneyItemGroupWithItemsResponseCreateEmpty(),
      'milestones': [],
  };
  }

