/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { KprCategoryResponse } from './KprCategoryResponse';
    import {
    KprCategoryResponseFromJSON,
    KprCategoryResponseFromJSONTyped,
    KprCategoryResponseToJSON,
    KprCategoryResponseCreateEmpty,
    } from './KprCategoryResponse';
import {DateTime} from '@Utils/DateTime';

export interface KprCategoryApplicationSummaryResponse {
  id: number;
  category: KprCategoryResponse;
  applicationId: number;
  priceSumDecided: number;
  itemCount: number;
  itemGroupId: number;
}

export const KprCategoryApplicationSummaryResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'category': {
    name: 'category',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'applicationId': {
    name: 'applicationId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'priceSumDecided': {
    name: 'priceSumDecided',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'itemCount': {
    name: 'itemCount',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'itemGroupId': {
    name: 'itemGroupId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
}
export function instanceOfKprCategoryApplicationSummaryResponse(value: object): value is KprCategoryApplicationSummaryResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "applicationId" in value;
    isInstance = isInstance && "priceSumDecided" in value;
    isInstance = isInstance && "itemCount" in value;
    isInstance = isInstance && "itemGroupId" in value;

return isInstance;
}

export function KprCategoryApplicationSummaryResponseFromJSON(json: any): KprCategoryApplicationSummaryResponse {
return KprCategoryApplicationSummaryResponseFromJSONTyped(json, false);
}

export function KprCategoryApplicationSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KprCategoryApplicationSummaryResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
              'category': KprCategoryResponseFromJSON(json['category']),
          'applicationId': json['applicationId'],
          'priceSumDecided': json['priceSumDecided'],
          'itemCount': json['itemCount'],
          'itemGroupId': json['itemGroupId'],
    };
  }

  export function KprCategoryApplicationSummaryResponseToJSON(value?: KprCategoryApplicationSummaryResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'category': KprCategoryResponseToJSON(value.category),
                'applicationId': value.applicationId,
                'priceSumDecided': value.priceSumDecided,
                'itemCount': value.itemCount,
                'itemGroupId': value.itemGroupId,
    };
  }

  export function KprCategoryApplicationSummaryResponseCreateEmpty(): KprCategoryApplicationSummaryResponse {
  return {
      'id': 0,
          'category': KprCategoryResponseCreateEmpty(),
      'applicationId': 0,
      'priceSumDecided': 0,
      'itemCount': 0,
      'itemGroupId': 0,
  };
  }

