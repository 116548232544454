import type {Group} from '@Models/base/Group';
import type {SpendingPeriod} from '@Models/money/SpendingPeriod';
import {MoneyApi} from '@/api/apis';
import {Base} from '@Models/Base';
import type {MoneyMoneyGroupSummary} from '@/api/models';
import {SpendingItem} from '@Models/money/SpendingItem';
import {Category} from '@Models/rvt/kpr/Category';
import {BudgetItem} from '@Models/money/BudgetItem';
import {BudgetProgram} from '@Models/money/BudgetProgram';

export type KprSummary = {
    id: number;
    category: Category,
    applicationId: number,
    priceSumDecided: number,
    itemCount: number,
    itemGroupId: number,
}

export class GroupSummary extends Base {
    relevantSpendingItems: Record<number, SpendingItem>;

    kprCategoryApplications: Record<number, KprSummary>;

    budgetItems: Record<number, BudgetItem>;

    budgetPrograms: Record<number, BudgetProgram>;

    static get(group: Group, spendingPeriod: SpendingPeriod): Promise<GroupSummary> {
        return MoneyApi.groupsSummary(group.id, spendingPeriod.id).then(response => {
            return GroupSummary.newSingle(response.data, GroupSummary.parseResponse);
        });
    }

    static parseResponse(summary: GroupSummary, data: MoneyMoneyGroupSummary): GroupSummary {
        summary.relevantSpendingItems = SpendingItem.newRecords(data.relevantSpendingItems, SpendingItem.parseResponse);
        summary.kprCategoryApplications = data.kprCategoryApplications.map(capp => {
            return {
                id: capp.id,
                category: Category.newSingle(capp.category, Category.parseResponse),
                applicationId: capp.applicationId,
                priceSumDecided: capp.priceSumDecided,
                itemCount: capp.itemCount,
                itemGroupId: capp.itemGroupId
            };
        }).keyBy(item => item.id);
        summary.budgetItems = BudgetItem.newRecords(data.budgetItems, BudgetItem.parseResponse);
        summary.budgetPrograms = BudgetProgram.newRecords(data.budgetPrograms, BudgetProgram.parseResponse);

        return summary;
    }
}
