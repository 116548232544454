/* eslint-disable */
// noinspection ES6RedundantNestingInTemplateLiteral

import * as runtime from '../runtime';
import type {FormRef} from "@/vue";
  import type {
    BaseSuccessResponse,
    BaseValidationBagResponse,
    VikoApplicationAdminResponse,
    VikoApplicationIgnoreUpdate,
    VikoApplicationListResponse,
    VikoApplicationResponse,
    VikoApplicationUpdate,
    VikoCalcResponse,
    VikoCalcUpdate,
    VikoDone,
    VikoExtendedTenderResponse,
    VikoGroupReviewUpdate,
    VikoIndexResponse,
    VikoLeaderResponse,
    VikoReportDecide,
    VikoReportListResponse,
    VikoReportResponse,
    VikoReportStore,
    VikoReportUpdate,
    VikoResultCategoryResponse,
    VikoResultCategoryStore,
    VikoResultCategoryUpdate,
    VikoReviewResponse,
    VikoReviewStore,
    VikoReviewUpdate,
    VikoReviewerGroupMember,
    VikoReviewerGroupResponse,
    VikoReviewerGroupStore,
    VikoReviewerGroupUpdate,
    VikoSummaryResponse,
    VikoTenderListResponse,
    VikoTenderModerator,
    VikoTenderProblemsResponse,
    VikoTenderStatisticsResponse,
    VikoTenderStore,
    VikoTenderSummaryResponse,
    VikoTenderUpdate,
  } from '../models/index';
    import {
      BaseSuccessResponseFromJSON,
      BaseSuccessResponseToJSON,
      BaseValidationBagResponseFromJSON,
      BaseValidationBagResponseToJSON,
      VikoApplicationAdminResponseFromJSON,
      VikoApplicationAdminResponseToJSON,
      VikoApplicationIgnoreUpdateFromJSON,
      VikoApplicationIgnoreUpdateToJSON,
      VikoApplicationListResponseFromJSON,
      VikoApplicationListResponseToJSON,
      VikoApplicationResponseFromJSON,
      VikoApplicationResponseToJSON,
      VikoApplicationUpdateFromJSON,
      VikoApplicationUpdateToJSON,
      VikoCalcResponseFromJSON,
      VikoCalcResponseToJSON,
      VikoCalcUpdateFromJSON,
      VikoCalcUpdateToJSON,
      VikoDoneFromJSON,
      VikoDoneToJSON,
      VikoExtendedTenderResponseFromJSON,
      VikoExtendedTenderResponseToJSON,
      VikoGroupReviewUpdateFromJSON,
      VikoGroupReviewUpdateToJSON,
      VikoIndexResponseFromJSON,
      VikoIndexResponseToJSON,
      VikoLeaderResponseFromJSON,
      VikoLeaderResponseToJSON,
      VikoReportDecideFromJSON,
      VikoReportDecideToJSON,
      VikoReportListResponseFromJSON,
      VikoReportListResponseToJSON,
      VikoReportResponseFromJSON,
      VikoReportResponseToJSON,
      VikoReportStoreFromJSON,
      VikoReportStoreToJSON,
      VikoReportUpdateFromJSON,
      VikoReportUpdateToJSON,
      VikoResultCategoryResponseFromJSON,
      VikoResultCategoryResponseToJSON,
      VikoResultCategoryStoreFromJSON,
      VikoResultCategoryStoreToJSON,
      VikoResultCategoryUpdateFromJSON,
      VikoResultCategoryUpdateToJSON,
      VikoReviewResponseFromJSON,
      VikoReviewResponseToJSON,
      VikoReviewStoreFromJSON,
      VikoReviewStoreToJSON,
      VikoReviewUpdateFromJSON,
      VikoReviewUpdateToJSON,
      VikoReviewerGroupMemberFromJSON,
      VikoReviewerGroupMemberToJSON,
      VikoReviewerGroupResponseFromJSON,
      VikoReviewerGroupResponseToJSON,
      VikoReviewerGroupStoreFromJSON,
      VikoReviewerGroupStoreToJSON,
      VikoReviewerGroupUpdateFromJSON,
      VikoReviewerGroupUpdateToJSON,
      VikoSummaryResponseFromJSON,
      VikoSummaryResponseToJSON,
      VikoTenderListResponseFromJSON,
      VikoTenderListResponseToJSON,
      VikoTenderModeratorFromJSON,
      VikoTenderModeratorToJSON,
      VikoTenderProblemsResponseFromJSON,
      VikoTenderProblemsResponseToJSON,
      VikoTenderStatisticsResponseFromJSON,
      VikoTenderStatisticsResponseToJSON,
      VikoTenderStoreFromJSON,
      VikoTenderStoreToJSON,
      VikoTenderSummaryResponseFromJSON,
      VikoTenderSummaryResponseToJSON,
      VikoTenderUpdateFromJSON,
      VikoTenderUpdateToJSON,
    } from '../models/index';

      export interface VikoApplicationsDestroyRequest {
        application: number;
      }

      export interface VikoApplicationsIgnoreRequest {
        application: number;
        vikoApplicationIgnoreUpdate: VikoApplicationIgnoreUpdate;
      }

      export interface VikoApplicationsReportsRequest {
        application: number;
      }

      export interface VikoApplicationsShowRequest {
        application: number;
      }

      export interface VikoApplicationsUpdateRequest {
        application: number;
        vikoApplicationUpdate: VikoApplicationUpdate;
      }

      export interface VikoIndexLeaderRequest {
        group: number;
      }

      export interface VikoReportsDecideRequest {
        report: number;
        vikoReportDecide: VikoReportDecide;
      }

      export interface VikoReportsDestroyRequest {
        report: number;
      }

      export interface VikoReportsSendRequest {
        report: number;
      }

      export interface VikoReportsStoreRequest {
        vikoReportStore: VikoReportStore;
      }

      export interface VikoReportsUpdateRequest {
        report: number;
        vikoReportUpdate: VikoReportUpdate;
      }

      export interface VikoResultCategoriesDeleteRequest {
        resultCategory: number;
      }

      export interface VikoResultCategoriesStoreRequest {
        vikoResultCategoryStore: VikoResultCategoryStore;
      }

      export interface VikoResultCategoriesUpdateRequest {
        resultCategory: number;
        vikoResultCategoryUpdate: VikoResultCategoryUpdate;
      }

      export interface VikoReviewerGroupsAddMemberRequest {
        group: number;
        vikoReviewerGroupMember: VikoReviewerGroupMember;
      }

      export interface VikoReviewerGroupsDestroyRequest {
        group: number;
      }

      export interface VikoReviewerGroupsRemoveMemberRequest {
        group: number;
        vikoReviewerGroupMember: VikoReviewerGroupMember;
      }

      export interface VikoReviewerGroupsStoreRequest {
        vikoReviewerGroupStore: VikoReviewerGroupStore;
      }

      export interface VikoReviewerGroupsUpdateRequest {
        group: number;
        vikoReviewerGroupUpdate: VikoReviewerGroupUpdate;
      }

      export interface VikoReviewsStoreRequest {
        vikoReviewStore: VikoReviewStore;
      }

      export interface VikoStatisticsStatisticsRequest {
        tender: number;
      }

      export interface VikoSummaryCalcRequest {
        tender: number;
      }

      export interface VikoSummaryCalcUpdateRequest {
        tender: number;
        vikoCalcUpdate: VikoCalcUpdate;
      }

      export interface VikoSummaryDoneRequest {
        application: number;
        vikoDone: VikoDone;
      }

      export interface VikoSummarySummaryRequest {
        tender: number;
      }

      export interface VikoSummaryUpdateGroupReviewRequest {
        review: number;
        reviewerGroup: number;
        vikoGroupReviewUpdate: VikoGroupReviewUpdate;
      }

      export interface VikoSummaryUpdateReviewRequest {
        review: number;
        vikoReviewUpdate: VikoReviewUpdate;
      }

      export interface VikoTendersAddModeratorRequest {
        tender: number;
        vikoTenderModerator: VikoTenderModerator;
      }

      export interface VikoTendersApplicationsRequest {
        tender: number;
      }

      export interface VikoTendersDestroyRequest {
        tender: number;
      }

      export interface VikoTendersExportApplicationsRequest {
        tender: number;
      }

      export interface VikoTendersExportIgnoredResultsRequest {
        tender: number;
      }

      export interface VikoTendersExportResultsRequest {
        tender: number;
      }

      export interface VikoTendersProblemsRequest {
        tender: number;
      }

      export interface VikoTendersRemoveModeratorRequest {
        tender: number;
        vikoTenderModerator: VikoTenderModerator;
      }

      export interface VikoTendersShowRequest {
        tender: number;
      }

      export interface VikoTendersStoreRequest {
        vikoTenderStore: VikoTenderStore;
      }

      export interface VikoTendersSummaryRequest {
        tender: number;
      }

      export interface VikoTendersUpdateRequest {
        tender: number;
        vikoTenderUpdate: VikoTenderUpdate;
      }

      export class VikoApi extends runtime.BaseAPI {

      private async applicationsDestroyRaw(requestParameters: VikoApplicationsDestroyRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.application === null || requestParameters.application === undefined) {
          throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling applicationsDestroy.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/viko/applications/{application}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async applicationsDestroy(application: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.applicationsDestroyRaw({ application: application }, options);
          return await response.value();
        }

      private async applicationsIgnoreRaw(requestParameters: VikoApplicationsIgnoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoApplicationAdminResponse>> {
          if (requestParameters.application === null || requestParameters.application === undefined) {
          throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling applicationsIgnore.');
          }

          if (requestParameters.vikoApplicationIgnoreUpdate === null || requestParameters.vikoApplicationIgnoreUpdate === undefined) {
          throw new runtime.RequiredError('vikoApplicationIgnoreUpdate','Required parameter requestParameters.vikoApplicationIgnoreUpdate was null or undefined when calling applicationsIgnore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/viko/applications/{application}/ignore`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: VikoApplicationIgnoreUpdateToJSON(requestParameters.vikoApplicationIgnoreUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoApplicationAdminResponseFromJSON(jsonValue));
      }

        static async applicationsIgnore(application: number, vikoApplicationIgnoreUpdate: VikoApplicationIgnoreUpdate, options?: {form?: FormRef}): Promise<{data: VikoApplicationAdminResponse, raw: Response}> {
          const api = new this();
          const response = await api.applicationsIgnoreRaw({ application: application, vikoApplicationIgnoreUpdate: vikoApplicationIgnoreUpdate }, options);
          return await response.value();
        }

      private async applicationsReportsRaw(requestParameters: VikoApplicationsReportsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoReportListResponse>> {
          if (requestParameters.application === null || requestParameters.application === undefined) {
          throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling applicationsReports.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/viko/applications/{application}/reports`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoReportListResponseFromJSON(jsonValue));
      }

        static async applicationsReports(application: number, options?: {form?: FormRef}): Promise<{data: VikoReportListResponse, raw: Response}> {
          const api = new this();
          const response = await api.applicationsReportsRaw({ application: application }, options);
          return await response.value();
        }

      private async applicationsShowRaw(requestParameters: VikoApplicationsShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoApplicationResponse>> {
          if (requestParameters.application === null || requestParameters.application === undefined) {
          throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling applicationsShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/viko/applications/{application}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoApplicationResponseFromJSON(jsonValue));
      }

        static async applicationsShow(application: number, options?: {form?: FormRef}): Promise<{data: VikoApplicationResponse, raw: Response}> {
          const api = new this();
          const response = await api.applicationsShowRaw({ application: application }, options);
          return await response.value();
        }

      private async applicationsStoreRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoApplicationResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/viko/applications`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoApplicationResponseFromJSON(jsonValue));
      }

        static async applicationsStore(options?: {form?: FormRef}): Promise<{data: VikoApplicationResponse, raw: Response}> {
          const api = new this();
          const response = await api.applicationsStoreRaw(options);
          return await response.value();
        }

      private async applicationsUpdateRaw(requestParameters: VikoApplicationsUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoApplicationResponse>> {
          if (requestParameters.application === null || requestParameters.application === undefined) {
          throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling applicationsUpdate.');
          }

          if (requestParameters.vikoApplicationUpdate === null || requestParameters.vikoApplicationUpdate === undefined) {
          throw new runtime.RequiredError('vikoApplicationUpdate','Required parameter requestParameters.vikoApplicationUpdate was null or undefined when calling applicationsUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/viko/applications/{application}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: VikoApplicationUpdateToJSON(requestParameters.vikoApplicationUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoApplicationResponseFromJSON(jsonValue));
      }

        static async applicationsUpdate(application: number, vikoApplicationUpdate: VikoApplicationUpdate, options?: {form?: FormRef}): Promise<{data: VikoApplicationResponse, raw: Response}> {
          const api = new this();
          const response = await api.applicationsUpdateRaw({ application: application, vikoApplicationUpdate: vikoApplicationUpdate }, options);
          return await response.value();
        }

      private async indexIndexRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoIndexResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/viko/index`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoIndexResponseFromJSON(jsonValue));
      }

        static async indexIndex(options?: {form?: FormRef}): Promise<{data: VikoIndexResponse, raw: Response}> {
          const api = new this();
          const response = await api.indexIndexRaw(options);
          return await response.value();
        }

      private async indexLeaderRaw(requestParameters: VikoIndexLeaderRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoLeaderResponse>> {
          if (requestParameters.group === null || requestParameters.group === undefined) {
          throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling indexLeader.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/viko/leader/{group}`.replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoLeaderResponseFromJSON(jsonValue));
      }

        static async indexLeader(group: number, options?: {form?: FormRef}): Promise<{data: VikoLeaderResponse, raw: Response}> {
          const api = new this();
          const response = await api.indexLeaderRaw({ group: group }, options);
          return await response.value();
        }

      private async reportsDecideRaw(requestParameters: VikoReportsDecideRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoReportResponse>> {
          if (requestParameters.report === null || requestParameters.report === undefined) {
          throw new runtime.RequiredError('report','Required parameter requestParameters.report was null or undefined when calling reportsDecide.');
          }

          if (requestParameters.vikoReportDecide === null || requestParameters.vikoReportDecide === undefined) {
          throw new runtime.RequiredError('vikoReportDecide','Required parameter requestParameters.vikoReportDecide was null or undefined when calling reportsDecide.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/viko/reports/{report}/decide`.replace(`{${"report"}}`, encodeURIComponent(String(requestParameters.report))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: VikoReportDecideToJSON(requestParameters.vikoReportDecide),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoReportResponseFromJSON(jsonValue));
      }

        static async reportsDecide(report: number, vikoReportDecide: VikoReportDecide, options?: {form?: FormRef}): Promise<{data: VikoReportResponse, raw: Response}> {
          const api = new this();
          const response = await api.reportsDecideRaw({ report: report, vikoReportDecide: vikoReportDecide }, options);
          return await response.value();
        }

      private async reportsDestroyRaw(requestParameters: VikoReportsDestroyRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.report === null || requestParameters.report === undefined) {
          throw new runtime.RequiredError('report','Required parameter requestParameters.report was null or undefined when calling reportsDestroy.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/viko/reports/{report}`.replace(`{${"report"}}`, encodeURIComponent(String(requestParameters.report))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async reportsDestroy(report: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.reportsDestroyRaw({ report: report }, options);
          return await response.value();
        }

      private async reportsSendRaw(requestParameters: VikoReportsSendRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoReportResponse>> {
          if (requestParameters.report === null || requestParameters.report === undefined) {
          throw new runtime.RequiredError('report','Required parameter requestParameters.report was null or undefined when calling reportsSend.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/viko/reports/{report}/send`.replace(`{${"report"}}`, encodeURIComponent(String(requestParameters.report))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoReportResponseFromJSON(jsonValue));
      }

        static async reportsSend(report: number, options?: {form?: FormRef}): Promise<{data: VikoReportResponse, raw: Response}> {
          const api = new this();
          const response = await api.reportsSendRaw({ report: report }, options);
          return await response.value();
        }

      private async reportsStoreRaw(requestParameters: VikoReportsStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoReportResponse>> {
          if (requestParameters.vikoReportStore === null || requestParameters.vikoReportStore === undefined) {
          throw new runtime.RequiredError('vikoReportStore','Required parameter requestParameters.vikoReportStore was null or undefined when calling reportsStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/viko/reports`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: VikoReportStoreToJSON(requestParameters.vikoReportStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoReportResponseFromJSON(jsonValue));
      }

        static async reportsStore(vikoReportStore: VikoReportStore, options?: {form?: FormRef}): Promise<{data: VikoReportResponse, raw: Response}> {
          const api = new this();
          const response = await api.reportsStoreRaw({ vikoReportStore: vikoReportStore }, options);
          return await response.value();
        }

      private async reportsUpdateRaw(requestParameters: VikoReportsUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoReportResponse>> {
          if (requestParameters.report === null || requestParameters.report === undefined) {
          throw new runtime.RequiredError('report','Required parameter requestParameters.report was null or undefined when calling reportsUpdate.');
          }

          if (requestParameters.vikoReportUpdate === null || requestParameters.vikoReportUpdate === undefined) {
          throw new runtime.RequiredError('vikoReportUpdate','Required parameter requestParameters.vikoReportUpdate was null or undefined when calling reportsUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/viko/reports/{report}`.replace(`{${"report"}}`, encodeURIComponent(String(requestParameters.report))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: VikoReportUpdateToJSON(requestParameters.vikoReportUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoReportResponseFromJSON(jsonValue));
      }

        static async reportsUpdate(report: number, vikoReportUpdate: VikoReportUpdate, options?: {form?: FormRef}): Promise<{data: VikoReportResponse, raw: Response}> {
          const api = new this();
          const response = await api.reportsUpdateRaw({ report: report, vikoReportUpdate: vikoReportUpdate }, options);
          return await response.value();
        }

      private async resultCategoriesDeleteRaw(requestParameters: VikoResultCategoriesDeleteRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.resultCategory === null || requestParameters.resultCategory === undefined) {
          throw new runtime.RequiredError('resultCategory','Required parameter requestParameters.resultCategory was null or undefined when calling resultCategoriesDelete.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/viko/resultCategories/{resultCategory}`.replace(`{${"resultCategory"}}`, encodeURIComponent(String(requestParameters.resultCategory))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async resultCategoriesDelete(resultCategory: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.resultCategoriesDeleteRaw({ resultCategory: resultCategory }, options);
          return await response.value();
        }

      private async resultCategoriesStoreRaw(requestParameters: VikoResultCategoriesStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoResultCategoryResponse>> {
          if (requestParameters.vikoResultCategoryStore === null || requestParameters.vikoResultCategoryStore === undefined) {
          throw new runtime.RequiredError('vikoResultCategoryStore','Required parameter requestParameters.vikoResultCategoryStore was null or undefined when calling resultCategoriesStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/viko/resultCategories`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: VikoResultCategoryStoreToJSON(requestParameters.vikoResultCategoryStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoResultCategoryResponseFromJSON(jsonValue));
      }

        static async resultCategoriesStore(vikoResultCategoryStore: VikoResultCategoryStore, options?: {form?: FormRef}): Promise<{data: VikoResultCategoryResponse, raw: Response}> {
          const api = new this();
          const response = await api.resultCategoriesStoreRaw({ vikoResultCategoryStore: vikoResultCategoryStore }, options);
          return await response.value();
        }

      private async resultCategoriesUpdateRaw(requestParameters: VikoResultCategoriesUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoResultCategoryResponse>> {
          if (requestParameters.resultCategory === null || requestParameters.resultCategory === undefined) {
          throw new runtime.RequiredError('resultCategory','Required parameter requestParameters.resultCategory was null or undefined when calling resultCategoriesUpdate.');
          }

          if (requestParameters.vikoResultCategoryUpdate === null || requestParameters.vikoResultCategoryUpdate === undefined) {
          throw new runtime.RequiredError('vikoResultCategoryUpdate','Required parameter requestParameters.vikoResultCategoryUpdate was null or undefined when calling resultCategoriesUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/viko/resultCategories/{resultCategory}`.replace(`{${"resultCategory"}}`, encodeURIComponent(String(requestParameters.resultCategory))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: VikoResultCategoryUpdateToJSON(requestParameters.vikoResultCategoryUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoResultCategoryResponseFromJSON(jsonValue));
      }

        static async resultCategoriesUpdate(resultCategory: number, vikoResultCategoryUpdate: VikoResultCategoryUpdate, options?: {form?: FormRef}): Promise<{data: VikoResultCategoryResponse, raw: Response}> {
          const api = new this();
          const response = await api.resultCategoriesUpdateRaw({ resultCategory: resultCategory, vikoResultCategoryUpdate: vikoResultCategoryUpdate }, options);
          return await response.value();
        }

      private async reviewerGroupsAddMemberRaw(requestParameters: VikoReviewerGroupsAddMemberRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoReviewerGroupResponse>> {
          if (requestParameters.group === null || requestParameters.group === undefined) {
          throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling reviewerGroupsAddMember.');
          }

          if (requestParameters.vikoReviewerGroupMember === null || requestParameters.vikoReviewerGroupMember === undefined) {
          throw new runtime.RequiredError('vikoReviewerGroupMember','Required parameter requestParameters.vikoReviewerGroupMember was null or undefined when calling reviewerGroupsAddMember.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/viko/reviewerGroups/{group}/addMember`.replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: VikoReviewerGroupMemberToJSON(requestParameters.vikoReviewerGroupMember),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoReviewerGroupResponseFromJSON(jsonValue));
      }

        static async reviewerGroupsAddMember(group: number, vikoReviewerGroupMember: VikoReviewerGroupMember, options?: {form?: FormRef}): Promise<{data: VikoReviewerGroupResponse, raw: Response}> {
          const api = new this();
          const response = await api.reviewerGroupsAddMemberRaw({ group: group, vikoReviewerGroupMember: vikoReviewerGroupMember }, options);
          return await response.value();
        }

      private async reviewerGroupsDestroyRaw(requestParameters: VikoReviewerGroupsDestroyRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.group === null || requestParameters.group === undefined) {
          throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling reviewerGroupsDestroy.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/viko/reviewerGroups/{group}`.replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async reviewerGroupsDestroy(group: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.reviewerGroupsDestroyRaw({ group: group }, options);
          return await response.value();
        }

      private async reviewerGroupsRemoveMemberRaw(requestParameters: VikoReviewerGroupsRemoveMemberRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoReviewerGroupResponse>> {
          if (requestParameters.group === null || requestParameters.group === undefined) {
          throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling reviewerGroupsRemoveMember.');
          }

          if (requestParameters.vikoReviewerGroupMember === null || requestParameters.vikoReviewerGroupMember === undefined) {
          throw new runtime.RequiredError('vikoReviewerGroupMember','Required parameter requestParameters.vikoReviewerGroupMember was null or undefined when calling reviewerGroupsRemoveMember.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/viko/reviewerGroups/{group}/removeMember`.replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: VikoReviewerGroupMemberToJSON(requestParameters.vikoReviewerGroupMember),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoReviewerGroupResponseFromJSON(jsonValue));
      }

        static async reviewerGroupsRemoveMember(group: number, vikoReviewerGroupMember: VikoReviewerGroupMember, options?: {form?: FormRef}): Promise<{data: VikoReviewerGroupResponse, raw: Response}> {
          const api = new this();
          const response = await api.reviewerGroupsRemoveMemberRaw({ group: group, vikoReviewerGroupMember: vikoReviewerGroupMember }, options);
          return await response.value();
        }

      private async reviewerGroupsStoreRaw(requestParameters: VikoReviewerGroupsStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoReviewerGroupResponse>> {
          if (requestParameters.vikoReviewerGroupStore === null || requestParameters.vikoReviewerGroupStore === undefined) {
          throw new runtime.RequiredError('vikoReviewerGroupStore','Required parameter requestParameters.vikoReviewerGroupStore was null or undefined when calling reviewerGroupsStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/viko/reviewerGroups`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: VikoReviewerGroupStoreToJSON(requestParameters.vikoReviewerGroupStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoReviewerGroupResponseFromJSON(jsonValue));
      }

        static async reviewerGroupsStore(vikoReviewerGroupStore: VikoReviewerGroupStore, options?: {form?: FormRef}): Promise<{data: VikoReviewerGroupResponse, raw: Response}> {
          const api = new this();
          const response = await api.reviewerGroupsStoreRaw({ vikoReviewerGroupStore: vikoReviewerGroupStore }, options);
          return await response.value();
        }

      private async reviewerGroupsUpdateRaw(requestParameters: VikoReviewerGroupsUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoReviewerGroupResponse>> {
          if (requestParameters.group === null || requestParameters.group === undefined) {
          throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling reviewerGroupsUpdate.');
          }

          if (requestParameters.vikoReviewerGroupUpdate === null || requestParameters.vikoReviewerGroupUpdate === undefined) {
          throw new runtime.RequiredError('vikoReviewerGroupUpdate','Required parameter requestParameters.vikoReviewerGroupUpdate was null or undefined when calling reviewerGroupsUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/viko/reviewerGroups/{group}`.replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: VikoReviewerGroupUpdateToJSON(requestParameters.vikoReviewerGroupUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoReviewerGroupResponseFromJSON(jsonValue));
      }

        static async reviewerGroupsUpdate(group: number, vikoReviewerGroupUpdate: VikoReviewerGroupUpdate, options?: {form?: FormRef}): Promise<{data: VikoReviewerGroupResponse, raw: Response}> {
          const api = new this();
          const response = await api.reviewerGroupsUpdateRaw({ group: group, vikoReviewerGroupUpdate: vikoReviewerGroupUpdate }, options);
          return await response.value();
        }

      private async reviewsStoreRaw(requestParameters: VikoReviewsStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoReviewResponse>> {
          if (requestParameters.vikoReviewStore === null || requestParameters.vikoReviewStore === undefined) {
          throw new runtime.RequiredError('vikoReviewStore','Required parameter requestParameters.vikoReviewStore was null or undefined when calling reviewsStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/viko/reviews`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: VikoReviewStoreToJSON(requestParameters.vikoReviewStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoReviewResponseFromJSON(jsonValue));
      }

        static async reviewsStore(vikoReviewStore: VikoReviewStore, options?: {form?: FormRef}): Promise<{data: VikoReviewResponse, raw: Response}> {
          const api = new this();
          const response = await api.reviewsStoreRaw({ vikoReviewStore: vikoReviewStore }, options);
          return await response.value();
        }

      private async statisticsStatisticsRaw(requestParameters: VikoStatisticsStatisticsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoTenderStatisticsResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling statisticsStatistics.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/viko/tenders/{tender}/statistics`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoTenderStatisticsResponseFromJSON(jsonValue));
      }

        static async statisticsStatistics(tender: number, options?: {form?: FormRef}): Promise<{data: VikoTenderStatisticsResponse, raw: Response}> {
          const api = new this();
          const response = await api.statisticsStatisticsRaw({ tender: tender }, options);
          return await response.value();
        }

      private async summaryCalcRaw(requestParameters: VikoSummaryCalcRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoCalcResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling summaryCalc.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/viko/reviewer/{tender}/calc`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoCalcResponseFromJSON(jsonValue));
      }

        static async summaryCalc(tender: number, options?: {form?: FormRef}): Promise<{data: VikoCalcResponse, raw: Response}> {
          const api = new this();
          const response = await api.summaryCalcRaw({ tender: tender }, options);
          return await response.value();
        }

      private async summaryCalcUpdateRaw(requestParameters: VikoSummaryCalcUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoCalcResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling summaryCalcUpdate.');
          }

          if (requestParameters.vikoCalcUpdate === null || requestParameters.vikoCalcUpdate === undefined) {
          throw new runtime.RequiredError('vikoCalcUpdate','Required parameter requestParameters.vikoCalcUpdate was null or undefined when calling summaryCalcUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/viko/reviewer/{tender}/calc`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: VikoCalcUpdateToJSON(requestParameters.vikoCalcUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoCalcResponseFromJSON(jsonValue));
      }

        static async summaryCalcUpdate(tender: number, vikoCalcUpdate: VikoCalcUpdate, options?: {form?: FormRef}): Promise<{data: VikoCalcResponse, raw: Response}> {
          const api = new this();
          const response = await api.summaryCalcUpdateRaw({ tender: tender, vikoCalcUpdate: vikoCalcUpdate }, options);
          return await response.value();
        }

      private async summaryDoneRaw(requestParameters: VikoSummaryDoneRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoApplicationResponse>> {
          if (requestParameters.application === null || requestParameters.application === undefined) {
          throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling summaryDone.');
          }

          if (requestParameters.vikoDone === null || requestParameters.vikoDone === undefined) {
          throw new runtime.RequiredError('vikoDone','Required parameter requestParameters.vikoDone was null or undefined when calling summaryDone.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/viko/reviewer/done/{application}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: VikoDoneToJSON(requestParameters.vikoDone),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoApplicationResponseFromJSON(jsonValue));
      }

        static async summaryDone(application: number, vikoDone: VikoDone, options?: {form?: FormRef}): Promise<{data: VikoApplicationResponse, raw: Response}> {
          const api = new this();
          const response = await api.summaryDoneRaw({ application: application, vikoDone: vikoDone }, options);
          return await response.value();
        }

      private async summarySummaryRaw(requestParameters: VikoSummarySummaryRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoSummaryResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling summarySummary.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/viko/reviewer/{tender}/summary`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoSummaryResponseFromJSON(jsonValue));
      }

        static async summarySummary(tender: number, options?: {form?: FormRef}): Promise<{data: VikoSummaryResponse, raw: Response}> {
          const api = new this();
          const response = await api.summarySummaryRaw({ tender: tender }, options);
          return await response.value();
        }

      private async summaryUpdateGroupReviewRaw(requestParameters: VikoSummaryUpdateGroupReviewRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoReviewResponse>> {
          if (requestParameters.review === null || requestParameters.review === undefined) {
          throw new runtime.RequiredError('review','Required parameter requestParameters.review was null or undefined when calling summaryUpdateGroupReview.');
          }

          if (requestParameters.reviewerGroup === null || requestParameters.reviewerGroup === undefined) {
          throw new runtime.RequiredError('reviewerGroup','Required parameter requestParameters.reviewerGroup was null or undefined when calling summaryUpdateGroupReview.');
          }

          if (requestParameters.vikoGroupReviewUpdate === null || requestParameters.vikoGroupReviewUpdate === undefined) {
          throw new runtime.RequiredError('vikoGroupReviewUpdate','Required parameter requestParameters.vikoGroupReviewUpdate was null or undefined when calling summaryUpdateGroupReview.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/viko/reviewer/update/{review}/{reviewerGroup}`.replace(`{${"review"}}`, encodeURIComponent(String(requestParameters.review))).replace(`{${"reviewerGroup"}}`, encodeURIComponent(String(requestParameters.reviewerGroup))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: VikoGroupReviewUpdateToJSON(requestParameters.vikoGroupReviewUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoReviewResponseFromJSON(jsonValue));
      }

        static async summaryUpdateGroupReview(review: number, reviewerGroup: number, vikoGroupReviewUpdate: VikoGroupReviewUpdate, options?: {form?: FormRef}): Promise<{data: VikoReviewResponse, raw: Response}> {
          const api = new this();
          const response = await api.summaryUpdateGroupReviewRaw({ review: review, reviewerGroup: reviewerGroup, vikoGroupReviewUpdate: vikoGroupReviewUpdate }, options);
          return await response.value();
        }

      private async summaryUpdateReviewRaw(requestParameters: VikoSummaryUpdateReviewRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoReviewResponse>> {
          if (requestParameters.review === null || requestParameters.review === undefined) {
          throw new runtime.RequiredError('review','Required parameter requestParameters.review was null or undefined when calling summaryUpdateReview.');
          }

          if (requestParameters.vikoReviewUpdate === null || requestParameters.vikoReviewUpdate === undefined) {
          throw new runtime.RequiredError('vikoReviewUpdate','Required parameter requestParameters.vikoReviewUpdate was null or undefined when calling summaryUpdateReview.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/viko/reviewer/update/{review}`.replace(`{${"review"}}`, encodeURIComponent(String(requestParameters.review))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: VikoReviewUpdateToJSON(requestParameters.vikoReviewUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoReviewResponseFromJSON(jsonValue));
      }

        static async summaryUpdateReview(review: number, vikoReviewUpdate: VikoReviewUpdate, options?: {form?: FormRef}): Promise<{data: VikoReviewResponse, raw: Response}> {
          const api = new this();
          const response = await api.summaryUpdateReviewRaw({ review: review, vikoReviewUpdate: vikoReviewUpdate }, options);
          return await response.value();
        }

      private async tendersAddModeratorRaw(requestParameters: VikoTendersAddModeratorRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoExtendedTenderResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling tendersAddModerator.');
          }

          if (requestParameters.vikoTenderModerator === null || requestParameters.vikoTenderModerator === undefined) {
          throw new runtime.RequiredError('vikoTenderModerator','Required parameter requestParameters.vikoTenderModerator was null or undefined when calling tendersAddModerator.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/viko/tenders/{tender}/addModerator`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: VikoTenderModeratorToJSON(requestParameters.vikoTenderModerator),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoExtendedTenderResponseFromJSON(jsonValue));
      }

        static async tendersAddModerator(tender: number, vikoTenderModerator: VikoTenderModerator, options?: {form?: FormRef}): Promise<{data: VikoExtendedTenderResponse, raw: Response}> {
          const api = new this();
          const response = await api.tendersAddModeratorRaw({ tender: tender, vikoTenderModerator: vikoTenderModerator }, options);
          return await response.value();
        }

      private async tendersApplicationsRaw(requestParameters: VikoTendersApplicationsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoApplicationListResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling tendersApplications.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/viko/tenders/{tender}/applications`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoApplicationListResponseFromJSON(jsonValue));
      }

        static async tendersApplications(tender: number, options?: {form?: FormRef}): Promise<{data: VikoApplicationListResponse, raw: Response}> {
          const api = new this();
          const response = await api.tendersApplicationsRaw({ tender: tender }, options);
          return await response.value();
        }

      private async tendersDestroyRaw(requestParameters: VikoTendersDestroyRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling tendersDestroy.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/viko/tenders/{tender}`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async tendersDestroy(tender: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.tendersDestroyRaw({ tender: tender }, options);
          return await response.value();
        }

      private async tendersExportApplicationsRaw(requestParameters: VikoTendersExportApplicationsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<Blob>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling tendersExportApplications.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/viko/tenders/{tender}/exportApplications`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

              if (this.isJsonMime(response.headers.get('content-type'))) {
              return new runtime.JSONApiResponse<Blob>(response);
              } else {
              return new runtime.BlobApiResponse(response) as any;
              }
      }

        static async tendersExportApplications(tender: number, options?: {form?: FormRef}): Promise<{data: Blob, raw: Response}> {
          const api = new this();
          const response = await api.tendersExportApplicationsRaw({ tender: tender }, options);
          return await response.value();
        }

      private async tendersExportIgnoredResultsRaw(requestParameters: VikoTendersExportIgnoredResultsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<Blob>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling tendersExportIgnoredResults.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/viko/tenders/{tender}/exportIgnoredResults`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

              if (this.isJsonMime(response.headers.get('content-type'))) {
              return new runtime.JSONApiResponse<Blob>(response);
              } else {
              return new runtime.BlobApiResponse(response) as any;
              }
      }

        static async tendersExportIgnoredResults(tender: number, options?: {form?: FormRef}): Promise<{data: Blob, raw: Response}> {
          const api = new this();
          const response = await api.tendersExportIgnoredResultsRaw({ tender: tender }, options);
          return await response.value();
        }

      private async tendersExportResultsRaw(requestParameters: VikoTendersExportResultsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<Blob>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling tendersExportResults.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/viko/tenders/{tender}/exportResults`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

              if (this.isJsonMime(response.headers.get('content-type'))) {
              return new runtime.JSONApiResponse<Blob>(response);
              } else {
              return new runtime.BlobApiResponse(response) as any;
              }
      }

        static async tendersExportResults(tender: number, options?: {form?: FormRef}): Promise<{data: Blob, raw: Response}> {
          const api = new this();
          const response = await api.tendersExportResultsRaw({ tender: tender }, options);
          return await response.value();
        }

      private async tendersIndexRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoTenderListResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/viko/tenders`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoTenderListResponseFromJSON(jsonValue));
      }

        static async tendersIndex(options?: {form?: FormRef}): Promise<{data: VikoTenderListResponse, raw: Response}> {
          const api = new this();
          const response = await api.tendersIndexRaw(options);
          return await response.value();
        }

      private async tendersProblemsRaw(requestParameters: VikoTendersProblemsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoTenderProblemsResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling tendersProblems.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/viko/tenders/{tender}/problems`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoTenderProblemsResponseFromJSON(jsonValue));
      }

        static async tendersProblems(tender: number, options?: {form?: FormRef}): Promise<{data: VikoTenderProblemsResponse, raw: Response}> {
          const api = new this();
          const response = await api.tendersProblemsRaw({ tender: tender }, options);
          return await response.value();
        }

      private async tendersRemoveModeratorRaw(requestParameters: VikoTendersRemoveModeratorRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoExtendedTenderResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling tendersRemoveModerator.');
          }

          if (requestParameters.vikoTenderModerator === null || requestParameters.vikoTenderModerator === undefined) {
          throw new runtime.RequiredError('vikoTenderModerator','Required parameter requestParameters.vikoTenderModerator was null or undefined when calling tendersRemoveModerator.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/viko/tenders/{tender}/removeModerator`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: VikoTenderModeratorToJSON(requestParameters.vikoTenderModerator),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoExtendedTenderResponseFromJSON(jsonValue));
      }

        static async tendersRemoveModerator(tender: number, vikoTenderModerator: VikoTenderModerator, options?: {form?: FormRef}): Promise<{data: VikoExtendedTenderResponse, raw: Response}> {
          const api = new this();
          const response = await api.tendersRemoveModeratorRaw({ tender: tender, vikoTenderModerator: vikoTenderModerator }, options);
          return await response.value();
        }

      private async tendersShowRaw(requestParameters: VikoTendersShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoExtendedTenderResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling tendersShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/viko/tenders/{tender}`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoExtendedTenderResponseFromJSON(jsonValue));
      }

        static async tendersShow(tender: number, options?: {form?: FormRef}): Promise<{data: VikoExtendedTenderResponse, raw: Response}> {
          const api = new this();
          const response = await api.tendersShowRaw({ tender: tender }, options);
          return await response.value();
        }

      private async tendersStoreRaw(requestParameters: VikoTendersStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoExtendedTenderResponse>> {
          if (requestParameters.vikoTenderStore === null || requestParameters.vikoTenderStore === undefined) {
          throw new runtime.RequiredError('vikoTenderStore','Required parameter requestParameters.vikoTenderStore was null or undefined when calling tendersStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/viko/tenders`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: VikoTenderStoreToJSON(requestParameters.vikoTenderStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoExtendedTenderResponseFromJSON(jsonValue));
      }

        static async tendersStore(vikoTenderStore: VikoTenderStore, options?: {form?: FormRef}): Promise<{data: VikoExtendedTenderResponse, raw: Response}> {
          const api = new this();
          const response = await api.tendersStoreRaw({ vikoTenderStore: vikoTenderStore }, options);
          return await response.value();
        }

      private async tendersSummaryRaw(requestParameters: VikoTendersSummaryRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoTenderSummaryResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling tendersSummary.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/viko/tenders/{tender}/summary`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoTenderSummaryResponseFromJSON(jsonValue));
      }

        static async tendersSummary(tender: number, options?: {form?: FormRef}): Promise<{data: VikoTenderSummaryResponse, raw: Response}> {
          const api = new this();
          const response = await api.tendersSummaryRaw({ tender: tender }, options);
          return await response.value();
        }

      private async tendersUpdateRaw(requestParameters: VikoTendersUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<VikoExtendedTenderResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling tendersUpdate.');
          }

          if (requestParameters.vikoTenderUpdate === null || requestParameters.vikoTenderUpdate === undefined) {
          throw new runtime.RequiredError('vikoTenderUpdate','Required parameter requestParameters.vikoTenderUpdate was null or undefined when calling tendersUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/viko/tenders/{tender}`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: VikoTenderUpdateToJSON(requestParameters.vikoTenderUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => VikoExtendedTenderResponseFromJSON(jsonValue));
      }

        static async tendersUpdate(tender: number, vikoTenderUpdate: VikoTenderUpdate, options?: {form?: FormRef}): Promise<{data: VikoExtendedTenderResponse, raw: Response}> {
          const api = new this();
          const response = await api.tendersUpdateRaw({ tender: tender, vikoTenderUpdate: vikoTenderUpdate }, options);
          return await response.value();
        }

    }
