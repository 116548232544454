import {BaseEntity} from "@Models/BaseEntity";
import {DateTime} from "@Utils/DateTime";
import {sumObject} from "@Models/Utils";
import type {KprCategoryResponse} from "@/api/api";
import {KprCategoryStatus, KprCategoryStatusValues} from "@/api/api";
import {Application} from "@Models/rvt/kpr/Application";

export type Statistics = {
    category: Category,
    priceSum: number,
    priceSumApproved: number,
    priceSumDecided: number,
    balance: number
}

export class Category extends BaseEntity {
    name: string;

    shortName: 'm' | 'k' | 'f';

    status: KprCategoryStatus;

    requireContributions: boolean;

    endDrafting: DateTime;

    endResulting: DateTime;

    budget: number | null;

    budgetItemId: number | null;

    static parseResponse(category: Category, data: KprCategoryResponse): Category {
        category.id = data.id;
        category.name = data.name;
        category.shortName = data.shortName as 'm' | 'k' | 'f';
        category.status = data.status;
        category.requireContributions = data.requireContributions;
        category.endDrafting = data.endDrafting;
        category.endResulting = data.endResulting;
        category.budget = data.budget;
        category.budgetItemId = data.budgetItemId;

        return category;
    }

    static getDefaultCategories(): Category[] {
        const m = new Category();
        m.name = 'Működési';
        m.shortName = 'm';
        m.status = KprCategoryStatus.DRAFTING;
        m.requireContributions = false;
        m.endDrafting = DateTime.now().addMonth();
        m.endResulting = DateTime.now().addYear();

        const k = new Category();
        k.name = 'Közösségi';
        k.shortName = 'k';
        k.status = KprCategoryStatus.DRAFTING;
        k.requireContributions = true;
        k.endDrafting = DateTime.now().addMonth();
        k.endResulting = DateTime.now().addYear();

        const f = new Category();
        f.name = 'Fejlesztési';
        f.shortName = 'f';
        f.status = KprCategoryStatus.DRAFTING;
        f.requireContributions = false;
        f.endDrafting = DateTime.now().addMonth();
        f.endResulting = DateTime.now().addYear();

        return [m, k, f];
    }

    public isInDrafting(): boolean {
        return this.status === KprCategoryStatus.DRAFTING;
    }

    get statusText(): string {
        return KprCategoryStatusValues[this.status];
    }

    get deadlineText(): string {
        return this.endDrafting.datetime();
    }

    public calculateStatistics(applications: Record<number, Application>): Statistics {
        const priceSum = sumObject(
            applications,
            application => application.getCappByShortName(this.shortName)?.itemGroup.calculateSum() ?? 0
        );

        const priceSumApproved = sumObject(
            applications,
            application => application.getCappByShortName(this.shortName)?.itemGroup.calculateSumApproved() ?? 0
        );

        const priceSumDecided = sumObject(
            applications,
            application => application.getCappByShortName(this.shortName)?.itemGroup.calculateSumDecided() ?? 0
        );

        return {
            category: this,
            priceSum,
            priceSumApproved,
            priceSumDecided,
            balance: priceSumDecided - (this.budget ?? 0),
        };
    }
}
