import {BaseEntity} from '@Models/BaseEntity';
import {
    BaseGroupSpecificPermission,
    type MoneyBudgetProgramMilestoneAdd,
    MoneyBudgetProgramMilestoneType,
    type MoneyBudgetProgramResponse,
    type MoneyBudgetProgramStore,
    type MoneyBudgetProgramUpdate
} from '@/api/models';
import type {FormRef} from '@/vue';
import {MoneyApi} from '@/api/apis';
import {ItemGroup} from '@Models/money/ItemGroup';
import {Group} from '@Models/base/Group';
import {BudgetProgramMilestone} from '@Models/money/BudgetProgramMilestone';
import {User} from '@Models/base/User';
import {each} from 'lodash-es';
import type {BudgetItem} from '@Models/money/BudgetItem';

export enum BudgetProgramStatus {
    DRAFT,
    WAITING,
    APPROVED,
    REJECTED,
    FINISHED,
    ARCHIVED
}

const BudgetProgramStatusValues: Record<BudgetProgramStatus, string> = {
    [BudgetProgramStatus.DRAFT]: 'Szerkesztés alatt',
    [BudgetProgramStatus.WAITING]: 'Bírálás alatt',
    [BudgetProgramStatus.APPROVED]: 'Elfogadva, költés alatt',
    [BudgetProgramStatus.REJECTED]: 'Visszadobva, szerkesztés alatt',
    [BudgetProgramStatus.FINISHED]: 'Elszámolás alatt',
    [BudgetProgramStatus.ARCHIVED]: 'Archivált',
};

export class BudgetProgram extends BaseEntity {
    name: string;

    description: string;

    group: Group;

    budgetItemName: string;

    responsible: User;

    itemGroup: ItemGroup;

    milestones: Record<number, BudgetProgramMilestone>;

    static getAllByItem(itemId: number): Promise<Record<number, BudgetProgram>> {
        return MoneyApi.budgetItemsGetPrograms(itemId).then(response => {
            return BudgetProgram.newRecords(response.data.programs, BudgetProgram.parseResponse);
        });
    }

    static getRelevant(): Promise<Record<number, BudgetProgram>> {
        return MoneyApi.budgetProgramsRelevant().then(response => {
            return BudgetProgram.newRecords(response.data.programs, BudgetProgram.parseResponse);
        });
    }

    static getById(id: number): Promise<BudgetProgram> {
        return MoneyApi.budgetProgramsShow(id).then(response => {
            return BudgetProgram.newSingle(response.data, this.parseResponse);
        });
    }

    static createNew(item: BudgetItem): BudgetProgram {
        const program = new BudgetProgram();

        program.name = 'Új költségvetés';
        program.description = '';
        program.group = item.group;
        program.budgetItemName = item.name;

        return program;
    }

    static parseResponse(program: BudgetProgram, data: MoneyBudgetProgramResponse): BudgetProgram {
        program.id = data.id;
        program.name = data.name;
        program.description = data.description;
        program.group = Group.getBySingleId(data.groupId);
        program.budgetItemName = data.budgetItemName;
        program.responsible = User.newSingle(data.responsible, User.parseMinimalResponse);
        program.itemGroup = ItemGroup.newSingle(data.itemGroup, ItemGroup.parseWithItems);
        program.milestones = BudgetProgramMilestone.newRecords(data.milestones, BudgetProgramMilestone.parseResponse);

        return program;
    }

    get statusText(): string {
        return BudgetProgramStatusValues[this.status];
    }

    get status(): BudgetProgramStatus {
        let status = BudgetProgramStatus.DRAFT;
        each(this.milestones, milestone => {
            switch (milestone.type) {
                case MoneyBudgetProgramMilestoneType.CREATE:
                    status = BudgetProgramStatus.DRAFT;
                    break;
                case MoneyBudgetProgramMilestoneType.SUBMIT:
                    status = BudgetProgramStatus.WAITING;
                    break;
                case MoneyBudgetProgramMilestoneType.APPROVE:
                    status = BudgetProgramStatus.APPROVED;
                    break;
                case MoneyBudgetProgramMilestoneType.REJECT:
                    status = BudgetProgramStatus.REJECTED;
                    break;
                case MoneyBudgetProgramMilestoneType.FINISH:
                    status = BudgetProgramStatus.FINISHED;
                    break;
                case MoneyBudgetProgramMilestoneType.ARCHIVE:
                    status = BudgetProgramStatus.ARCHIVED;
                    break;
            }
        });

        return status;
    }

    public store(budgetItemId: number, form: FormRef): Promise<BudgetProgram> {
        const data: MoneyBudgetProgramStore = {
            name: this.name,
            budgetItem: budgetItemId,
            group: this.group.id
        };

        return MoneyApi.budgetProgramsStore(data, {form}).then(response => {
            return BudgetProgram.parseResponse(this, response.data);
        });
    }

    public update(name: string, description: string, form: FormRef): Promise<void> {
        const data: MoneyBudgetProgramUpdate = {
            name,
            description
        };

        return MoneyApi.budgetProgramsUpdate(this.id, data, {form}).then(response => {
            BudgetProgram.parseResponse(this, response.data);
        });
    }

    public submit(comment: string | null, form: FormRef): Promise<void> {
        const data: MoneyBudgetProgramMilestoneAdd = {
            type: MoneyBudgetProgramMilestoneType.SUBMIT,
            comment: comment
        };

        return MoneyApi.budgetProgramsAddMilestone(this.id, data, {form}).then(response => {
            BudgetProgram.parseResponse(this, response.data);
        });
    }

    public approve(comment: string | null, form: FormRef): Promise<void> {
        const data: MoneyBudgetProgramMilestoneAdd = {
            type: MoneyBudgetProgramMilestoneType.APPROVE,
            comment: comment
        };

        return MoneyApi.budgetProgramsAddMilestone(this.id, data, {form}).then(response => {
            BudgetProgram.parseResponse(this, response.data);
        });
    }

    public reject(comment: string | null, form: FormRef): Promise<void> {
        const data: MoneyBudgetProgramMilestoneAdd = {
            type: MoneyBudgetProgramMilestoneType.REJECT,
            comment: comment
        };

        return MoneyApi.budgetProgramsAddMilestone(this.id, data, {form}).then(response => {
            BudgetProgram.parseResponse(this, response.data);
        });
    }

    public finish(comment: string | null, form: FormRef): Promise<void> {
        const data: MoneyBudgetProgramMilestoneAdd = {
            type: MoneyBudgetProgramMilestoneType.FINISH,
            comment: comment
        };

        return MoneyApi.budgetProgramsAddMilestone(this.id, data, {form}).then(response => {
            BudgetProgram.parseResponse(this, response.data);
        });
    }

    public archive(comment: string | null, form: FormRef): Promise<void> {
        const data: MoneyBudgetProgramMilestoneAdd = {
            type: MoneyBudgetProgramMilestoneType.ARCHIVE,
            comment: comment
        };

        return MoneyApi.budgetProgramsAddMilestone(this.id, data, {form}).then(response => {
            BudgetProgram.parseResponse(this, response.data);
        });
    }

    public canEdit(user: User): boolean {
        if (!user.hasGroupPermission(this.group, BaseGroupSpecificPermission.MANAGE_MONEY)) {
            return false;
        }

        return this.status === BudgetProgramStatus.DRAFT || this.status === BudgetProgramStatus.REJECTED;
    }
}
