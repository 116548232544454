/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { KprCategoryStore } from './KprCategoryStore';
    import {
    KprCategoryStoreFromJSON,
    KprCategoryStoreFromJSONTyped,
    KprCategoryStoreToJSON,
    KprCategoryStoreCreateEmpty,
    } from './KprCategoryStore';
import {DateTime} from '@Utils/DateTime';

export interface KprTenderStore {
  spendingPeriod: number;
  categories: Array<KprCategoryStore>;
}

export const KprTenderStoreSchema = {
  'spendingPeriod': {
    name: 'spendingPeriod',
    required: true,
    nullable: false,
    label: 'Gazdasági év',
    type: 'number',
      minimum: 0,
  },
  'categories': {
    name: 'categories',
    required: true,
    nullable: false,
    label: 'Kategóriák',
      type: 'array',
  },
}
export function instanceOfKprTenderStore(value: object): value is KprTenderStore {
let isInstance = true;
    isInstance = isInstance && "spendingPeriod" in value;
    isInstance = isInstance && "categories" in value;

return isInstance;
}

export function KprTenderStoreFromJSON(json: any): KprTenderStore {
return KprTenderStoreFromJSONTyped(json, false);
}

export function KprTenderStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): KprTenderStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'spendingPeriod': json['spendingPeriod'],
            'categories': ((json['categories'] as Array<any>).map(KprCategoryStoreFromJSON)),
    };
  }

  export function KprTenderStoreToJSON(value?: KprTenderStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'spendingPeriod': value.spendingPeriod,
              'categories': ((value.categories as Array<any>).map(KprCategoryStoreToJSON)),
    };
  }

  export function KprTenderStoreCreateEmpty(): KprTenderStore {
  return {
      'spendingPeriod': 0,
      'categories': [],
  };
  }

