<template>
    <PegasusModal header="Kivezetés döntés">
        <PegasusForm ref="form">
            <TextareaField
                v-model="comment"
                :schema="InventoryTerminationDecisionSchema.comment"
            ></TextareaField>

            <PegasusButton type="submit" variant="success" @click="decide(true)">
                Elfogadás
            </PegasusButton>

            <PegasusButton type="submit" variant="danger" @click="decide(false)">
                Elutasítás
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {Item} from "@Models/inventory/Item";
import {ref} from "vue";
import toast from "@Utils/toast";
import {InventoryTerminationDecisionSchema} from "@/api/models";
import TextareaField from "@Components/base/form/types/TextareaField.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusModal from "@Components/base/PegasusModal.vue";
import type {FormRef} from '@/vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import {useReturn} from '@Utils/dialog';

const {item} = defineProps<{
    item: Item
}>();

const pending = item.getPendingTermination();
if (pending === undefined) {
    throw new Error('Cannot approve nonexisting termination');
}

const termination = ref(pending);

const form = ref<FormRef>(null);

const comment = ref('');

const returnValue = useReturn();

function decide(decision: boolean) {
    let request = termination.value.decide(decision, comment.value, form.value).then(() => {
        const existing = item.updates.findIndex(update => update.id === termination.value.id);
        item.updates[existing] = termination.value;

        returnValue(decision);
    });

    toast.loading(request);
}
</script>
