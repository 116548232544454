<template>
    <PegasusPanel v-model="collapsed" :class="classObject" toggleable>
        <template #header>
            <h5>{{ item.displayName }}</h5>

            <div class="kpr-item-header">
                <PegasusButton :variant="isEmpty(item.comments) ? 'primary' : 'warning'" size="xs" @click="openComments">
                    <i class="fa fa-comment"></i> Megjegyzések: {{ size(item.comments) }}
                </PegasusButton>

                <PegasusButton v-if="permissions.canDecide" size="xs" variant="success" @click="decide(item.price)">
                    <i class="fa fa-check"></i>
                </PegasusButton>

                <PegasusButton v-if="permissions.canDecide" size="xs" variant="danger" @click="decide(0)">
                    <i class="fa fa-times"></i>
                </PegasusButton>

                <span class="badge badge-pill bg-primary price-approved">
                    <i class="fas fa-pen"></i> {{ item.price.huf() }}
                </span>

                <template v-if="item.type !== null">
                    <span v-if="item.approvedPrice === null" class="badge badge-pill bg-primary price-approved">
                        <i class="fas fa-gavel"></i> bírálásra vár
                    </span>
                    <span
                        v-else-if="item.approvedPrice === item.price"
                        class="badge badge-pill bg-success price-approved"
                    >
                        <i class="fas fa-gavel"></i> {{ item.approvedPrice.huf() }}
                    </span>
                    <span v-else-if="item.approvedPrice !== 0" class="badge badge-pill bg-warning price-approved">
                        <i class="fas fa-gavel"></i> {{ item.approvedPrice.huf() }}
                    </span>
                    <span v-else-if="item.approvedPrice === 0" class="badge badge-pill bg-danger price-approved">
                        <i class="fas fa-gavel"></i> {{ item.approvedPrice.huf() }}
                    </span>

                    <span
                        v-if="item.finalPrice !== null && item.finalPrice !== 0"
                        class="badge badge-pill bg-warning price-approved"
                    >
                        <i class="fas fa-stamp"></i> {{ item.finalPrice.huf() }}
                    </span>
                    <span v-else-if="item.finalPrice === 0" class="badge badge-pill bg-danger price-approved">
                        <i class="fas fa-stamp"></i> eldobva
                    </span>
                </template>
            </div>
        </template>

        <ItemDetails
            v-model:dirty="dirty"
            v-model:form="form"
            :item="item"
            :permissions="permissions"
            :visible="!collapsed"
        ></ItemDetails>

        <PegasusButton v-if="permissions.canEdit" title="Törlés" variant="danger" @click="emit('delete')">
            <i class="fa fa-times"></i> Tétel törlése
        </PegasusButton>

        <PegasusButton
            v-if="permissions.canEdit && item.type !== null"
            title="Mozgatás"
            variant="primary"
            @click="move"
        >
            <i class="fa fa-truck-moving"></i> Mozgatás
        </PegasusButton>

        <PegasusButton v-if="permissions.canEdit" variant="success" @click="save">
            <i class="fa fa-save"></i> Mentés
        </PegasusButton>

        <template v-if="permissions.canDecide">
            <hr/>

            <ItemDecisionBar :item="item" @decide="decide"></ItemDecisionBar>
        </template>

        <template v-if="permissions.canFinalize">
            <hr/>

            <ItemFinalizingBar :item="item" @finalize="finalize"></ItemFinalizingBar>
        </template>
    </PegasusPanel>
</template>

<script lang="ts" setup>
import {Item} from "@Models/money/Item";
import {computed, ref} from "vue";
import toast from "@Utils/toast";
import type {KprPermissions} from "@Models/rvt/kpr/Application";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import {usePreventExit} from "@Utils/composables";
import {isEmpty, size} from "lodash-es";
import type {FormRef} from "@/vue";
import ItemCommentsDialog from '@Components/money/item/ItemCommentsDialog.vue';
import {Dialog} from '@Utils/dialog';
import ItemMoveDialog from '@Components/money/item/ItemMoveDialog.vue';
import ItemDecisionBar from '@Components/money/item/ItemDecisionBar.vue';
import ItemFinalizingBar from '@Components/money/item/ItemFinalizingBar.vue';
import ItemDetails from '@Components/money/item/ItemDetails.vue';

const item = defineModel<Item>("item", {required: true});

const {permissions} = defineProps<{
    permissions: KprPermissions;
}>();

const emit = defineEmits<{
    delete: [];
}>();

const collapsed = ref(true);

const dirty = ref(false);

usePreventExit(() => dirty.value);

const form = ref<FormRef>(null);

const classObject = computed(() => {
    return {
        "kpr-item mb-3": true,
        "card-warning": dirty.value,
        "card-success": !dirty.value,
    };
});

function move() {
    Dialog.open(ItemMoveDialog, {item}).then(result => {
        if (result) {
            location.reload();
        }
    });
}

function openComments() {
    Dialog.open(ItemCommentsDialog, {
        item: item,
    });
}

function save() {
    let request = item.value.save(form.value).then(() => {
        dirty.value = false;
    });

    toast.loading(request);

    return request;
}

function decide(result: number) {
    dirty.value = true;

    let request = item.value.decide(result).then(() => {
        dirty.value = false;
    });

    toast.loading(request);
}

function finalize(price: number) {
    dirty.value = true;

    const request = item.value.finalize(price).then(() => {
        dirty.value = false;
    });

    toast.loading(request);
}

function approveIfNotAlready() {
    if (item.value.approvedPrice === null) {
        decide(item.value.price);
    }
}

function saveIfDirty() {
    if (dirty.value) {
        save();
    }
}

defineExpose({
    approveIfNotAlready,
    decide,
    saveIfDirty,
    dirty,
});
</script>
