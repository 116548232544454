/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { KprCategoryStatus } from './KprCategoryStatus';
    import {
    KprCategoryStatusFromJSON,
    KprCategoryStatusFromJSONTyped,
    KprCategoryStatusToJSON,
    KprCategoryStatusCreateEmpty,
    } from './KprCategoryStatus';
import {DateTime} from '@Utils/DateTime';
import { KprCategoryStatusValues } from './KprCategoryStatus';

export interface KprCategoryUpdate {
  category: number;
  status: KprCategoryStatus;
  endDrafting: DateTime;
  budgetItem: number | null;
}

export const KprCategoryUpdateSchema = {
  'category': {
    name: 'category',
    required: true,
    nullable: false,
    label: 'KPR kategória',
    type: 'number',
      minimum: 0,
  },
  'status': {
    name: 'status',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "",
        enumValues: KprCategoryStatusValues,
  },
  'endDrafting': {
    name: 'endDrafting',
    required: true,
    nullable: false,
    label: 'KPR pályázási határidő',
      labelHelp: `Ebben az időpont a rendszer automatikusan Átnézési időszak-ra módosítja a kategória státuszát`,
    type: 'datetime',
  },
  'budgetItem': {
    name: 'budgetItem',
    required: true,
    nullable: true,
    label: 'Költségvetési tétel',
    type: 'number',
      minimum: 0,
  },
}
export function instanceOfKprCategoryUpdate(value: object): value is KprCategoryUpdate {
let isInstance = true;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "endDrafting" in value;
    isInstance = isInstance && "budgetItem" in value;

return isInstance;
}

export function KprCategoryUpdateFromJSON(json: any): KprCategoryUpdate {
return KprCategoryUpdateFromJSONTyped(json, false);
}

export function KprCategoryUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): KprCategoryUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'category': json['category'],
              'status': KprCategoryStatusFromJSON(json['status']),
        'endDrafting': (DateTime.fromISO(json['endDrafting'])),
          'budgetItem': json['budgetItem'],
    };
  }

  export function KprCategoryUpdateToJSON(value?: KprCategoryUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'category': value.category,
                'status': KprCategoryStatusToJSON(value.status),
            'endDrafting': (value.endDrafting.toISO()),
                'budgetItem': value.budgetItem,
    };
  }

  export function KprCategoryUpdateCreateEmpty(): KprCategoryUpdate {
  return {
      'category': 0,
          'status': KprCategoryStatusCreateEmpty(),
      'endDrafting': DateTime.now(),
      'budgetItem': 0,
  };
  }

