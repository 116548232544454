/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { KprApplicationWithItemsResponse } from './KprApplicationWithItemsResponse';
    import {
    KprApplicationWithItemsResponseFromJSON,
    KprApplicationWithItemsResponseFromJSONTyped,
    KprApplicationWithItemsResponseToJSON,
    KprApplicationWithItemsResponseCreateEmpty,
    } from './KprApplicationWithItemsResponse';
    import type { KprTenderResponse } from './KprTenderResponse';
    import {
    KprTenderResponseFromJSON,
    KprTenderResponseFromJSONTyped,
    KprTenderResponseToJSON,
    KprTenderResponseCreateEmpty,
    } from './KprTenderResponse';
    import type { RvtSpendingPeriodResponse } from './RvtSpendingPeriodResponse';
    import {
    RvtSpendingPeriodResponseFromJSON,
    RvtSpendingPeriodResponseFromJSONTyped,
    RvtSpendingPeriodResponseToJSON,
    RvtSpendingPeriodResponseCreateEmpty,
    } from './RvtSpendingPeriodResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseGroupSummaryPeriodResponse {
  period: RvtSpendingPeriodResponse;
  waitingClaims: number | null;
  pendingClaims: number | null;
  tender: KprTenderResponse | null;
  application: KprApplicationWithItemsResponse | null;
  waitingAmends: number | null;
  pendingAmends: number | null;
}

export const BaseGroupSummaryPeriodResponseSchema = {
  'period': {
    name: 'period',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'waitingClaims': {
    name: 'waitingClaims',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'pendingClaims': {
    name: 'pendingClaims',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'tender': {
    name: 'tender',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'application': {
    name: 'application',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'waitingAmends': {
    name: 'waitingAmends',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'pendingAmends': {
    name: 'pendingAmends',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 0,
  },
}
export function instanceOfBaseGroupSummaryPeriodResponse(value: object): value is BaseGroupSummaryPeriodResponse {
let isInstance = true;
    isInstance = isInstance && "period" in value;
    isInstance = isInstance && "waitingClaims" in value;
    isInstance = isInstance && "pendingClaims" in value;
    isInstance = isInstance && "tender" in value;
    isInstance = isInstance && "application" in value;
    isInstance = isInstance && "waitingAmends" in value;
    isInstance = isInstance && "pendingAmends" in value;

return isInstance;
}

export function BaseGroupSummaryPeriodResponseFromJSON(json: any): BaseGroupSummaryPeriodResponse {
return BaseGroupSummaryPeriodResponseFromJSONTyped(json, false);
}

export function BaseGroupSummaryPeriodResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseGroupSummaryPeriodResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'period': RvtSpendingPeriodResponseFromJSON(json['period']),
          'waitingClaims': json['waitingClaims'],
          'pendingClaims': json['pendingClaims'],
              'tender': KprTenderResponseFromJSON(json['tender']),
              'application': KprApplicationWithItemsResponseFromJSON(json['application']),
          'waitingAmends': json['waitingAmends'],
          'pendingAmends': json['pendingAmends'],
    };
  }

  export function BaseGroupSummaryPeriodResponseToJSON(value?: BaseGroupSummaryPeriodResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'period': RvtSpendingPeriodResponseToJSON(value.period),
                'waitingClaims': value.waitingClaims,
                'pendingClaims': value.pendingClaims,
                'tender': KprTenderResponseToJSON(value.tender),
                'application': KprApplicationWithItemsResponseToJSON(value.application),
                'waitingAmends': value.waitingAmends,
                'pendingAmends': value.pendingAmends,
    };
  }

  export function BaseGroupSummaryPeriodResponseCreateEmpty(): BaseGroupSummaryPeriodResponse {
  return {
          'period': RvtSpendingPeriodResponseCreateEmpty(),
      'waitingClaims': 0,
      'pendingClaims': 0,
          'tender': KprTenderResponseCreateEmpty(),
          'application': KprApplicationWithItemsResponseCreateEmpty(),
      'waitingAmends': 0,
      'pendingAmends': 0,
  };
  }

