/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface RvtClaimStore {
  group: number;
  tender: number;
}

export const RvtClaimStoreSchema = {
  'group': {
    name: 'group',
    required: true,
    nullable: false,
    label: 'Kör',
    type: 'number',
      minimum: 0,
  },
  'tender': {
    name: 'tender',
    required: true,
    nullable: false,
    label: 'KPR',
    type: 'number',
      minimum: 0,
  },
}
export function instanceOfRvtClaimStore(value: object): value is RvtClaimStore {
let isInstance = true;
    isInstance = isInstance && "group" in value;
    isInstance = isInstance && "tender" in value;

return isInstance;
}

export function RvtClaimStoreFromJSON(json: any): RvtClaimStore {
return RvtClaimStoreFromJSONTyped(json, false);
}

export function RvtClaimStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvtClaimStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'group': json['group'],
          'tender': json['tender'],
    };
  }

  export function RvtClaimStoreToJSON(value?: RvtClaimStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'group': value.group,
                'tender': value.tender,
    };
  }

  export function RvtClaimStoreCreateEmpty(): RvtClaimStore {
  return {
      'group': 0,
      'tender': 0,
  };
  }

