<template>
    <PegasusModal header="Házszintű kategória">
        <PegasusForm ref="form">
            <TextField
                v-model="name"
                :disabled="!canEdit"
                :schema="MoneyBudgetCategoryUpdateSchema.name"
            ></TextField>

            <TextareaField
                v-model="description"
                :disabled="!canEdit"
                :schema="MoneyBudgetCategoryUpdateSchema.description"
            ></TextareaField>

            <NumberField
                v-model="number"
                :disabled="!canEdit"
                :schema="MoneyBudgetCategoryUpdateSchema.number"
            ></NumberField>

            <PegasusButton v-if="canEdit" variant="success" @click="save">
                <i class="fa fa-save"></i> Mentés
            </PegasusButton>

            <PegasusButton v-if="canEdit" variant="primary" @click="newItem">
                <i class="fa fa-plus"></i> Új tétel létrehozása
            </PegasusButton>

            <PegasusButton v-if="canEdit" variant="danger" @click="remove">
                <i class="fa fa-trash"></i> Kategória törlése
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import PegasusModal from '@Components/base/PegasusModal.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import TextField from '@Components/base/form/types/TextField.vue';
import {MoneyBudgetCategoryUpdateSchema} from '@/api/models';
import type {BudgetCategory} from '@Models/money/BudgetCategory';
import {ref} from 'vue';
import type {FormRef} from '@/vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import toast from '@Utils/toast';
import {useReturn} from '@Utils/dialog';
import TextareaField from '@Components/base/form/types/TextareaField.vue';
import {BudgetItem} from '@Models/money/BudgetItem';
import {last} from 'lodash-es';
import NumberField from '@Components/base/form/types/NumberField.vue';

const {category, canEdit} = defineProps<{
    category: BudgetCategory,
    canEdit: boolean
}>();

const emit = defineEmits<{
    delete: []
}>();

const form = ref<FormRef>(null);

const name = ref(category.name);
const description = ref(category.description);
const number = ref(category.number);

const returnValue = useReturn();

function save() {
    const request = category.update(name.value, description.value, null, number.value, form.value).then(() => {
        returnValue(true);
    });

    toast.loading(request);
}

function newItem() {
    const item = new BudgetItem();

    const lastItem = last(category.itemsInOrder());

    const request = item.store(
        'Új tétel',
        '',
        (lastItem?.number ?? 0) + 1,
        category
    ).then(item => {
        category.items[item.id] = item;
    });

    toast.loading(request, 'Létrehozás');
}

async function remove() {
    if (!await toast.confirm('Biztos vagy benne?', 'Minden benne lévő tételt is töröl!')) {
        return;
    }

    const request = category.delete().then(() => {
        emit('delete');
        returnValue(false);
    });

    toast.loading(request, 'Törlés');
}
</script>
