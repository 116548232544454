<template>
    <PegasusPanel v-if="canEdit" class="mb-3" header="Műveletek">
        <dt>Állapot</dt>
        <PegasusButton variant="success" @click="Dialog.open(RepairDialog, {item})">
            <i :class="icons[InventoryUpdateType.REPAIR][0]"></i> {{ InventoryUpdateTypeValues.repair }}
        </PegasusButton>
        <PegasusButton variant="warning" @click="Dialog.open(DamageDialog, {item})">
            <i :class="icons[InventoryUpdateType.DAMAGE][0]"></i> {{ InventoryUpdateTypeValues.damage }}
        </PegasusButton>
        <PegasusButton variant="danger" @click="Dialog.open(TerminationDialog, {item})">
            <i :class="icons[InventoryUpdateType.TERMINATION][0]"></i> {{ InventoryUpdateTypeValues.termination }}
        </PegasusButton>

        <dt class="pt-2">Adminisztráció</dt>
        <PegasusButton @click="Dialog.open(DataChangeDialog, {item, summary})">
            <i :class="icons[InventoryUpdateType.DATA_CHANGE][0]"></i> {{ InventoryUpdateTypeValues.dataChange }}
        </PegasusButton>
        <PegasusButton
            :disabled="!!pendingTransfer"
            :popover="pendingTransfer ? 'Már folyamatban van egy átvezetési kérelem' : undefined"
            variant="warning"
            @click="Dialog.open(TransferDialog, {item})"
        >
            <i :class="icons[InventoryUpdateType.TRANSFER][0]"></i> {{ InventoryUpdateTypeValues.transfer }}
        </PegasusButton>
        <PegasusButton v-if="user?.hasPermission(BaseGlobalPermission.ADMIN_ACL)" variant="danger" @click="destroy">
            <i class="fa fa-trash"></i> Törlés!
        </PegasusButton>

        <dt class="pt-2">Dokumentum feltöltés</dt>
        <PegasusButton @click="Dialog.open(DocumentUploadDialog, {item, documentType: InventoryDocumentType.IMAGE})">
            <i class="fa fa-image"></i> Fénykép
        </PegasusButton>
        <PegasusButton @click="Dialog.open(DocumentUploadDialog, {item, documentType: InventoryDocumentType.INVOICE})">
            <i class="fa fa-receipt"></i> Számla
        </PegasusButton>
        <PegasusButton @click="Dialog.open(DocumentUploadDialog, {item, documentType: InventoryDocumentType.WARRANTY})">
            <i class="fa fa-person-falling"></i> Garanciajegy
        </PegasusButton>
        <PegasusButton @click="Dialog.open(DocumentUploadDialog, {item, documentType: InventoryDocumentType.CONTRACT})">
            <i class="fa fa-file-signature"></i> Szerződés
        </PegasusButton>
    </PegasusPanel>

    <PegasusPanel v-if="pendingTransfer" class="mb-3" header="Folyamatban lévő átvezetés">
        <dl>
            <dd>
                <b>Kezdeményező kör:</b> {{ pendingTransfer.from.name }}
            </dd>
            <dd>
                <b>Kezdeményező:</b> {{ pendingTransfer.creator.name }}
            </dd>
            <dd>
                <b>Folyamat indítása:</b> {{ pendingTransfer.createdAt.datetime() }}
            </dd>
            <dd>
                <b>Cél kör:</b> {{ pendingTransfer.to.name }}
            </dd>
        </dl>

        <div v-if="user.hasGroupPermission(pendingTransfer.to, BaseGroupSpecificPermission.MANAGE_INVENTORY)">
            <PegasusButton variant="success" @click="Dialog.open(TransferDecideDialog, {item})">
                Döntés
            </PegasusButton>
        </div>
    </PegasusPanel>

    <PegasusPanel v-if="pendingTermination" class="mb-3" header="Folyamatban lévő kivezetés">
        <dl>
            <dd>
                <b>Kivezetés oka:</b> {{ InventoryTerminationReasonValues[pendingTermination.reason] }}
            </dd>
            <dd>
                <b>Megjegyzés:</b> {{ pendingTermination.comment }}
            </dd>
            <dd>
                <b>Kezdeményező:</b> {{ pendingTermination.creator.name }}
            </dd>
            <dd>
                <b>Folyamat indítása:</b> {{ pendingTermination.createdAt.datetime() }}
            </dd>
        </dl>

        <div v-if="user.hasPermission(BaseGlobalPermission.INVENTORY_ADMIN)">
            <PegasusButton variant="success" @click="Dialog.open(TerminationDecideDialog, {item})">
                Döntés
            </PegasusButton>
        </div>
    </PegasusPanel>

    <PegasusPanel :header-bg-variant="headerVariant" header="Állapot">
        <template v-if="pendingTermination">
            <dt>A tétel kivezetés alatt áll</dt>

            <hr>
        </template>

        <template v-if="termination">
            <dt>A tétel ki lett vezetve a leltárból</dt>
            <dd>Kivezetés oka: {{ InventoryTerminationReasonValues[termination.reason] }}</dd>
            <dd v-if="termination.approval.decisionTime">
                Kivezetés időpontja: {{ termination.approval.decisionTime.datetime() }}
            </dd>
        </template>

        <dt>Legfrisebb állapotbejegyzés</dt>
        <dd v-if="latestCheck && latestCheck.quality !== null">
            {{ InventoryQualityValues[latestCheck.quality] }} -
            <i>({{ latestCheck.createdAt.diffBigNow() }})</i>

            <PegasusHelp header="Legfrisebb állapotbejegyzés" variant="info" wide>
                <dl>
                    <dt>Bejegyzés típusa</dt>
                    <dd>{{ latestCheck.getName() }}</dd>
                    <dt>Időpont</dt>
                    <dd>{{ latestCheck.createdAt.datetime() }}</dd>
                    <dt>Létrehozó</dt>
                    <dd>{{ latestCheck.creator.name }}</dd>
                    <template v-for="data in latestCheck.text" :key="data.key">
                        <dt>{{ data.key }}</dt>
                        <dd>{{ data.value }}</dd>
                    </template>
                </dl>
            </PegasusHelp>
        </dd>
        <dd v-else>Nincs adat</dd>

        <dt class="pt-2">Cimkék</dt>
        <TagSelector :item="item"></TagSelector>

        <dt v-if="imageData">Kép</dt>
        <PegasusImage v-if="imageData" :src="imageData" image-class="img-fluid" preview></PegasusImage>
    </PegasusPanel>
</template>

<script lang="ts" setup>
import {Item} from "@Models/inventory/Item";
import {ItemUpdate} from "@Models/inventory/ItemUpdate";
import {
    BaseGlobalPermission,
    BaseGroupSpecificPermission,
    InventoryQualityValues,
    InventoryTerminationReasonValues,
    InventoryDocumentType,
    InventoryUpdateType,
    InventoryUpdateTypeValues,
} from "@/api/models";
import {computed, ref} from "vue";
import {redirect} from "@/router";
import toast from "@Utils/toast";
import PegasusImage from "@Components/base/PegasusImage.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import {useAuthStore} from "@/stores/auth";
import type {InventoryGroupSummary} from "@Models/inventory/InventoryGroupSummary";
import {Dialog} from '@Utils/dialog';
import RepairDialog from '@Components/inventory/item/dialogs/RepairDialog.vue';
import DamageDialog from '@Components/inventory/item/dialogs/DamageDialog.vue';
import TerminationDialog from '@Components/inventory/item/dialogs/TerminationDialog.vue';
import DataChangeDialog from '@Components/inventory/item/dialogs/DataChangeDialog.vue';
import TransferDialog from '@Components/inventory/item/dialogs/TransferDialog.vue';
import TransferDecideDialog from '@Components/inventory/item/dialogs/TransferDecideDialog.vue';
import DocumentUploadDialog from '@Components/inventory/item/dialogs/DocumentUploadDialog.vue';
import TagSelector from '@Components/inventory/item/tags/TagSelector.vue';
import PegasusHelp from '@Components/base/PegasusHelp.vue';
import TerminationDecideDialog from "@Components/inventory/item/dialogs/TerminationDecideDialog.vue";

const {item} = defineProps<{
    item: Item,
    summary: InventoryGroupSummary
}>();

const user = useAuthStore().requireUser();

const image = item.getLatestImage();
const imageData = ref<string | null>(null);

const canEdit = computed(() => {
    if (pendingTermination.value || termination.value) {
        return false;
    }

    return user.value.hasGroupPermission(item.responsible, BaseGroupSpecificPermission.MANAGE_INVENTORY);
});

if (image) {
    image.download().then(file => {
        file.getAsDataUrl().then(url => {
            imageData.value = url;
        });
    });
}

const latestCheck = computed(() => item.getLatestCheck());

async function destroy() {
    if (!await toast.confirm('Biztos vagy benne?')) {
        return;
    }

    if (!await toast.confirm('Tényleg biztos vagy benne?', 'VISSZAVONHATATLAN MŰVELET')) {
        return;
    }

    let request = item.destroy().then(() => {
        redirect({
            name: 'inventory.groups.index',
            params: {
                group: item.responsible.id.toString(),
            },
        });
    });

    toast.loading(request, 'Törlés');
}

const headerVariant = computed(() => {
    if (item.getPendingTermination()) {
        return 'warning';
    }

    if (item.getTermination()) {
        return 'danger';
    }

    return undefined;
});

const icons = ItemUpdate.getIcons();

const pendingTransfer = computed(() => item.getPendingTransfer());

const pendingTermination = computed(() => item.getPendingTermination());

const termination = computed(() => item.getTermination());
</script>
