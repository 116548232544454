/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { RvtSpendingPeriodResponse } from './RvtSpendingPeriodResponse';
    import {
    RvtSpendingPeriodResponseFromJSON,
    RvtSpendingPeriodResponseFromJSONTyped,
    RvtSpendingPeriodResponseToJSON,
    RvtSpendingPeriodResponseCreateEmpty,
    } from './RvtSpendingPeriodResponse';
import {DateTime} from '@Utils/DateTime';

export interface RvtSpendingPeriodSummaryResponse {
  period: RvtSpendingPeriodResponse;
  tenderId: number | null;
}

export const RvtSpendingPeriodSummaryResponseSchema = {
  'period': {
    name: 'period',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'tenderId': {
    name: 'tenderId',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 1,
  },
}
export function instanceOfRvtSpendingPeriodSummaryResponse(value: object): value is RvtSpendingPeriodSummaryResponse {
let isInstance = true;
    isInstance = isInstance && "period" in value;
    isInstance = isInstance && "tenderId" in value;

return isInstance;
}

export function RvtSpendingPeriodSummaryResponseFromJSON(json: any): RvtSpendingPeriodSummaryResponse {
return RvtSpendingPeriodSummaryResponseFromJSONTyped(json, false);
}

export function RvtSpendingPeriodSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvtSpendingPeriodSummaryResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'period': RvtSpendingPeriodResponseFromJSON(json['period']),
          'tenderId': json['tenderId'],
    };
  }

  export function RvtSpendingPeriodSummaryResponseToJSON(value?: RvtSpendingPeriodSummaryResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'period': RvtSpendingPeriodResponseToJSON(value.period),
                'tenderId': value.tenderId,
    };
  }

  export function RvtSpendingPeriodSummaryResponseCreateEmpty(): RvtSpendingPeriodSummaryResponse {
  return {
          'period': RvtSpendingPeriodResponseCreateEmpty(),
      'tenderId': 0,
  };
  }

