/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { KprCategoryResponse } from './KprCategoryResponse';
    import {
    KprCategoryResponseFromJSON,
    KprCategoryResponseFromJSONTyped,
    KprCategoryResponseToJSON,
    KprCategoryResponseCreateEmpty,
    } from './KprCategoryResponse';
    import type { MoneyItemGroupWithItemsResponse } from './MoneyItemGroupWithItemsResponse';
    import {
    MoneyItemGroupWithItemsResponseFromJSON,
    MoneyItemGroupWithItemsResponseFromJSONTyped,
    MoneyItemGroupWithItemsResponseToJSON,
    MoneyItemGroupWithItemsResponseCreateEmpty,
    } from './MoneyItemGroupWithItemsResponse';
import {DateTime} from '@Utils/DateTime';

export interface KprCategoryApplicationResponse {
  id: number;
  category: KprCategoryResponse;
  applicationId: number;
  itemGroup: MoneyItemGroupWithItemsResponse;
  canEdit: boolean;
  canEditResult: boolean;
  canDecide: boolean;
  canFinalize: boolean;
  canConfidential: boolean;
}

export const KprCategoryApplicationResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'category': {
    name: 'category',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'applicationId': {
    name: 'applicationId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'itemGroup': {
    name: 'itemGroup',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'canEdit': {
    name: 'canEdit',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'canEditResult': {
    name: 'canEditResult',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'canDecide': {
    name: 'canDecide',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'canFinalize': {
    name: 'canFinalize',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'canConfidential': {
    name: 'canConfidential',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
}
export function instanceOfKprCategoryApplicationResponse(value: object): value is KprCategoryApplicationResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "applicationId" in value;
    isInstance = isInstance && "itemGroup" in value;
    isInstance = isInstance && "canEdit" in value;
    isInstance = isInstance && "canEditResult" in value;
    isInstance = isInstance && "canDecide" in value;
    isInstance = isInstance && "canFinalize" in value;
    isInstance = isInstance && "canConfidential" in value;

return isInstance;
}

export function KprCategoryApplicationResponseFromJSON(json: any): KprCategoryApplicationResponse {
return KprCategoryApplicationResponseFromJSONTyped(json, false);
}

export function KprCategoryApplicationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KprCategoryApplicationResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
              'category': KprCategoryResponseFromJSON(json['category']),
          'applicationId': json['applicationId'],
              'itemGroup': MoneyItemGroupWithItemsResponseFromJSON(json['itemGroup']),
          'canEdit': json['canEdit'],
          'canEditResult': json['canEditResult'],
          'canDecide': json['canDecide'],
          'canFinalize': json['canFinalize'],
          'canConfidential': json['canConfidential'],
    };
  }

  export function KprCategoryApplicationResponseToJSON(value?: KprCategoryApplicationResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'category': KprCategoryResponseToJSON(value.category),
                'applicationId': value.applicationId,
                'itemGroup': MoneyItemGroupWithItemsResponseToJSON(value.itemGroup),
                'canEdit': value.canEdit,
                'canEditResult': value.canEditResult,
                'canDecide': value.canDecide,
                'canFinalize': value.canFinalize,
                'canConfidential': value.canConfidential,
    };
  }

  export function KprCategoryApplicationResponseCreateEmpty(): KprCategoryApplicationResponse {
  return {
      'id': 0,
          'category': KprCategoryResponseCreateEmpty(),
      'applicationId': 0,
          'itemGroup': MoneyItemGroupWithItemsResponseCreateEmpty(),
      'canEdit': false,
      'canEditResult': false,
      'canDecide': false,
      'canFinalize': false,
      'canConfidential': false,
  };
  }

