<template>
    <PegasusPanel small-header>
        <template #header>
            Információk
            <PegasusButton
                v-if="permissions.canEdit && (program.status === BudgetProgramStatus.DRAFT || program.status === BudgetProgramStatus.REJECTED)"
                block
                size="sm"
                @click="Dialog.open(ProgramUpdateDialog, {program})"
            >
                <i class="fa fa-edit"></i>
            </PegasusButton>
        </template>

        <dt>Költségvetés leírás</dt>
        <dd>
            {{ program.description }}
        </dd>

        <dt>Kérvényező neve</dt>
        <dd>{{ program.responsible.name }}
            (<a :href="'mailto:' + program.responsible.email" target="_blank">{{ program.responsible.email }}</a>)
        </dd>

        <dt>Kör / csoport neve</dt>
        <dd>{{ program.group.name }}</dd>

        <dt>Állapot</dt>
        <dd>{{ program.statusText }}</dd>

        <dt>Házszintű tétel</dt>
        <dd>{{ program.budgetItemName }}</dd>

        <dt>Műveletek</dt>
        <dd>
            <PegasusButton
                v-if="permissions.canEdit && program.status === BudgetProgramStatus.DRAFT"
                variant="info"
                @click="Dialog.open(SubmitDialog, {program})"
            >
                <i class="fa fa-paper-plane"></i> Véglegesítés
            </PegasusButton>

            <PegasusButton
                v-if="permissions.canDecide && (program.status === BudgetProgramStatus.REJECTED || program.status === BudgetProgramStatus.WAITING)"
                variant="success"
                @click="Dialog.open(ApproveDialog, {program})"
            >
                <i class="fa fa-gavel"></i> Elfogadás
            </PegasusButton>

            <PegasusButton
                v-if="permissions.canDecide && (program.status === BudgetProgramStatus.APPROVED || program.status === BudgetProgramStatus.WAITING)"
                variant="danger"
                @click="Dialog.open(RejectDialog, {program})"
            >
                <i class="fa fa-gavel"></i> Visszadobás
            </PegasusButton>

            <PegasusButton
                v-if="permissions.canEdit && program.status === BudgetProgramStatus.APPROVED"
                variant="info"
                @click="Dialog.open(FinishDialog, {program})"
            >
                <i class="fa fa-check"></i> Lezárás
            </PegasusButton>

            <PegasusButton
                v-if="permissions.canDecide && program.status === BudgetProgramStatus.FINISHED"
                variant="success"
                @click="Dialog.open(ArchiveDialog, {program})"
            >
                <i class="fa fa-file-circle-check"></i> Elszámolás sikeres, archiválás
            </PegasusButton>
        </dd>

        <dt>Történet</dt>
        <div class="timeline">
            <div v-for="milestone in program.milestones" :key="milestone.id">
                <i :class="'fas text-white ' + icons[milestone.type]"></i>
                <div class="timeline-item">
                    <span class="time">
                        <i class="fas fa-clock"></i> {{ milestone.createdAt.datetime() }}
                    </span>

                    <h3 class="timeline-header">{{ MoneyBudgetProgramMilestoneTypeValues[milestone.type] }}</h3>

                    <div class="timeline-body">
                        <i class="fas fa-user"></i> {{ milestone.user.name }}<br>
                    </div>

                    <PegasusOverlay v-if="milestone.comment" :content="milestone.comment" header="Megjegyzés">
                        <div class="timeline-body">
                            <i class="fas fa-comment"></i> {{ milestone.comment.limit(40) }}
                        </div>
                    </PegasusOverlay>
                </div>
            </div>
        </div>
    </PegasusPanel>
</template>

<script lang="ts" setup>
import {type BudgetProgram, BudgetProgramStatus} from '@Models/money/BudgetProgram';
import type {KprPermissions} from '@Models/rvt/kpr/Application';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import {BudgetProgramMilestone} from '@Models/money/BudgetProgramMilestone';
import {MoneyBudgetProgramMilestoneTypeValues} from '@/api/models';
import PegasusOverlay from '@Components/base/PegasusOverlay.vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import {useAuthStore} from '@/stores/auth';
import {Dialog} from '@Utils/dialog';
import SubmitDialog from '@Components/money/budgetProgram/dialogs/SubmitDialog.vue';
import ApproveDialog from '@Components/money/budgetProgram/dialogs/ApproveDialog.vue';
import RejectDialog from '@Components/money/budgetProgram/dialogs/RejectDialog.vue';
import FinishDialog from '@Components/money/budgetProgram/dialogs/FinishDialog.vue';
import ArchiveDialog from '@Components/money/budgetProgram/dialogs/ArchiveDialog.vue';
import ProgramUpdateDialog from '@Components/money/budgetProgram/dialogs/ProgramUpdateDialog.vue';

const {program, permissions} = defineProps<{
    program: BudgetProgram,
    permissions: KprPermissions
}>();

useAuthStore().requireUser();

const icons = BudgetProgramMilestone.getIcons();
</script>
