/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneyBudgetCategoryUpdate {
  name: string;
  description: string;
  prefix: string | null;
  number: number;
}

export const MoneyBudgetCategoryUpdateSchema = {
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Név',
    type: 'string',
      minLength: 2,
      maxLength: 100,
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Leírás',
    type: 'string',
      minLength: 0,
      maxLength: 3000,
  },
  'prefix': {
    name: 'prefix',
    required: true,
    nullable: true,
    label: 'Prefix',
    type: 'string',
      minLength: 0,
      maxLength: 10,
  },
  'number': {
    name: 'number',
    required: true,
    nullable: false,
    label: 'Sorszám',
    type: 'number',
      minimum: 1,
      maximum: 100,
  },
}
export function instanceOfMoneyBudgetCategoryUpdate(value: object): value is MoneyBudgetCategoryUpdate {
let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "prefix" in value;
    isInstance = isInstance && "number" in value;

return isInstance;
}

export function MoneyBudgetCategoryUpdateFromJSON(json: any): MoneyBudgetCategoryUpdate {
return MoneyBudgetCategoryUpdateFromJSONTyped(json, false);
}

export function MoneyBudgetCategoryUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyBudgetCategoryUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'name': json['name'],
          'description': json['description'],
          'prefix': json['prefix'],
          'number': json['number'],
    };
  }

  export function MoneyBudgetCategoryUpdateToJSON(value?: MoneyBudgetCategoryUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'name': value.name,
                'description': value.description,
                'prefix': value.prefix,
                'number': value.number,
    };
  }

  export function MoneyBudgetCategoryUpdateCreateEmpty(): MoneyBudgetCategoryUpdate {
  return {
      'name': '',
      'description': '',
      'prefix': '',
      'number': 0,
  };
  }

