<template>
    <PegasusModal header="Nyílt pályázat létrehozása">
        <PegasusForm ref="form">
            <TextField
                v-model="tender.name"
                :schema="MoneyOpenTenderStoreSchema.name"
            ></TextField>

            <TextareaField
                v-model="tender.description"
                :schema="MoneyOpenTenderStoreSchema.description"
            ></TextareaField>

            <DatetimeField
                v-model="tender.deadline"
                :schema="MoneyOpenTenderStoreSchema.deadline"
            ></DatetimeField>

            <PegasusButton variant="success" @click="save">
                <i class="fa fa-save"></i> Létrehozás
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import PegasusModal from '@Components/base/PegasusModal.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import {ref} from 'vue';
import type {FormRef} from '@/vue';
import TextField from '@Components/base/form/types/TextField.vue';
import {MoneyOpenTenderStoreSchema} from '@/api/models';
import DatetimeField from '@Components/base/form/types/DatetimeField.vue';
import TextareaField from '@Components/base/form/types/TextareaField.vue';
import {OpenTender} from '@Models/money/OpenTender';
import PegasusButton from '@Components/base/PegasusButton.vue';
import toast from '@Utils/toast';
import type {BudgetItem} from '@Models/money/BudgetItem';
import {useReturn} from '@Utils/dialog';
import {redirect} from '@/router';

const {item} = defineProps<{
    item: BudgetItem
}>();

const form = ref<FormRef>(null);

const returnValue = useReturn();

const tender = ref(OpenTender.createNew(item));

async function save() {
    if (!await toast.confirm('Biztosan létre akarsz hozni nyílványos pályázatot?')) {
        return;
    }

    const request = tender.value.store(form.value).then(async() => {
        await redirect({
            name: 'money.openTenders.show',
            params: {
                tender: tender.value.id
            }
        });

        returnValue(true);
    });

    toast.loading(request, 'Létrehozás');
}
</script>
