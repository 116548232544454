<template>
    <footer class="main-footer">
        <div class="container">
            <div class="float-end d-none d-sm-inline">
                Schönherzes Villamosmérnökök és Informatikusok Egyesülete
            </div>

            <strong>
                <a href="" @click.prevent="impressum">Impresszum</a>
            </strong>

            <strong class="ms-5">
                <a href="" @click.prevent="help">Hibabejelentés, segítségkérés, fejlesztési ötletek</a>
            </strong>
        </div>
    </footer>
</template>

<script lang="ts" setup>
import {Dialog} from '@Utils/dialog';
import ImpressumDialog from '@Components/ImpressumDialog.vue';
import HelpDialog from '@Components/HelpDialog.vue';

const {supportEmail = 'support@rvt.sch.bme.hu'} = defineProps<{
    supportEmail?: string
}>();

function impressum() {
    Dialog.open(ImpressumDialog);
}

function help() {
    Dialog.open(HelpDialog, {supportEmail});
}
</script>
