<template>
    <ItemGroupComponent
        v-for="itemGroup in program.itemGroup.alternativeChildren"
        :key="itemGroup.id"
        :group="itemGroup"
        :permissions="permissions"
        @delete-group="deleteItemGroup(itemGroup)"
    >
    </ItemGroupComponent>

    <PegasusButton v-if="permissions.canEdit" variant="primary" @click="newItemGroup">
        <i class="fa fa-plus-square"></i>
        Új tételcsoport hozzáadása
    </PegasusButton>
</template>

<script lang="ts" setup>
import ItemGroupComponent from "@Components/money/ItemGroup.vue";
import type {BudgetProgram} from '@Models/money/BudgetProgram';
import type {KprPermissions} from '@Models/rvt/kpr/Application';
import PegasusButton from '@Components/base/PegasusButton.vue';
import {ItemGroup} from '@Models/money/ItemGroup';
import toast from '@Utils/toast';

const {program, permissions} = defineProps<{
    program: BudgetProgram,
    permissions: KprPermissions
}>();

function newItemGroup(): void {
    const group = ItemGroup.createNew('Névtelen tételcsoport');

    let request = group.storeForParentAsAlternative(program.itemGroup.id).then(() => {
        program.itemGroup.alternativeChildren[group.id] = group;
    });

    toast.loading(request, 'Létrehozás');
}

async function deleteItemGroup(itemGroup: ItemGroup) {
    if (!await toast.confirm('Biztosan ki szeretnéd törölni ezt a tételcsoportot?')) {
        return;
    }

    const request = itemGroup.delete().then(() => {
        delete program.itemGroup.alternativeChildren[itemGroup.id];
    });

    toast.loading(request, 'Törlés');
}
</script>
