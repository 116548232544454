<template>
    <PegasusHeading>{{ tender.displayName }}</PegasusHeading>

    <div class="row mb-3">
        <div class="col-md-5">
            <PegasusPanel header="Adatok">
                <dl>
                    <template v-for="category in tender.categories" :key="category.id">
                        <dt>{{ category.name }}</dt>

                        <dd>
                            {{ category.statusText }}
                            <PegasusHelp :title="category.statusText">
                                <permission-matrix :data="constants.kpr.permissionMatrix[category.status]"></permission-matrix>
                            </PegasusHelp>
                        </dd>

                        <dd v-if="category.isInDrafting()">Határidő: {{ category.deadlineText }}</dd>
                    </template>

                    <dt>Költési időszak</dt>
                    <dd>
                        {{ tender.spendingPeriod.dates }}
                    </dd>

                    <dt>Leadott pályázatok száma</dt>
                    <dd>{{ size(applications) }} db</dd>
                </dl>

                <PegasusButton v-if="user.hasPermission(BaseGlobalPermission.KPR_TENDER)" :params="{tender: tender}" size="sm" to="rvt.kpr.tender.admin" variant="primary">
                    <i class="fas fa-cogs"></i> Admin
                </PegasusButton>
            </PegasusPanel>
        </div>

        <div class="col-md-7">
            <PegasusPanel header="Statisztikák">
                <table class="table text-nowrap">
                    <thead>
                        <tr>
                            <th></th>
                            <th v-for="category in statistics" :key="category.category.id">
                                {{ category.category.name }}
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <th>Leadott összeg</th>
                            <td v-for="category in statistics" :key="category.category.id">
                                {{ category.priceSum.huf() }}
                            </td>
                        </tr>

                        <tr>
                            <th>Elvi bírálás</th>
                            <td v-for="category in statistics" :key="category.category.id">
                                {{ category.priceSumApproved.huf() }}
                            </td>
                        </tr>

                        <tr>
                            <th>Dobálás után</th>
                            <td v-for="category in statistics" :key="category.category.id">
                                {{ category.priceSumDecided.huf() }}
                            </td>
                        </tr>

                        <tr>
                            <th>Keretösszeg</th>
                            <td v-for="category in statistics" :key="category.category.id">
                                {{ category.category.budget?.huf() ?? '?' }}
                            </td>
                        </tr>

                        <tr>
                            <th>Túlköltés</th>
                            <td
                                v-for="category in statistics"
                                :key="category.category.id"
                                :class="category.balance > 0 ? 'table-danger' : 'table-success'"
                            >
                                {{ category.balance.huf() }}
                                <i :class="category.balance > 0 ? 'fa fa-times' : 'fa fa-check'"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <PegasusButton size="sm" variant="primary" @click="refreshStatistics">
                    <i class="fas fa-sync"></i> Frissítés
                </PegasusButton>
                <PegasusButton v-if="user.hasPermission(BaseGlobalPermission.KPR)" :params="{tender: tender}" size="sm" to="rvt.kpr.tender.statistics" variant="success">
                    <i class="fas fa-chart-simple"></i> Cimke statisztika
                </PegasusButton>
            </PegasusPanel>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <table class="table table-striped table-bordered table-hover text-nowrap">
                <thead>
                    <tr>
                        <th class="text-center">Leadott pályázatok</th>
                        <th v-for="category in tender.categories" :key="category.id" class="text-center" colspan="2">
                            {{ category.name }}
                        </th>
                    </tr>

                    <tr>
                        <th>Kör neve</th>
                        <template v-for="category in tender.categories" :key="category.id">
                            <th>Igényelt</th>
                            <th>Megszavazott</th>
                        </template>
                    </tr>
                </thead>

                <tbody>
                    <template v-for="resort in resorts" :key="resort.id">
                        <tr>
                            <td class="text-center table-success" colspan="9">{{ resort.name }}</td>
                        </tr>

                        <tr v-for="app in appGroupedByResort[resort.id]" :key="app.id">
                            <td>
                                <PegasusLink :params="{application: app}" to="rvt.kpr.application">
                                    {{ app.group.name }}
                                </PegasusLink>
                            </td>
                            <template v-for="category in tender.categories" :key="category.id">
                                <template v-if="app.getCappByShortName(category.shortName)">
                                    <td>
                                        {{ app.getCappByShortName(category.shortName)?.itemGroup.calculateSum().huf() }}
                                        <PegasusButton :params="{application: app, category: category.shortName}" class="float-end" size="xs" to="rvt.kpr.edit">
                                            <i class="fa fa-external-link"></i>
                                        </PegasusButton>
                                    </td>
                                    <td>
                                        {{ app.getCappByShortName(category.shortName)?.itemGroup.calculateSumDecided().huf() }}
                                    </td>
                                </template>

                                <td v-else class="text-center" colspan="2">Nincs pályázat</td>
                            </template>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<title>KPR pályázat</title>

<script lang="ts" setup>
import {Tender} from "@Models/rvt/kpr/Tender";
import {Application} from "@Models/rvt/kpr/Application";
import toast from "@Utils/toast";
import {filter, groupBy, map, size, sortBy} from "lodash-es";
import {computed, ref} from "vue";
import {useConstantsStore} from "@/stores/constants";
import {BaseGlobalPermission} from "@/api/models";
import PermissionMatrix from "@Components/kpr/PermissionMatrix.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusHelp from "@Components/base/PegasusHelp.vue";
import PegasusLink from "@Components/base/PegasusLink.vue";
import {useAuthStore} from "@/stores/auth";
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const {tenderId} = defineProps<{
    tenderId: number
}>();

const user = useAuthStore().requireUser();

const tender = ref(await Tender.getById(tenderId));

const applications = ref(await Application.getAllByTender(tenderId));

const constants = useConstantsStore().constants;

function refreshStatistics() {
    let request = Application.getAllByTender(tender.value.id).then(response => {
        applications.value = response;
    });

    toast.loading(request, 'Frissítés');
}

const resorts = computed(() => {
    return sortBy(
        filter(constants.groups.resorts, resort => resort.id in appGroupedByResort.value),
        resort => resort.name
    );
});

const appGroupedByResort = computed(() => {
    return groupBy(
        sortBy(applications.value, application => application.group.name),
        application => application.group.resortOrSelf
    );
});

const statistics = computed(() => {
    return map(tender.value.categories, category => category.calculateStatistics(applications.value));
});
</script>
