<template>
    <PegasusModal header="Költségvetés szerkesztése">
        <PegasusForm ref="form">
            A költségvetés neve és leírása fontos része a költségvetésnek.

            <hr>

            <TextField
                v-model="name"
                :schema="MoneyBudgetProgramUpdateSchema.name"
            ></TextField>

            <TextareaField
                v-model="description"
                :schema="MoneyBudgetProgramUpdateSchema.description"
            ></TextareaField>

            <PegasusButton
                variant="success"
                @click="save"
            >
                <i class="fa fa-save"></i> Mentés
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import PegasusModal from '@Components/base/PegasusModal.vue';
import {MoneyBudgetProgramUpdateSchema} from '@/api/models';
import {ref} from 'vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import toast from '@Utils/toast';
import type {BudgetProgram} from '@Models/money/BudgetProgram';
import {useReturn} from '@Utils/dialog';
import TextareaField from '@Components/base/form/types/TextareaField.vue';
import type {FormRef} from '@/vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import TextField from '@Components/base/form/types/TextField.vue';

const {program} = defineProps<{
    program: BudgetProgram
}>();

const name = ref(program.name);
const description = ref(program.description);

const returnValue = useReturn();

const form = ref<FormRef>(null);

async function save() {
    if (!await toast.confirm('Biztosan módosítod a költségvetés leírását?')) {
        return;
    }

    const request = program.update(name.value, description.value, form.value).then(() => {
        returnValue(true);
    });

    toast.loading(request);
}
</script>
