import {BaseEntity} from "@Models/BaseEntity";
import {DateTime} from "@Utils/DateTime";
import {MoneyApi, type MoneyOpenTenderResponse, type MoneyOpenTenderStore} from "@/api/api";
import type {FormRef} from '@/vue';
import type {BudgetItem} from '@Models/money/BudgetItem';

export class OpenTender extends BaseEntity {
    name: string;

    description: string;

    deadline: DateTime;

    budgetItemId: number;

    static createNew(item: BudgetItem): OpenTender {
        const tender = new OpenTender();

        tender.name = 'Új pályázat';
        tender.description = '';
        tender.deadline = DateTime.now().addMonth().endOfDay();
        tender.budgetItemId = item.id;

        return tender;
    }

    static getById(id: number): Promise<OpenTender> {
        return MoneyApi.openTendersShow(id).then(response => {
            return OpenTender.newSingle(response.data, OpenTender.parseResponse);
        });
    }

    static getAllByItem(id: number): Promise<Record<number, OpenTender>> {
        return MoneyApi.budgetItemsGetOpenTenders(id).then(response => {
            return OpenTender.newRecords(response.data.tenders, OpenTender.parseResponse);
        });
    }

    static parseResponse(tender: OpenTender, data: MoneyOpenTenderResponse): OpenTender {
        tender.id = data.id;
        tender.name = data.name;
        tender.description = data.description;
        tender.deadline = data.deadline;
        tender.budgetItemId = data.budgetItemId;

        return tender;
    }

    public store(form: FormRef): Promise<OpenTender> {
        const data: MoneyOpenTenderStore = {
            name: this.name,
            description: this.description,
            deadline: this.deadline,
            budgetItem: this.budgetItemId,
        };

        return MoneyApi.openTendersStore(data, {form}).then(response => {
            return OpenTender.parseResponse(this, response.data);
        });
    }
}
