import {SpendingItemEvent} from '@Models/money/SpendingItemEvent';
import {
    type MoneySpendingEventSpendingSettled,
    MoneySpendingEventSpendingSettledSchema,
    MoneySpendingEventType,
    type MoneySpendingItemEvent,
    type MoneySpendingItemEventResponse
} from '@/api/models';
import type {FormRef} from '@/vue';
import {MoneyApi} from '@/api/apis';

export class SpendingSettled extends SpendingItemEvent {
    normal: boolean;

    constructor() {
        super();

        this.normal = true;
    }

    static parseResponse(spendingSettled: SpendingSettled, data: MoneySpendingItemEventResponse): SpendingSettled {
        SpendingItemEvent.parseResponse(spendingSettled, data);

        const specific = data.data as MoneySpendingEventSpendingSettled;
        spendingSettled.normal = specific.normal;

        return spendingSettled;
    }

    public store(itemId: number, form: FormRef): Promise<SpendingSettled> {
        const data: MoneySpendingItemEvent = {
            type: MoneySpendingEventType.SPENDING_SETTLED,
            data: {
                type: MoneySpendingEventType.SPENDING_SETTLED,
                normal: this.normal,
            },
            comment: this.comment
        };

        return MoneyApi.spendingEventsSpendingSettled(itemId, data, {form}).then(response => {
            return SpendingSettled.parseResponse(this, response.data);
        });
    }

    get text(): {key: string, value: string}[] {
        return [
            {
                key: MoneySpendingEventSpendingSettledSchema.normal.label,
                value: this.normal ? 'Igen' : 'Nem'
            },
        ];
    }
}
