/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MoneyBudgetProgramMilestoneType } from './MoneyBudgetProgramMilestoneType';
    import {
    MoneyBudgetProgramMilestoneTypeFromJSON,
    MoneyBudgetProgramMilestoneTypeFromJSONTyped,
    MoneyBudgetProgramMilestoneTypeToJSON,
    MoneyBudgetProgramMilestoneTypeCreateEmpty,
    } from './MoneyBudgetProgramMilestoneType';
import {DateTime} from '@Utils/DateTime';
import { MoneyBudgetProgramMilestoneTypeValues } from './MoneyBudgetProgramMilestoneType';

export interface MoneyBudgetProgramMilestoneAdd {
  type: MoneyBudgetProgramMilestoneType;
  comment: string | null;
}

export const MoneyBudgetProgramMilestoneAddSchema = {
  'type': {
    name: 'type',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "",
        enumValues: MoneyBudgetProgramMilestoneTypeValues,
  },
  'comment': {
    name: 'comment',
    required: true,
    nullable: true,
    label: 'Megjegyzés',
    type: 'string',
      maxLength: 500,
  },
}
export function instanceOfMoneyBudgetProgramMilestoneAdd(value: object): value is MoneyBudgetProgramMilestoneAdd {
let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "comment" in value;

return isInstance;
}

export function MoneyBudgetProgramMilestoneAddFromJSON(json: any): MoneyBudgetProgramMilestoneAdd {
return MoneyBudgetProgramMilestoneAddFromJSONTyped(json, false);
}

export function MoneyBudgetProgramMilestoneAddFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyBudgetProgramMilestoneAdd {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'type': MoneyBudgetProgramMilestoneTypeFromJSON(json['type']),
          'comment': json['comment'],
    };
  }

  export function MoneyBudgetProgramMilestoneAddToJSON(value?: MoneyBudgetProgramMilestoneAdd | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'type': MoneyBudgetProgramMilestoneTypeToJSON(value.type),
                'comment': value.comment,
    };
  }

  export function MoneyBudgetProgramMilestoneAddCreateEmpty(): MoneyBudgetProgramMilestoneAdd {
  return {
          'type': MoneyBudgetProgramMilestoneTypeCreateEmpty(),
      'comment': '',
  };
  }

