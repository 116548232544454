/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { KprApplicationWithItemsResponse } from './KprApplicationWithItemsResponse';
    import {
    KprApplicationWithItemsResponseFromJSON,
    KprApplicationWithItemsResponseFromJSONTyped,
    KprApplicationWithItemsResponseToJSON,
    KprApplicationWithItemsResponseCreateEmpty,
    } from './KprApplicationWithItemsResponse';
    import type { KprTenderResponse } from './KprTenderResponse';
    import {
    KprTenderResponseFromJSON,
    KprTenderResponseFromJSONTyped,
    KprTenderResponseToJSON,
    KprTenderResponseCreateEmpty,
    } from './KprTenderResponse';
    import type { RvtAmendResponse } from './RvtAmendResponse';
    import {
    RvtAmendResponseFromJSON,
    RvtAmendResponseFromJSONTyped,
    RvtAmendResponseToJSON,
    RvtAmendResponseCreateEmpty,
    } from './RvtAmendResponse';
    import type { RvtClaimResponse } from './RvtClaimResponse';
    import {
    RvtClaimResponseFromJSON,
    RvtClaimResponseFromJSONTyped,
    RvtClaimResponseToJSON,
    RvtClaimResponseCreateEmpty,
    } from './RvtClaimResponse';
    import type { RvtSpendingPeriodResponse } from './RvtSpendingPeriodResponse';
    import {
    RvtSpendingPeriodResponseFromJSON,
    RvtSpendingPeriodResponseFromJSONTyped,
    RvtSpendingPeriodResponseToJSON,
    RvtSpendingPeriodResponseCreateEmpty,
    } from './RvtSpendingPeriodResponse';
import {DateTime} from '@Utils/DateTime';

export interface RvtGroupPeriodSummaryResponse {
  groupId: number;
  period: RvtSpendingPeriodResponse;
  claims: Array<RvtClaimResponse>;
  tender: KprTenderResponse | null;
  application: KprApplicationWithItemsResponse | null;
  amends: Array<RvtAmendResponse>;
}

export const RvtGroupPeriodSummaryResponseSchema = {
  'groupId': {
    name: 'groupId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'period': {
    name: 'period',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'claims': {
    name: 'claims',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'tender': {
    name: 'tender',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'application': {
    name: 'application',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'amends': {
    name: 'amends',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfRvtGroupPeriodSummaryResponse(value: object): value is RvtGroupPeriodSummaryResponse {
let isInstance = true;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "period" in value;
    isInstance = isInstance && "claims" in value;
    isInstance = isInstance && "tender" in value;
    isInstance = isInstance && "application" in value;
    isInstance = isInstance && "amends" in value;

return isInstance;
}

export function RvtGroupPeriodSummaryResponseFromJSON(json: any): RvtGroupPeriodSummaryResponse {
return RvtGroupPeriodSummaryResponseFromJSONTyped(json, false);
}

export function RvtGroupPeriodSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvtGroupPeriodSummaryResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'groupId': json['groupId'],
              'period': RvtSpendingPeriodResponseFromJSON(json['period']),
            'claims': ((json['claims'] as Array<any>).map(RvtClaimResponseFromJSON)),
              'tender': KprTenderResponseFromJSON(json['tender']),
              'application': KprApplicationWithItemsResponseFromJSON(json['application']),
            'amends': ((json['amends'] as Array<any>).map(RvtAmendResponseFromJSON)),
    };
  }

  export function RvtGroupPeriodSummaryResponseToJSON(value?: RvtGroupPeriodSummaryResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'groupId': value.groupId,
                'period': RvtSpendingPeriodResponseToJSON(value.period),
              'claims': ((value.claims as Array<any>).map(RvtClaimResponseToJSON)),
                'tender': KprTenderResponseToJSON(value.tender),
                'application': KprApplicationWithItemsResponseToJSON(value.application),
              'amends': ((value.amends as Array<any>).map(RvtAmendResponseToJSON)),
    };
  }

  export function RvtGroupPeriodSummaryResponseCreateEmpty(): RvtGroupPeriodSummaryResponse {
  return {
      'groupId': 0,
          'period': RvtSpendingPeriodResponseCreateEmpty(),
      'claims': [],
          'tender': KprTenderResponseCreateEmpty(),
          'application': KprApplicationWithItemsResponseCreateEmpty(),
      'amends': [],
  };
  }

