/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MapRoomResponse } from './MapRoomResponse';
    import {
    MapRoomResponseFromJSON,
    MapRoomResponseFromJSONTyped,
    MapRoomResponseToJSON,
    MapRoomResponseCreateEmpty,
    } from './MapRoomResponse';
import {DateTime} from '@Utils/DateTime';

export interface InventoryMinimalItemResponse {
  id: number;
  officialName: string;
  name: string | null;
  description: string | null;
  count: number;
  material: boolean;
  responsibleId: number;
  ownerId: number | null;
  room: MapRoomResponse | null;
  itemGroupId: number | null;
  createdAt: DateTime;
}

export const InventoryMinimalItemResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'officialName': {
    name: 'officialName',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'name': {
    name: 'name',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'count': {
    name: 'count',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'material': {
    name: 'material',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'responsibleId': {
    name: 'responsibleId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'ownerId': {
    name: 'ownerId',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'room': {
    name: 'room',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'itemGroupId': {
    name: 'itemGroupId',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'createdAt': {
    name: 'createdAt',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
}
export function instanceOfInventoryMinimalItemResponse(value: object): value is InventoryMinimalItemResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "officialName" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "material" in value;
    isInstance = isInstance && "responsibleId" in value;
    isInstance = isInstance && "ownerId" in value;
    isInstance = isInstance && "room" in value;
    isInstance = isInstance && "itemGroupId" in value;
    isInstance = isInstance && "createdAt" in value;

return isInstance;
}

export function InventoryMinimalItemResponseFromJSON(json: any): InventoryMinimalItemResponse {
return InventoryMinimalItemResponseFromJSONTyped(json, false);
}

export function InventoryMinimalItemResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryMinimalItemResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'officialName': json['officialName'],
          'name': json['name'],
          'description': json['description'],
          'count': json['count'],
          'material': json['material'],
          'responsibleId': json['responsibleId'],
          'ownerId': json['ownerId'],
              'room': MapRoomResponseFromJSON(json['room']),
          'itemGroupId': json['itemGroupId'],
        'createdAt': (DateTime.fromISO(json['createdAt'])),
    };
  }

  export function InventoryMinimalItemResponseToJSON(value?: InventoryMinimalItemResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'officialName': value.officialName,
                'name': value.name,
                'description': value.description,
                'count': value.count,
                'material': value.material,
                'responsibleId': value.responsibleId,
                'ownerId': value.ownerId,
                'room': MapRoomResponseToJSON(value.room),
                'itemGroupId': value.itemGroupId,
            'createdAt': (value.createdAt.toISO()),
    };
  }

  export function InventoryMinimalItemResponseCreateEmpty(): InventoryMinimalItemResponse {
  return {
      'id': 0,
      'officialName': '',
      'name': '',
      'description': '',
      'count': 0,
      'material': false,
      'responsibleId': 0,
      'ownerId': 0,
          'room': MapRoomResponseCreateEmpty(),
      'itemGroupId': 0,
      'createdAt': DateTime.now(),
  };
  }

