/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { InventoryDocumentType } from './InventoryDocumentType';
    import {
    InventoryDocumentTypeFromJSON,
    InventoryDocumentTypeFromJSONTyped,
    InventoryDocumentTypeToJSON,
    InventoryDocumentTypeCreateEmpty,
    } from './InventoryDocumentType';
import {DateTime} from '@Utils/DateTime';
import { InventoryDocumentTypeValues } from './InventoryDocumentType';

export interface InventoryDocumentStoreRequest {
  item: number;
  name: string;
  documentType: InventoryDocumentType;
  file: Blob;
  comment: string;
}

export const InventoryDocumentStoreRequestSchema = {
  'item': {
    name: 'item',
    required: true,
    nullable: false,
    label: 'A tétel amihez a dokumentum tartozik',
    type: 'number',
      minimum: 0,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Dokumentum elnevezése',
    type: 'string',
      minLength: 3,
      maxLength: 100,
  },
  'documentType': {
    name: 'documentType',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Dokumentum típusa",
        enumValues: InventoryDocumentTypeValues,
  },
  'file': {
    name: 'file',
    required: true,
    nullable: false,
    label: 'Dokumentum fájl',
      labelHelp: `Támogatott formátumok: jpg, jpeg, png, pdf`,
    type: 'file',
        extensions: ["jpg","jpeg","png","pdf"]
  },
  'comment': {
    name: 'comment',
    required: true,
    nullable: false,
    label: 'Megjegyzés',
    type: 'string',
      maxLength: 1000,
  },
}
export function instanceOfInventoryDocumentStoreRequest(value: object): value is InventoryDocumentStoreRequest {
let isInstance = true;
    isInstance = isInstance && "item" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "documentType" in value;
    isInstance = isInstance && "file" in value;
    isInstance = isInstance && "comment" in value;

return isInstance;
}

export function InventoryDocumentStoreRequestFromJSON(json: any): InventoryDocumentStoreRequest {
return InventoryDocumentStoreRequestFromJSONTyped(json, false);
}

export function InventoryDocumentStoreRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryDocumentStoreRequest {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'item': json['item'],
          'name': json['name'],
              'documentType': InventoryDocumentTypeFromJSON(json['documentType']),
          'file': json['file'],
          'comment': json['comment'],
    };
  }

  export function InventoryDocumentStoreRequestToJSON(value?: InventoryDocumentStoreRequest | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'item': value.item,
                'name': value.name,
                'documentType': InventoryDocumentTypeToJSON(value.documentType),
                'file': value.file,
                'comment': value.comment,
    };
  }

  export function InventoryDocumentStoreRequestCreateEmpty(): InventoryDocumentStoreRequest {
  return {
      'item': 0,
      'name': '',
          'documentType': InventoryDocumentTypeCreateEmpty(),
      'file': new Blob(),
      'comment': '',
  };
  }

