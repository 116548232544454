import {BaseEntity} from "@Models/BaseEntity";
import {DateTime} from "@Utils/DateTime";
import {MoneyApi, type MoneySpendingPeriodStore, type RvtSpendingPeriodResponse} from "@/api/api";
import {useConstantsStore} from '@/stores/constants';
import {Tender} from '@Models/rvt/kpr/Tender';
import {find} from 'lodash-es';
import type {FormRef} from '@/vue';
import {Budget} from '@Models/money/Budget';

export class SpendingPeriod extends BaseEntity {
    from: DateTime;

    to: DateTime;

    tender: Tender | null;

    static getByYear(year: number): SpendingPeriod | null {
        return find(useConstantsStore().constants.money.spendingPeriods, period => period.year === year) ?? null;
    }

    static getBySingleId(id: number): SpendingPeriod {
        return useConstantsStore().constants.money.spendingPeriods[id];
    }

    static getAll(): Record<number, SpendingPeriod> {
        return useConstantsStore().constants.money.spendingPeriods;
    }

    static parseResponse(period: SpendingPeriod, data: RvtSpendingPeriodResponse): SpendingPeriod {
        period.id = data.id;
        period.from = data.from;
        period.to = data.to;
        period.tender = Tender.newSingleNullable(data.tender, Tender.parseResponse);

        return period;
    }

    get name(): string {
        return 'Gazdasági év ' + this.from.year;
    }

    get year(): number {
        return this.from.year;
    }

    get dates(): string {
        return this.from.datetime() + ' - ' + this.to.datetime();
    }

    public isClaimable(): boolean {
        return this.from.isPast() && this.to.isFuture();
    }

    public store(form: FormRef): Promise<SpendingPeriod> {
        const data: MoneySpendingPeriodStore = {
            from: this.from,
            to: this.to,
        };

        return MoneyApi.spendingPeriodsStore(data, {form}).then(response => {
            return SpendingPeriod.parseResponse(this, response.data);
        });
    }

    public getBudget(): Promise<Budget> {
        return Budget.getBySpendingPeriod(this);
    }
}
