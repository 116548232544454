/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface KprFlagStatisticsResponse {
  id: number;
  itemCount: number;
  priceSum: number;
  approvedPriceSum: number;
  finalPriceSum: number;
}

export const KprFlagStatisticsResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'itemCount': {
    name: 'itemCount',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'priceSum': {
    name: 'priceSum',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'approvedPriceSum': {
    name: 'approvedPriceSum',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'finalPriceSum': {
    name: 'finalPriceSum',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
}
export function instanceOfKprFlagStatisticsResponse(value: object): value is KprFlagStatisticsResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "itemCount" in value;
    isInstance = isInstance && "priceSum" in value;
    isInstance = isInstance && "approvedPriceSum" in value;
    isInstance = isInstance && "finalPriceSum" in value;

return isInstance;
}

export function KprFlagStatisticsResponseFromJSON(json: any): KprFlagStatisticsResponse {
return KprFlagStatisticsResponseFromJSONTyped(json, false);
}

export function KprFlagStatisticsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KprFlagStatisticsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'itemCount': json['itemCount'],
          'priceSum': json['priceSum'],
          'approvedPriceSum': json['approvedPriceSum'],
          'finalPriceSum': json['finalPriceSum'],
    };
  }

  export function KprFlagStatisticsResponseToJSON(value?: KprFlagStatisticsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'itemCount': value.itemCount,
                'priceSum': value.priceSum,
                'approvedPriceSum': value.approvedPriceSum,
                'finalPriceSum': value.finalPriceSum,
    };
  }

  export function KprFlagStatisticsResponseCreateEmpty(): KprFlagStatisticsResponse {
  return {
      'id': 0,
      'itemCount': 0,
      'priceSum': 0,
      'approvedPriceSum': 0,
      'finalPriceSum': 0,
  };
  }

