<template>
    <PegasusHeading>{{ tender.displayName }} módosításai</PegasusHeading>

    <PegasusPanel class="mb-3" header="Adatok">
        <div class="row">
            <div class="col-lg-4">
                <dt>Kiírás</dt>
                <dd>
                    <a :href="'/api/kpr/tenders/' + tender.id + '/rules'" target="_blank">Megnyitás</a>
                </dd>
                <dt>Módosítok száma</dt>
                <dd>{{ size(amends) }}</dd>
                <dt>Elfogadott módosítók összege</dt>
                <dd>{{ sum().huf() }}</dd>
            </div>

            <div class="col-lg-4">
                <PegasusButton :params="{tender}" class="m-2" to="rvt.kpr.tender.show" variant="success">
                    <i class="fas fa-undo"></i> Vissza a KPR oldalára
                </PegasusButton>

                <PegasusButton :params="{period: tender.spendingPeriod}" class="m-2" to="rvt.spendingPeriod.show" variant="success">
                    <i class="fas fa-undo"></i> Vissza a költési időszak oldalára
                </PegasusButton>
            </div>
        </div>
    </PegasusPanel>

    <PegasusPanel header="Módosítók">
        <PegasusTable :data="amends" :per-page="10" :row-variant="rowClass" search>
            <Column field="idAsText" header="ID"></Column>
            <Column :field="item => item.createdAt.datetime()" header="Dátum"></Column>
            <Column field="group.name" header="Kör"></Column>
            <Column :field="item => item.objective.limit(50)" header="Cél"></Column>
            <Column :field="item => item.balance.huf()" header="Összeg"></Column>

            <Column header="Műveletek">
                <template #body="{data}">
                    <PegasusButton :params="{amend: data}" to="rvt.amends.show" variant="success">
                        <i class="fas fa-eye"></i> Megnyitás
                    </PegasusButton>
                </template>
            </Column>
        </PegasusTable>
    </PegasusPanel>
</template>

<title>KPR módosító kérvények</title>

<script lang="ts" setup>
import {Amend} from "@Models/rvt/requests/Amend";
import {Tender} from "@Models/rvt/kpr/Tender";
import {sumObject} from "@Models/Utils";
import {size} from "lodash-es";
import PegasusButton from "@Components/base/PegasusButton.vue";
import Column from "primevue/column";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import {useAuthStore} from "@/stores/auth";
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {ref} from 'vue';

const {tenderId} = defineProps<{
    tenderId: number
}>();

useAuthStore().requireUser();

const tender = ref(await Tender.getById(tenderId));

const amends = ref(await Amend.getAllInTender(tenderId));

function rowClass(item: Amend) {
    if (item.decision === null) {
        return null;
    }

    return item.decision ? 'success' : 'danger';
}

function sum() {
    return sumObject(amends.value, amend => {
        return amend.decision ? amend.balance : 0;
    });
}
</script>
