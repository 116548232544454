import {BaseEntity} from '@Models/BaseEntity';
import type {
    MoneyBudgetCategoryResponse,
    MoneyBudgetCategoryStore,
    MoneyBudgetCategoryUpdate,
    MoneyExtendedBudgetCategoryResponse
} from '@/api/models';
import {BudgetItem} from '@Models/money/BudgetItem';
import {find, isEmpty, size, sortBy, sumBy, toArray} from 'lodash-es';
import type {BudgetLocation} from '@Models/money/BudgetLocation';
import type {FormRef} from '@/vue';
import {MoneyApi} from '@/api/apis';
import type {SpendingPeriod} from '@Models/money/SpendingPeriod';

export class BudgetCategory extends BaseEntity {
    name: string;

    description: string;

    prefix: string | null = null;

    number: number;

    children: Record<number, BudgetCategory>;

    items: Record<number, BudgetItem>;

    static parseResponse(category: BudgetCategory, data: MoneyBudgetCategoryResponse): BudgetCategory {
        category.id = data.id;
        category.name = data.name;
        category.description = data.description;
        category.prefix = data.prefix;
        category.number = data.number;

        return category;
    }

    static parseExtendedResponse(category: BudgetCategory, data: MoneyExtendedBudgetCategoryResponse): BudgetCategory {
        BudgetCategory.parseResponse(category, data);

        category.children = BudgetCategory.newRecords(data.children, BudgetCategory.parseExtendedResponse);
        category.items = BudgetItem.newRecords(data.items, BudgetItem.parseResponse);

        return category;
    }

    get contentsText(): string {
        if (!isEmpty(this.items)) {
            return '' + size(this.items) + ' tétel';
        }

        if (!isEmpty(this.children)) {
            return '' + size(this.children) + ' alcsoport';
        }

        return 'Üres';
    }

    public childrenInOrder(): BudgetCategory[] {
        return sortBy(this.children, child => child.number);
    }

    public itemsInOrder(): BudgetItem[] {
        return sortBy(this.items, item => item.number);
    }

    public sum(): number {
        return sumBy(toArray(this.items), item => item.sum);
    }

    public valueSumByLocation(location: BudgetLocation): number {
        return sumBy(toArray(this.items), item => item.valueByLocation(location)?.value ?? 0);
    }

    public childByNumber(number: number): BudgetCategory | undefined {
        return find(this.children, child => child.number === number);
    }

    public store(period: SpendingPeriod, parentId: number): Promise<BudgetCategory> {
        const data: MoneyBudgetCategoryStore = {
            name: this.name,
            description: this.description,
            number: this.number,
            parent: parentId,
            spendingPeriod: period.id
        };

        return MoneyApi.budgetCategoriesStore(data).then(response => {
            return BudgetCategory.parseExtendedResponse(this, response.data);
        });
    }

    public update(name: string, description: string, prefix: string | null, number: number, form: FormRef): Promise<void> {
        const data: MoneyBudgetCategoryUpdate = {
            name,
            description,
            prefix,
            number
        };

        return MoneyApi.budgetCategoriesUpdate(this.id, data, {form}).then(response => {
            BudgetCategory.parseExtendedResponse(this, response.data);
        });
    }

    public delete(): Promise<void> {
        return MoneyApi.budgetCategoriesDelete(this.id).then();
    }
}
