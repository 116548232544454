<template>
    <form @submit.prevent="ok">
        <div class="row">

            <div class="col-lg-8">
                <TextField v-model="id" :schema="InventoryCreateExistingItemSchema.id" without-group></TextField>
            </div>

            <div class="col-lg-4">
                <PegasusButton variant="success" @click="ok">
                    Lekérdezés
                </PegasusButton>
            </div>
        </div>
    </form>
</template>

<script lang="ts" setup>
import {ref} from "vue";
import TextField from "@Components/base/form/types/TextField.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import {InventoryCreateExistingItemSchema} from '@/api/models';

const id = ref('');

const emit = defineEmits<{
    found: [id: number]
}>();

function parseCode(code: string) {
    if (code.startsWith('SCH')) {
        return parseInt(code.substring(3));
    }

    if (!isNaN(parseInt(code))) {
        return parseInt(code);
    }

    throw new Error('Invalid code: ' + code);
}

function ok() {
    emit('found', parseCode(id.value));
}

function reset() {
    id.value = '';
}

defineExpose({
    reset,
});
</script>
