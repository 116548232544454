<template>
    <PegasusHeading>Nyílvános pályázat - {{ tender.name }}</PegasusHeading>
</template>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {OpenTender} from '@Models/money/OpenTender';

const {tenderId} = defineProps<{
    tenderId: number
}>();

const tender = await OpenTender.getById(tenderId);
</script>
