<template>
    <PegasusHeading>{{ tender.displayName }} Statisztikák</PegasusHeading>

    <PegasusPanel header="Statisztikák">
        <table class="table text-nowrap">
            <thead>
                <tr>
                    <th></th>
                    <th>Tételek száma</th>
                    <th>Leadott összeg</th>
                    <th>Elvi bírált összeg</th>
                    <th>Végleges összeg</th>
                </tr>
            </thead>

            <tbody>
                <tr :key="flag.id" v-for="flag in statistics">
                    <th>
                        <PegasusLink to="rvt.kpr.tender.itemsByFlag" size="sm" :params="{tender: tenderId, flag: flag.id}">
                            {{ constants.constants.money.flags[flag.id].name }}
                        </PegasusLink>
                    </th>
                    <td>{{ flag.itemCount }}</td>
                    <td>{{ flag.priceSum.huf() }}</td>
                    <td>{{ flag.approvedPriceSum.huf()}}</td>
                    <td>{{ flag.finalPriceSum.huf() }}</td>
                </tr>
            </tbody>
        </table>
    </PegasusPanel>
</template>

<title>KPR pályázat</title>

<script lang="ts" setup>
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {KprApi} from "@/api/apis";
import {Tender} from "@Models/rvt/kpr/Tender";
import promiseAll from "@Utils/promise";
import {useConstantsStore} from "@/stores/constants";
import PegasusLink from "@Components/base/PegasusLink.vue";

const {tenderId} = defineProps<{
    tenderId: number
}>();

const constants = useConstantsStore();

const {tender, statisticsRaw: {data: {flags: statistics}}} = await promiseAll({
    tender: Tender.getById(tenderId),
    statisticsRaw: KprApi.statisticsFlags(tenderId),
});
</script>
