/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MoneyOpenTenderResponse } from './MoneyOpenTenderResponse';
    import {
    MoneyOpenTenderResponseFromJSON,
    MoneyOpenTenderResponseFromJSONTyped,
    MoneyOpenTenderResponseToJSON,
    MoneyOpenTenderResponseCreateEmpty,
    } from './MoneyOpenTenderResponse';
import {DateTime} from '@Utils/DateTime';

export interface MoneyOpenTenderListResponse {
  tenders: Array<MoneyOpenTenderResponse>;
}

export const MoneyOpenTenderListResponseSchema = {
  'tenders': {
    name: 'tenders',
    required: true,
    nullable: false,
    label: 'Nyílt pályázatok',
      type: 'array',
  },
}
export function instanceOfMoneyOpenTenderListResponse(value: object): value is MoneyOpenTenderListResponse {
let isInstance = true;
    isInstance = isInstance && "tenders" in value;

return isInstance;
}

export function MoneyOpenTenderListResponseFromJSON(json: any): MoneyOpenTenderListResponse {
return MoneyOpenTenderListResponseFromJSONTyped(json, false);
}

export function MoneyOpenTenderListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyOpenTenderListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'tenders': ((json['tenders'] as Array<any>).map(MoneyOpenTenderResponseFromJSON)),
    };
  }

  export function MoneyOpenTenderListResponseToJSON(value?: MoneyOpenTenderListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'tenders': ((value.tenders as Array<any>).map(MoneyOpenTenderResponseToJSON)),
    };
  }

  export function MoneyOpenTenderListResponseCreateEmpty(): MoneyOpenTenderListResponse {
  return {
      'tenders': [],
  };
  }

