/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { InventoryDocumentResponse } from './InventoryDocumentResponse';
    import {
    InventoryDocumentResponseFromJSON,
    InventoryDocumentResponseFromJSONTyped,
    InventoryDocumentResponseToJSON,
    InventoryDocumentResponseCreateEmpty,
    } from './InventoryDocumentResponse';
    import type { InventoryUpdateResponse } from './InventoryUpdateResponse';
    import {
    InventoryUpdateResponseFromJSON,
    InventoryUpdateResponseFromJSONTyped,
    InventoryUpdateResponseToJSON,
    InventoryUpdateResponseCreateEmpty,
    } from './InventoryUpdateResponse';
    import type { MapRoomResponse } from './MapRoomResponse';
    import {
    MapRoomResponseFromJSON,
    MapRoomResponseFromJSONTyped,
    MapRoomResponseToJSON,
    MapRoomResponseCreateEmpty,
    } from './MapRoomResponse';
import {DateTime} from '@Utils/DateTime';

export interface InventoryItemResponse {
  uniqueIdentifier: string | null;
  price: number | null;
  kprItemId: number | null;
  sgrId: string | null;
  tags: Array<number>;
  updates: Array<InventoryUpdateResponse>;
  documents: Array<InventoryDocumentResponse>;
  id: number;
  officialName: string;
  name: string | null;
  description: string | null;
  count: number;
  material: boolean;
  responsibleId: number;
  ownerId: number | null;
  room: MapRoomResponse | null;
  itemGroupId: number | null;
  createdAt: DateTime;
}

export const InventoryItemResponseSchema = {
  'uniqueIdentifier': {
    name: 'uniqueIdentifier',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'price': {
    name: 'price',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'kprItemId': {
    name: 'kprItemId',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'sgrId': {
    name: 'sgrId',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
      minLength: 3,
      maxLength: 50,
  },
  'tags': {
    name: 'tags',
    required: true,
    nullable: false,
    label: '',
    type: 'array',
  },
  'updates': {
    name: 'updates',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'documents': {
    name: 'documents',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'officialName': {
    name: 'officialName',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'name': {
    name: 'name',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'count': {
    name: 'count',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'material': {
    name: 'material',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
  'responsibleId': {
    name: 'responsibleId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'ownerId': {
    name: 'ownerId',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'room': {
    name: 'room',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'itemGroupId': {
    name: 'itemGroupId',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'createdAt': {
    name: 'createdAt',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
}
export function instanceOfInventoryItemResponse(value: object): value is InventoryItemResponse {
let isInstance = true;
    isInstance = isInstance && "uniqueIdentifier" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "kprItemId" in value;
    isInstance = isInstance && "sgrId" in value;
    isInstance = isInstance && "tags" in value;
    isInstance = isInstance && "updates" in value;
    isInstance = isInstance && "documents" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "officialName" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "material" in value;
    isInstance = isInstance && "responsibleId" in value;
    isInstance = isInstance && "ownerId" in value;
    isInstance = isInstance && "room" in value;
    isInstance = isInstance && "itemGroupId" in value;
    isInstance = isInstance && "createdAt" in value;

return isInstance;
}

export function InventoryItemResponseFromJSON(json: any): InventoryItemResponse {
return InventoryItemResponseFromJSONTyped(json, false);
}

export function InventoryItemResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryItemResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'uniqueIdentifier': json['uniqueIdentifier'],
          'price': json['price'],
          'kprItemId': json['kprItemId'],
          'sgrId': json['sgrId'],
          'tags': json['tags'],
            'updates': ((json['updates'] as Array<any>).map(InventoryUpdateResponseFromJSON)),
            'documents': ((json['documents'] as Array<any>).map(InventoryDocumentResponseFromJSON)),
          'id': json['id'],
          'officialName': json['officialName'],
          'name': json['name'],
          'description': json['description'],
          'count': json['count'],
          'material': json['material'],
          'responsibleId': json['responsibleId'],
          'ownerId': json['ownerId'],
              'room': MapRoomResponseFromJSON(json['room']),
          'itemGroupId': json['itemGroupId'],
        'createdAt': (DateTime.fromISO(json['createdAt'])),
    };
  }

  export function InventoryItemResponseToJSON(value?: InventoryItemResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'uniqueIdentifier': value.uniqueIdentifier,
                'price': value.price,
                'kprItemId': value.kprItemId,
                'sgrId': value.sgrId,
            'tags': value.tags,
              'updates': ((value.updates as Array<any>).map(InventoryUpdateResponseToJSON)),
              'documents': ((value.documents as Array<any>).map(InventoryDocumentResponseToJSON)),
                'id': value.id,
                'officialName': value.officialName,
                'name': value.name,
                'description': value.description,
                'count': value.count,
                'material': value.material,
                'responsibleId': value.responsibleId,
                'ownerId': value.ownerId,
                'room': MapRoomResponseToJSON(value.room),
                'itemGroupId': value.itemGroupId,
            'createdAt': (value.createdAt.toISO()),
    };
  }

  export function InventoryItemResponseCreateEmpty(): InventoryItemResponse {
  return {
      'uniqueIdentifier': '',
      'price': 0,
      'kprItemId': 0,
      'sgrId': '',
      'tags': [],
      'updates': [],
      'documents': [],
      'id': 0,
      'officialName': '',
      'name': '',
      'description': '',
      'count': 0,
      'material': false,
      'responsibleId': 0,
      'ownerId': 0,
          'room': MapRoomResponseCreateEmpty(),
      'itemGroupId': 0,
      'createdAt': DateTime.now(),
  };
  }

