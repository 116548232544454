import {BaseEntity} from "@Models/BaseEntity";
import {Group} from "@Models/base/Group";
import {CategoryApplication} from "@Models/rvt/kpr/CategoryApplication";
import {Category} from "@Models/rvt/kpr/Category";
import {find} from "lodash-es";
import type {KprApplicationStore, KprApplicationWithItemsResponse} from "@/api/api";
import {KprApi} from "@/api/api";
import {Amend} from "@Models/rvt/requests/Amend";
import type {FormRef} from '@/vue';

export type KprPermissions = {
    canEdit: boolean,
    canDecide: boolean,
    canFinalize: boolean,
    canConfidential: boolean,
};

export class Application extends BaseEntity {
    group: Group;

    statement: string;

    number: number;

    categories: Record<number, CategoryApplication>;

    amends: Record<number, Amend>;

    canEditStatement: boolean;

    tenderId: number;

    static getById(id: number): Promise<Application> {
        return KprApi.applicationsShow(id).then(response => {
            return Application.newSingle(response.data, Application.parseWithItems);
        });
    }

    static getAllByTender(tenderId: number): Promise<Record<number, Application>> {
        return KprApi.tenderApplications(tenderId).then(response => {
            return Application.newRecords(response.data.applications, Application.parseWithItems);
        });
    }

    static create(group: Group): Application {
        const application = new Application();

        application.group = group;

        return application;
    }

    static parseWithItems(application: Application, data: KprApplicationWithItemsResponse): Application {
        application.id = data.id;
        application.group = Group.getBySingleId(data.groupId);
        application.statement = data.statement;
        application.number = data.number;
        application.categories = CategoryApplication.newRecords(data.categories, CategoryApplication.parseResponse);
        application.amends = Amend.newRecords(data.amends, Amend.parseResponse);
        application.canEditStatement = data.canEditStatement;
        application.tenderId = data.tenderId;

        return application;
    }

    public store(tenderId: number): Promise<Application> {
        const data: KprApplicationStore = {
            tender: tenderId,
            group: this.group.id,
        };

        return KprApi.applicationsStore(data).then(response => {
            return Application.parseWithItems(this, response.data);
        });
    }

    public update(form: FormRef, number: number, statement: string): Promise<void> {
        const data = {
            number: number,
            statement: statement,
        };

        return KprApi.applicationsUpdate(this.id, data, {form}).then(response => {
            Application.parseWithItems(this, response.data);
        });
    }

    public getCappByCategory(category: Category): CategoryApplication | null {
        return find(this.categories, capp => capp.category.id === category.id) ?? null;
    }

    public getCappByShortName(shortName: string): CategoryApplication | null {
        return find(this.categories, capp => capp.category.shortName === shortName) ?? null;
    }
}
