/* tslint:disable */
/* eslint-disable */

export enum MoneyBudgetProgramMilestoneType {
    CREATE = 'CREATE',
    SUBMIT = 'SUBMIT',
    APPROVE = 'APPROVE',
    REJECT = 'REJECT',
    FINISH = 'FINISH',
    ARCHIVE = 'ARCHIVE'
}

export const MoneyBudgetProgramMilestoneTypeValues: Record<MoneyBudgetProgramMilestoneType, string> = {
    'CREATE': 'Létrehozva',
    'SUBMIT': 'Véglegesítve',
    'APPROVE': 'Elfogadva',
    'REJECT': 'Visszadobva',
    'FINISH': 'Költés vége',
    'ARCHIVE': 'Archíválva'
}


export function MoneyBudgetProgramMilestoneTypeFromJSON(json: any): MoneyBudgetProgramMilestoneType {
    return MoneyBudgetProgramMilestoneTypeFromJSONTyped(json, false);
}

export function MoneyBudgetProgramMilestoneTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyBudgetProgramMilestoneType {
    return json as MoneyBudgetProgramMilestoneType;
}

export function MoneyBudgetProgramMilestoneTypeToJSON(value?: MoneyBudgetProgramMilestoneType | null): any {
    return value as any;
}

export function MoneyBudgetProgramMilestoneTypeCreateEmpty(): MoneyBudgetProgramMilestoneType {
    return MoneyBudgetProgramMilestoneType.CREATE;
}

