/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneyOpenTenderStore {
  budgetItem: number;
  name: string;
  description: string;
  deadline: DateTime;
}

export const MoneyOpenTenderStoreSchema = {
  'budgetItem': {
    name: 'budgetItem',
    required: true,
    nullable: false,
    label: 'Házszintű tétel',
    type: 'number',
      minimum: 0,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Pályázat neve',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: 'Leírás',
    type: 'string',
  },
  'deadline': {
    name: 'deadline',
    required: true,
    nullable: false,
    label: 'Leadási határidő',
    type: 'datetime',
  },
}
export function instanceOfMoneyOpenTenderStore(value: object): value is MoneyOpenTenderStore {
let isInstance = true;
    isInstance = isInstance && "budgetItem" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "deadline" in value;

return isInstance;
}

export function MoneyOpenTenderStoreFromJSON(json: any): MoneyOpenTenderStore {
return MoneyOpenTenderStoreFromJSONTyped(json, false);
}

export function MoneyOpenTenderStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyOpenTenderStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'budgetItem': json['budgetItem'],
          'name': json['name'],
          'description': json['description'],
        'deadline': (DateTime.fromISO(json['deadline'])),
    };
  }

  export function MoneyOpenTenderStoreToJSON(value?: MoneyOpenTenderStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'budgetItem': value.budgetItem,
                'name': value.name,
                'description': value.description,
            'deadline': (value.deadline.toISO()),
    };
  }

  export function MoneyOpenTenderStoreCreateEmpty(): MoneyOpenTenderStore {
  return {
      'budgetItem': 0,
      'name': '',
      'description': '',
      'deadline': DateTime.now(),
  };
  }

