<template>
    <SmartField :inline="inline" :schema="schema" :without-group="withoutGroup">
        <template #default="{id, isValid}">
            <div>
                <CascadeSelect
                    v-model="modelValue"
                    :class="{'p-invalid': isValid === false}"
                    :disabled="props.disabled"
                    :input-id="id"
                    :loading="props.budget === null"
                    :option-group-children="['children', 'children']"
                    :options="options"
                    :pt="{input: {'data-input-name': schema.name}}"
                    class="w-100"
                    option-group-label="name"
                    option-label="name"
                    option-value="value"
                ></CascadeSelect>
            </div>
        </template>

        <template #label="slotProps">
            <slot name="label" v-bind="slotProps"></slot>
        </template>
    </SmartField>
</template>

<script lang="ts" setup>
import SmartField from "@Components/base/form/SmartField.vue";
import {type FieldSchema} from "@Components/base/form/FieldProperties";
import CascadeSelect from "primevue/cascadeselect";
import {map} from 'lodash-es';
import {computed} from 'vue';
import {Budget} from '@Models/money/Budget';

const modelValue = defineModel<number | null>({required: true});

const props = defineProps<{
    disabled?: boolean;
    withoutGroup?: boolean;
    schema: FieldSchema;
    inline?: boolean;
    budget: Budget | null;
}>();

const options = computed(() => {
    if (props.budget === null) {
        return [];
    }

    return map(props.budget.categories, category => {
        return {
            name: category.name,
            children: category.childrenInOrder().map(child => {
                const prefix = (category.prefix ?? '') + child.number;
                return {
                    name: prefix + ' ' + child.name,
                    children: child.itemsInOrder().map(item => {
                        return {
                            name: prefix + '.' + item.number + ' ' + item.name,
                            value: item.id,
                        };
                    })
                };
            })
        };
    });
});
</script>
