import {SpendingItemEvent} from '@Models/money/SpendingItemEvent';
import {type MoneySpendingEventItemCreated, type MoneySpendingItemEventResponse} from '@/api/models';

export class ItemCreated extends SpendingItemEvent {
    urgent: boolean;

    static parseResponse(itemCreated: ItemCreated, data: MoneySpendingItemEventResponse): ItemCreated {
        SpendingItemEvent.parseResponse(itemCreated, data);

        const specific = data.data as MoneySpendingEventItemCreated;
        itemCreated.urgent = specific.urgent;

        return itemCreated;
    }

    get text(): {key: string, value: string}[] {
        return [];
    }
}
