/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneySpendingEventSpendingSettled {
  normal: boolean;
}

export const MoneySpendingEventSpendingSettledSchema = {
  'normal': {
    name: 'normal',
    required: true,
    nullable: false,
    label: 'Minden rendben történt',
    type: 'boolean',
  },
}
export function instanceOfMoneySpendingEventSpendingSettled(value: object): value is MoneySpendingEventSpendingSettled {
let isInstance = true;
    isInstance = isInstance && "normal" in value;

return isInstance;
}

export function MoneySpendingEventSpendingSettledFromJSON(json: any): MoneySpendingEventSpendingSettled {
return MoneySpendingEventSpendingSettledFromJSONTyped(json, false);
}

export function MoneySpendingEventSpendingSettledFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneySpendingEventSpendingSettled {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'normal': json['normal'],
    };
  }

  export function MoneySpendingEventSpendingSettledToJSON(value?: MoneySpendingEventSpendingSettled | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'normal': value.normal,
    };
  }

  export function MoneySpendingEventSpendingSettledCreateEmpty(): MoneySpendingEventSpendingSettled {
  return {
      'normal': false,
  };
  }

