import {BaseEntity} from "@Models/BaseEntity";
import {type DecidedItem, Item} from "@Models/money/Item";
import {sumObject} from "@Models/Utils";
import {concat, filter, map, size, toArray} from "lodash-es";
import {
    MoneyApi,
    type MoneyItemGroupStore,
    type MoneyItemGroupUpdate,
    type MoneyItemGroupWithItemsResponse
} from "@/api/api";
import type {FormRef} from '@/vue';

export class ItemGroup extends BaseEntity {
    name: string;

    description: string;

    items: Record<number, Item>;

    alternativeChildren: Record<number, ItemGroup>;

    bonusChildren: Record<number, ItemGroup>;

    static getById(id: number): Promise<ItemGroup> {
        return MoneyApi.itemGroupsShow(id).then(response => {
            return ItemGroup.newSingle(response.data, ItemGroup.parseWithItems);
        });
    }

    static createNew(name: string, description: string = ""): ItemGroup {
        const group = new ItemGroup();

        group.id = 0;
        group.name = name;
        group.description = description;

        return group;
    }

    static parseWithItems(itemGroup: ItemGroup, data: MoneyItemGroupWithItemsResponse): ItemGroup {
        itemGroup.id = data.id;
        itemGroup.name = data.name;
        itemGroup.description = data.description;
        itemGroup.items = Item.newRecords(data.items, Item.parseResponse);
        itemGroup.alternativeChildren = ItemGroup.newRecords(data.alternativeChildren, ItemGroup.parseWithItems);
        itemGroup.bonusChildren = ItemGroup.newRecords(data.bonusChildren, ItemGroup.parseWithItems);

        return itemGroup;
    }

    get itemCount(): number {
        return size(this.items);
    }

    get allChildren(): ItemGroup[] {
        return toArray(this.alternativeChildren).concat(toArray(this.bonusChildren));
    }

    public allItems(): Item[] {
        return concat(
            toArray(this.items),
            map(this.alternativeChildren, children => children.allItems()).flat(),
            map(this.bonusChildren, children => children.allItems()).flat(),
        );
    }

    public calculateAllItemCount(): number {
        return (
            this.itemCount
            + sumObject(this.alternativeChildren, itemGroup => itemGroup.calculateAllItemCount())
            + sumObject(this.bonusChildren, itemGroup => itemGroup.calculateAllItemCount())
        );
    }

    public calculateSum(): number {
        return (
            sumObject(this.items, item => item.price)
            + sumObject(this.alternativeChildren, itemGroup => itemGroup.calculateSum())
            + sumObject(this.bonusChildren, itemGroup => itemGroup.calculateSum())
        );
    }

    public calculateSumApproved(): number {
        return (
            sumObject(this.items, item => item.approvedPrice ?? 0)
            + sumObject(this.alternativeChildren, itemGroup => itemGroup.calculateSumApproved())
            + sumObject(this.bonusChildren, itemGroup => itemGroup.calculateSumApproved())
        );
    }

    public calculateSumDecided(): number {
        return (
            sumObject(this.items, item => item.decidedPrice ?? 0)
            + sumObject(this.alternativeChildren, itemGroup => itemGroup.calculateSumDecided())
            + sumObject(this.bonusChildren, itemGroup => itemGroup.calculateSumDecided())
        );
    }

    public getNonZeroItems(): DecidedItem[] {
        return filter(this.items, item => {
            return item.decidedPrice !== null ? item.decidedPrice > 0 : false;
        }) as DecidedItem[];
    }

    public getUnapprovedItems(): Item[] {
        return filter(this.items, item => {
            return item.approvedPrice === null;
        });
    }

    public storeForParentAsBonus(parent: number): Promise<void> {
        const data: MoneyItemGroupStore = {
            parentBonus: parent,
            name: this.name,
            description: this.description ?? "",
            parentAlternative: null,
        };

        return MoneyApi.itemGroupsStore(data).then(response => {
            ItemGroup.parseWithItems(this, response.data);
        });
    }

    public storeForParentAsAlternative(parent: number): Promise<void> {
        const data: MoneyItemGroupStore = {
            parentAlternative: parent,
            name: this.name,
            description: this.description ?? "",
            parentBonus: null,
        };

        return MoneyApi.itemGroupsStore(data).then(response => {
            ItemGroup.parseWithItems(this, response.data);
        });
    }

    public update(name: string, description: string, form: FormRef): Promise<void> {
        const data: MoneyItemGroupUpdate = {
            name: name,
            description: description,
        };

        return MoneyApi.itemGroupsUpdate(this.id, data, {form}).then(response => {
            ItemGroup.parseWithItems(this, response.data);
        });
    }

    public delete(): Promise<void> {
        return MoneyApi.itemGroupsDestroy(this.id).then();
    }
}
