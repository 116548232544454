/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { KprCategoryApplicationSummaryResponse } from './KprCategoryApplicationSummaryResponse';
    import {
    KprCategoryApplicationSummaryResponseFromJSON,
    KprCategoryApplicationSummaryResponseFromJSONTyped,
    KprCategoryApplicationSummaryResponseToJSON,
    KprCategoryApplicationSummaryResponseCreateEmpty,
    } from './KprCategoryApplicationSummaryResponse';
    import type { MoneyBudgetItemResponse } from './MoneyBudgetItemResponse';
    import {
    MoneyBudgetItemResponseFromJSON,
    MoneyBudgetItemResponseFromJSONTyped,
    MoneyBudgetItemResponseToJSON,
    MoneyBudgetItemResponseCreateEmpty,
    } from './MoneyBudgetItemResponse';
    import type { MoneyBudgetProgramResponse } from './MoneyBudgetProgramResponse';
    import {
    MoneyBudgetProgramResponseFromJSON,
    MoneyBudgetProgramResponseFromJSONTyped,
    MoneyBudgetProgramResponseToJSON,
    MoneyBudgetProgramResponseCreateEmpty,
    } from './MoneyBudgetProgramResponse';
    import type { MoneySpendingItemResponse } from './MoneySpendingItemResponse';
    import {
    MoneySpendingItemResponseFromJSON,
    MoneySpendingItemResponseFromJSONTyped,
    MoneySpendingItemResponseToJSON,
    MoneySpendingItemResponseCreateEmpty,
    } from './MoneySpendingItemResponse';
import {DateTime} from '@Utils/DateTime';

export interface MoneyMoneyGroupSummary {
  relevantSpendingItems: Array<MoneySpendingItemResponse>;
  kprCategoryApplications: Array<KprCategoryApplicationSummaryResponse>;
  budgetItems: Array<MoneyBudgetItemResponse>;
  budgetPrograms: Array<MoneyBudgetProgramResponse>;
}

export const MoneyMoneyGroupSummarySchema = {
  'relevantSpendingItems': {
    name: 'relevantSpendingItems',
    required: true,
    nullable: false,
    label: 'Releváns altételek',
      type: 'array',
  },
  'kprCategoryApplications': {
    name: 'kprCategoryApplications',
    required: true,
    nullable: false,
    label: 'KPR pályázatok',
      type: 'array',
  },
  'budgetItems': {
    name: 'budgetItems',
    required: true,
    nullable: false,
    label: 'Házszintű tételek',
      type: 'array',
  },
  'budgetPrograms': {
    name: 'budgetPrograms',
    required: true,
    nullable: false,
    label: 'Költségvetések',
      type: 'array',
  },
}
export function instanceOfMoneyMoneyGroupSummary(value: object): value is MoneyMoneyGroupSummary {
let isInstance = true;
    isInstance = isInstance && "relevantSpendingItems" in value;
    isInstance = isInstance && "kprCategoryApplications" in value;
    isInstance = isInstance && "budgetItems" in value;
    isInstance = isInstance && "budgetPrograms" in value;

return isInstance;
}

export function MoneyMoneyGroupSummaryFromJSON(json: any): MoneyMoneyGroupSummary {
return MoneyMoneyGroupSummaryFromJSONTyped(json, false);
}

export function MoneyMoneyGroupSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyMoneyGroupSummary {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'relevantSpendingItems': ((json['relevantSpendingItems'] as Array<any>).map(MoneySpendingItemResponseFromJSON)),
            'kprCategoryApplications': ((json['kprCategoryApplications'] as Array<any>).map(KprCategoryApplicationSummaryResponseFromJSON)),
            'budgetItems': ((json['budgetItems'] as Array<any>).map(MoneyBudgetItemResponseFromJSON)),
            'budgetPrograms': ((json['budgetPrograms'] as Array<any>).map(MoneyBudgetProgramResponseFromJSON)),
    };
  }

  export function MoneyMoneyGroupSummaryToJSON(value?: MoneyMoneyGroupSummary | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'relevantSpendingItems': ((value.relevantSpendingItems as Array<any>).map(MoneySpendingItemResponseToJSON)),
              'kprCategoryApplications': ((value.kprCategoryApplications as Array<any>).map(KprCategoryApplicationSummaryResponseToJSON)),
              'budgetItems': ((value.budgetItems as Array<any>).map(MoneyBudgetItemResponseToJSON)),
              'budgetPrograms': ((value.budgetPrograms as Array<any>).map(MoneyBudgetProgramResponseToJSON)),
    };
  }

  export function MoneyMoneyGroupSummaryCreateEmpty(): MoneyMoneyGroupSummary {
  return {
      'relevantSpendingItems': [],
      'kprCategoryApplications': [],
      'budgetItems': [],
      'budgetPrograms': [],
  };
  }

