/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { KprFlagStatisticsResponse } from './KprFlagStatisticsResponse';
    import {
    KprFlagStatisticsResponseFromJSON,
    KprFlagStatisticsResponseFromJSONTyped,
    KprFlagStatisticsResponseToJSON,
    KprFlagStatisticsResponseCreateEmpty,
    } from './KprFlagStatisticsResponse';
import {DateTime} from '@Utils/DateTime';

export interface KprTenderFlagStatisticsResponse {
  flags: Array<KprFlagStatisticsResponse>;
}

export const KprTenderFlagStatisticsResponseSchema = {
  'flags': {
    name: 'flags',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfKprTenderFlagStatisticsResponse(value: object): value is KprTenderFlagStatisticsResponse {
let isInstance = true;
    isInstance = isInstance && "flags" in value;

return isInstance;
}

export function KprTenderFlagStatisticsResponseFromJSON(json: any): KprTenderFlagStatisticsResponse {
return KprTenderFlagStatisticsResponseFromJSONTyped(json, false);
}

export function KprTenderFlagStatisticsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KprTenderFlagStatisticsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'flags': ((json['flags'] as Array<any>).map(KprFlagStatisticsResponseFromJSON)),
    };
  }

  export function KprTenderFlagStatisticsResponseToJSON(value?: KprTenderFlagStatisticsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'flags': ((value.flags as Array<any>).map(KprFlagStatisticsResponseToJSON)),
    };
  }

  export function KprTenderFlagStatisticsResponseCreateEmpty(): KprTenderFlagStatisticsResponse {
  return {
      'flags': [],
  };
  }

