<template>
    <PegasusHeading>Költségvetés - {{ program.name }}</PegasusHeading>

    <div class="row">
        <div class="col-lg-8">
            <ProgramDesignTab :permissions="permissions" :program="program"></ProgramDesignTab>
        </div>
        <div class="col-lg-4">
            <ProgramStatusTab :permissions="permissions" :program="program"></ProgramStatusTab>
        </div>
    </div>
</template>

<title>Költségvetés részletek</title>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {useAuthStore} from '@/stores/auth';
import {computed, ref} from 'vue';
import {BudgetProgram} from '@Models/money/BudgetProgram';
import type {KprPermissions} from '@Models/rvt/kpr/Application';
import ProgramDesignTab from '@Components/money/budgetProgram/tabs/ProgramDesignTab.vue';
import ProgramStatusTab from '@Components/money/budgetProgram/tabs/ProgramStatusTab.vue';
import {BaseGlobalPermission} from '@/api/models';

const {programId} = defineProps<{
    programId: number
}>();

const user = useAuthStore().requireUser();

const program = ref(await BudgetProgram.getById(programId));

const permissions = computed((): KprPermissions => {
    return {
        canEdit: program.value.canEdit(user.value),
        canDecide: user.value.hasPermission(BaseGlobalPermission.BUDGET_EDIT),
        canFinalize: false,
        canConfidential: false
    };
});
</script>
