<template>
    <PegasusModal header="Tétel mozgatása">
        <template v-if="application">
            <TabView>
                <TabPanel v-for="category in application.categories" :key="category.category.id" :header="category.category.name">
                    <PegasusPanel v-for="project in category.itemGroup.alternativeChildren" :key="project.id" :header="project.name">
                        <div v-if="!isEmpty(project.alternativeChildren)">
                            Alternatív csomagok:
                            <div v-for="group in project.alternativeChildren" :key="group.id">
                                {{ group.name }}
                                <PegasusButton variant="success" @click="doMove(group.id)">
                                    Mozgatás ide
                                </PegasusButton>

                                <div v-if="!isEmpty(group.bonusChildren)">
                                    <div v-for="bonus in group.bonusChildren" :key="bonus.id">
                                        {{ bonus.name }}
                                        <PegasusButton variant="success" @click="doMove(bonus.id)">
                                            Mozgatás ide
                                        </PegasusButton>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!isEmpty(project.bonusChildren)">
                            Bónusz csomagok
                            <div v-for="group in project.bonusChildren" :key="group.id">
                                {{ group.name }}
                                <PegasusButton variant="success" @click="doMove(group.id)">
                                    Mozgatás ide
                                </PegasusButton>
                            </div>
                        </div>

                        <div v-if="isEmpty(project.alternativeChildren) && isEmpty(project.bonusChildren)">
                            <PegasusButton variant="success" @click="doMove(project.id)">
                                Mozgatás ide
                            </PegasusButton>
                        </div>
                    </PegasusPanel>

                    <div v-if="category.category.shortName === 'm'">
                        <TextField
                            v-model="customName"
                            :schema="MoneyItemGroupStoreSchema.name"
                            label="Új tételcsoport"
                        ></TextField>

                        <PegasusButton variant="success" @click="doMoveNew(category)">
                            Mozgatás ide
                        </PegasusButton>
                    </div>
                </TabPanel>
            </TabView>
        </template>

        <template v-else>
            <i class="fa fa-spin fa-spinner"></i>
        </template>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {Item} from "@Models/money/Item";
import {ItemGroup} from "@Models/money/ItemGroup";
import {ref} from "vue";
import toast from "@Utils/toast";
import type {CategoryApplication} from "@Models/rvt/kpr/CategoryApplication";
import {isEmpty} from "lodash-es";
import TextField from "@Components/base/form/types/TextField.vue";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import PegasusModal from '@Components/base/PegasusModal.vue';
import {Application} from '@Models/rvt/kpr/Application';
import {useReturn} from '@Utils/dialog';
import {MoneyItemGroupStoreSchema} from '@/api/models';

const {item} = defineProps<{
    item: Item,
}>();

const application = ref<Application | null>(null);

if (item.applicationId === null) {
    throw new Error('Invalid item');
}

Application.getById(item.applicationId).then(response => {
    application.value = response;
});

const customName = ref('');

const returnValue = useReturn();

function doMove(itemGroupId: number) {
    let request = item.move(itemGroupId).then(() => {
        returnValue(true);
    });

    toast.loading(request, 'Mozgatás');
}

function doMoveNew(capp: CategoryApplication) {
    if (!customName.value) {
        toast.error('Név megadása kötelező');
        return;
    }

    const request = new Promise<void>(() => {
        const newGroup = ItemGroup.createNew(customName.value);

        newGroup.storeForParentAsAlternative(capp.itemGroup.id).then(() => {
            item.move(newGroup.id).then(() => {
                returnValue(true);
            });
        });
    });

    toast.loading(request, 'Mozgatás');
}
</script>
