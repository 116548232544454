/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseMinimalUserResponse } from './BaseMinimalUserResponse';
    import {
    BaseMinimalUserResponseFromJSON,
    BaseMinimalUserResponseFromJSONTyped,
    BaseMinimalUserResponseToJSON,
    BaseMinimalUserResponseCreateEmpty,
    } from './BaseMinimalUserResponse';
import {DateTime} from '@Utils/DateTime';

export interface VikoReviewerStatisticsResponse {
  reviewer: BaseMinimalUserResponse;
  reportsReviewed: number;
  avgReviewTime: number;
  shortReviewsCount: number;
}

export const VikoReviewerStatisticsResponseSchema = {
  'reviewer': {
    name: 'reviewer',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'reportsReviewed': {
    name: 'reportsReviewed',
    required: true,
    nullable: false,
    label: 'Elbírált beszámolók',
    type: 'number',
  },
  'avgReviewTime': {
    name: 'avgReviewTime',
    required: true,
    nullable: false,
    label: 'Bírálás átlag ideje',
    type: 'number',
  },
  'shortReviewsCount': {
    name: 'shortReviewsCount',
    required: true,
    nullable: false,
    label: 'Rövid idő alatt bírált beszámolók száma',
    type: 'number',
  },
}
export function instanceOfVikoReviewerStatisticsResponse(value: object): value is VikoReviewerStatisticsResponse {
let isInstance = true;
    isInstance = isInstance && "reviewer" in value;
    isInstance = isInstance && "reportsReviewed" in value;
    isInstance = isInstance && "avgReviewTime" in value;
    isInstance = isInstance && "shortReviewsCount" in value;

return isInstance;
}

export function VikoReviewerStatisticsResponseFromJSON(json: any): VikoReviewerStatisticsResponse {
return VikoReviewerStatisticsResponseFromJSONTyped(json, false);
}

export function VikoReviewerStatisticsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoReviewerStatisticsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'reviewer': BaseMinimalUserResponseFromJSON(json['reviewer']),
          'reportsReviewed': json['reportsReviewed'],
          'avgReviewTime': json['avgReviewTime'],
          'shortReviewsCount': json['shortReviewsCount'],
    };
  }

  export function VikoReviewerStatisticsResponseToJSON(value?: VikoReviewerStatisticsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'reviewer': BaseMinimalUserResponseToJSON(value.reviewer),
                'reportsReviewed': value.reportsReviewed,
                'avgReviewTime': value.avgReviewTime,
                'shortReviewsCount': value.shortReviewsCount,
    };
  }

  export function VikoReviewerStatisticsResponseCreateEmpty(): VikoReviewerStatisticsResponse {
  return {
          'reviewer': BaseMinimalUserResponseCreateEmpty(),
      'reportsReviewed': 0,
      'avgReviewTime': 0,
      'shortReviewsCount': 0,
  };
  }

