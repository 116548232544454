/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { KprCategoryStatus } from './KprCategoryStatus';
    import {
    KprCategoryStatusFromJSON,
    KprCategoryStatusFromJSONTyped,
    KprCategoryStatusToJSON,
    KprCategoryStatusCreateEmpty,
    } from './KprCategoryStatus';
import {DateTime} from '@Utils/DateTime';
import { KprCategoryStatusValues } from './KprCategoryStatus';

export interface KprCategoryStore {
  name: string;
  shortName: string;
  requireContributions: boolean;
  status: KprCategoryStatus;
}

export const KprCategoryStoreSchema = {
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Név',
    type: 'string',
  },
  'shortName': {
    name: 'shortName',
    required: true,
    nullable: false,
    label: 'Rövidítés',
    type: 'string',
  },
  'requireContributions': {
    name: 'requireContributions',
    required: true,
    nullable: false,
    label: 'Önrészes tételek',
    type: 'boolean',
  },
  'status': {
    name: 'status',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "",
        enumValues: KprCategoryStatusValues,
  },
}
export function instanceOfKprCategoryStore(value: object): value is KprCategoryStore {
let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "shortName" in value;
    isInstance = isInstance && "requireContributions" in value;
    isInstance = isInstance && "status" in value;

return isInstance;
}

export function KprCategoryStoreFromJSON(json: any): KprCategoryStore {
return KprCategoryStoreFromJSONTyped(json, false);
}

export function KprCategoryStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): KprCategoryStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'name': json['name'],
          'shortName': json['shortName'],
          'requireContributions': json['requireContributions'],
              'status': KprCategoryStatusFromJSON(json['status']),
    };
  }

  export function KprCategoryStoreToJSON(value?: KprCategoryStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'name': value.name,
                'shortName': value.shortName,
                'requireContributions': value.requireContributions,
                'status': KprCategoryStatusToJSON(value.status),
    };
  }

  export function KprCategoryStoreCreateEmpty(): KprCategoryStore {
  return {
      'name': '',
      'shortName': '',
      'requireContributions': false,
          'status': KprCategoryStatusCreateEmpty(),
  };
  }

