/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneySpendingEventItemCreated {
  urgent: boolean;
}

export const MoneySpendingEventItemCreatedSchema = {
  'urgent': {
    name: 'urgent',
    required: true,
    nullable: false,
    label: 'Sürgős',
    type: 'boolean',
  },
}
export function instanceOfMoneySpendingEventItemCreated(value: object): value is MoneySpendingEventItemCreated {
let isInstance = true;
    isInstance = isInstance && "urgent" in value;

return isInstance;
}

export function MoneySpendingEventItemCreatedFromJSON(json: any): MoneySpendingEventItemCreated {
return MoneySpendingEventItemCreatedFromJSONTyped(json, false);
}

export function MoneySpendingEventItemCreatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneySpendingEventItemCreated {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'urgent': json['urgent'],
    };
  }

  export function MoneySpendingEventItemCreatedToJSON(value?: MoneySpendingEventItemCreated | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'urgent': value.urgent,
    };
  }

  export function MoneySpendingEventItemCreatedCreateEmpty(): MoneySpendingEventItemCreated {
  return {
      'urgent': false,
  };
  }

