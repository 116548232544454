/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MoneySpendingEventType } from './MoneySpendingEventType';
    import {
    MoneySpendingEventTypeFromJSON,
    MoneySpendingEventTypeFromJSONTyped,
    MoneySpendingEventTypeToJSON,
    MoneySpendingEventTypeCreateEmpty,
    } from './MoneySpendingEventType';
    import type { MoneySpendingItemEventResponseData } from './MoneySpendingItemEventResponseData';
    import {
    MoneySpendingItemEventResponseDataFromJSON,
    MoneySpendingItemEventResponseDataFromJSONTyped,
    MoneySpendingItemEventResponseDataToJSON,
    MoneySpendingItemEventResponseDataCreateEmpty,
    } from './MoneySpendingItemEventResponseData';
import {DateTime} from '@Utils/DateTime';
import { MoneySpendingEventTypeValues } from './MoneySpendingEventType';

export interface MoneySpendingItemEvent {
  type: MoneySpendingEventType;
  data: MoneySpendingItemEventResponseData;
  comment: string;
}

export const MoneySpendingItemEventSchema = {
  'type': {
    name: 'type',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Esemény Típusa",
        enumValues: MoneySpendingEventTypeValues,
  },
  'data': {
    name: 'data',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'comment': {
    name: 'comment',
    required: true,
    nullable: false,
    label: 'Megjegyzés',
    type: 'string',
      maxLength: 1000,
  },
}
export function instanceOfMoneySpendingItemEvent(value: object): value is MoneySpendingItemEvent {
let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "comment" in value;

return isInstance;
}

export function MoneySpendingItemEventFromJSON(json: any): MoneySpendingItemEvent {
return MoneySpendingItemEventFromJSONTyped(json, false);
}

export function MoneySpendingItemEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneySpendingItemEvent {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'type': MoneySpendingEventTypeFromJSON(json['type']),
              'data': MoneySpendingItemEventResponseDataFromJSON(json['data']),
          'comment': json['comment'],
    };
  }

  export function MoneySpendingItemEventToJSON(value?: MoneySpendingItemEvent | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'type': MoneySpendingEventTypeToJSON(value.type),
                'data': MoneySpendingItemEventResponseDataToJSON(value.data),
                'comment': value.comment,
    };
  }

  export function MoneySpendingItemEventCreateEmpty(): MoneySpendingItemEvent {
  return {
          'type': MoneySpendingEventTypeCreateEmpty(),
          'data': MoneySpendingItemEventResponseDataCreateEmpty(),
      'comment': '',
  };
  }

