import * as Sentry from '@sentry/vue';
import {upperDomain} from '@/router/domains';
import {AuthenticationRequiredError} from '@Utils/errors/AuthenticationRequiredError';
import {ValidationError} from '@Utils/errors/ValidationError';
import {NotFoundError} from '@Utils/errors/NotFoundError';
import type {App} from 'vue';

export function initSentry(app: App) {
    Sentry.init({
        app,
        dsn: 'https://REPLACE@REPLACE.io/1',
        tunnel: '/api/js/sentry',
        logErrors: false,
        environment: upperDomain() === 'test.sch.bme.hu' ? 'staging' : 'production',
        integrations: [Sentry.captureConsoleIntegration(), Sentry.extraErrorDataIntegration()],
        beforeSend(event, hint) {
            const error = hint.originalException;

            if (error instanceof AuthenticationRequiredError && error.name === 'AuthenticationRequiredError') {
                return null;
            }

            if (error instanceof ValidationError && error.name === 'ValidationError') {
                return null;
            }

            if (error instanceof NotFoundError && error.name === 'NotFoundError') {
                return null;
            }

            return event;
        },
    });
}
