/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { KprTenderResponse } from './KprTenderResponse';
    import {
    KprTenderResponseFromJSON,
    KprTenderResponseFromJSONTyped,
    KprTenderResponseToJSON,
    KprTenderResponseCreateEmpty,
    } from './KprTenderResponse';
import {DateTime} from '@Utils/DateTime';

export interface RvtSpendingPeriodResponse {
  id: number;
  from: DateTime;
  to: DateTime;
  name: string;
  tender: KprTenderResponse | null;
}

export const RvtSpendingPeriodResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'from': {
    name: 'from',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'to': {
    name: 'to',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'tender': {
    name: 'tender',
    required: true,
    nullable: true,
      type: 'embedded',
  },
}
export function instanceOfRvtSpendingPeriodResponse(value: object): value is RvtSpendingPeriodResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "to" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "tender" in value;

return isInstance;
}

export function RvtSpendingPeriodResponseFromJSON(json: any): RvtSpendingPeriodResponse {
return RvtSpendingPeriodResponseFromJSONTyped(json, false);
}

export function RvtSpendingPeriodResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RvtSpendingPeriodResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
        'from': (DateTime.fromISO(json['from'])),
        'to': (DateTime.fromISO(json['to'])),
          'name': json['name'],
              'tender': KprTenderResponseFromJSON(json['tender']),
    };
  }

  export function RvtSpendingPeriodResponseToJSON(value?: RvtSpendingPeriodResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
            'from': (value.from.toISO()),
            'to': (value.to.toISO()),
                'name': value.name,
                'tender': KprTenderResponseToJSON(value.tender),
    };
  }

  export function RvtSpendingPeriodResponseCreateEmpty(): RvtSpendingPeriodResponse {
  return {
      'id': 0,
      'from': DateTime.now(),
      'to': DateTime.now(),
      'name': '',
          'tender': KprTenderResponseCreateEmpty(),
  };
  }

