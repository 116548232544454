/* tslint:disable */
/* eslint-disable */

import {
    type MoneySpendingEventCashIn,
    MoneySpendingEventCashInFromJSONTyped,
    MoneySpendingEventCashInToJSON,
    MoneySpendingEventCashInCreateEmpty,
    instanceOfMoneySpendingEventCashIn,
} from './MoneySpendingEventCashIn';
import {
    type MoneySpendingEventCashOut,
    MoneySpendingEventCashOutFromJSONTyped,
    MoneySpendingEventCashOutToJSON,
    MoneySpendingEventCashOutCreateEmpty,
    instanceOfMoneySpendingEventCashOut,
} from './MoneySpendingEventCashOut';
import {
    type MoneySpendingEventInvoiceUploaded,
    MoneySpendingEventInvoiceUploadedFromJSONTyped,
    MoneySpendingEventInvoiceUploadedToJSON,
    MoneySpendingEventInvoiceUploadedCreateEmpty,
    instanceOfMoneySpendingEventInvoiceUploaded,
} from './MoneySpendingEventInvoiceUploaded';
import {
    type MoneySpendingEventItemCreated,
    MoneySpendingEventItemCreatedFromJSONTyped,
    MoneySpendingEventItemCreatedToJSON,
    MoneySpendingEventItemCreatedCreateEmpty,
    instanceOfMoneySpendingEventItemCreated,
} from './MoneySpendingEventItemCreated';
import {
    type MoneySpendingEventSpendingSettled,
    MoneySpendingEventSpendingSettledFromJSONTyped,
    MoneySpendingEventSpendingSettledToJSON,
    MoneySpendingEventSpendingSettledCreateEmpty,
    instanceOfMoneySpendingEventSpendingSettled,
} from './MoneySpendingEventSpendingSettled';
import {
    type MoneySpendingEventTransferRegistered,
    MoneySpendingEventTransferRegisteredFromJSONTyped,
    MoneySpendingEventTransferRegisteredToJSON,
    MoneySpendingEventTransferRegisteredCreateEmpty,
    instanceOfMoneySpendingEventTransferRegistered,
} from './MoneySpendingEventTransferRegistered';
import {
    type MoneySpendingEventTransferRequested,
    MoneySpendingEventTransferRequestedFromJSONTyped,
    MoneySpendingEventTransferRequestedToJSON,
    MoneySpendingEventTransferRequestedCreateEmpty,
    instanceOfMoneySpendingEventTransferRequested,
} from './MoneySpendingEventTransferRequested';
import {
    type MoneySpendingEventTransferSent,
    MoneySpendingEventTransferSentFromJSONTyped,
    MoneySpendingEventTransferSentToJSON,
    MoneySpendingEventTransferSentCreateEmpty,
    instanceOfMoneySpendingEventTransferSent,
} from './MoneySpendingEventTransferSent';

export type MoneySpendingItemEventResponseData = { type: 'cashIn' } & MoneySpendingEventCashIn | { type: 'cashOut' } & MoneySpendingEventCashOut | { type: 'created' } & MoneySpendingEventItemCreated | { type: 'invoiceUploaded' } & MoneySpendingEventInvoiceUploaded | { type: 'spendingSettled' } & MoneySpendingEventSpendingSettled | { type: 'transferRegistered' } & MoneySpendingEventTransferRegistered | { type: 'transferRequested' } & MoneySpendingEventTransferRequested | { type: 'transferSent' } & MoneySpendingEventTransferSent;
export type MoneySpendingItemEventResponseDataSimple = MoneySpendingEventCashIn | MoneySpendingEventCashOut | MoneySpendingEventItemCreated | MoneySpendingEventInvoiceUploaded | MoneySpendingEventSpendingSettled | MoneySpendingEventTransferRegistered | MoneySpendingEventTransferRequested | MoneySpendingEventTransferSent;



export function MoneySpendingItemEventResponseDataFromJSON(json: any): MoneySpendingItemEventResponseData {
    return MoneySpendingItemEventResponseDataFromJSONTyped(json, false);
}

export function MoneySpendingItemEventResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneySpendingItemEventResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'cashIn':
            return {...MoneySpendingEventCashInFromJSONTyped(json, true), type: 'cashIn'};
        case 'cashOut':
            return {...MoneySpendingEventCashOutFromJSONTyped(json, true), type: 'cashOut'};
        case 'created':
            return {...MoneySpendingEventItemCreatedFromJSONTyped(json, true), type: 'created'};
        case 'invoiceUploaded':
            return {...MoneySpendingEventInvoiceUploadedFromJSONTyped(json, true), type: 'invoiceUploaded'};
        case 'spendingSettled':
            return {...MoneySpendingEventSpendingSettledFromJSONTyped(json, true), type: 'spendingSettled'};
        case 'transferRegistered':
            return {...MoneySpendingEventTransferRegisteredFromJSONTyped(json, true), type: 'transferRegistered'};
        case 'transferRequested':
            return {...MoneySpendingEventTransferRequestedFromJSONTyped(json, true), type: 'transferRequested'};
        case 'transferSent':
            return {...MoneySpendingEventTransferSentFromJSONTyped(json, true), type: 'transferSent'};
        default:
            throw new Error(`No variant of MoneySpendingItemEventResponseData exists with 'type=${json['type']}'`);
    }
}

export function MoneySpendingItemEventResponseDataToJSON(value?: MoneySpendingItemEventResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'cashIn':
            return MoneySpendingEventCashInToJSON(value);
        case 'cashOut':
            return MoneySpendingEventCashOutToJSON(value);
        case 'created':
            return MoneySpendingEventItemCreatedToJSON(value);
        case 'invoiceUploaded':
            return MoneySpendingEventInvoiceUploadedToJSON(value);
        case 'spendingSettled':
            return MoneySpendingEventSpendingSettledToJSON(value);
        case 'transferRegistered':
            return MoneySpendingEventTransferRegisteredToJSON(value);
        case 'transferRequested':
            return MoneySpendingEventTransferRequestedToJSON(value);
        case 'transferSent':
            return MoneySpendingEventTransferSentToJSON(value);
        default:
            throw new Error(`No variant of MoneySpendingItemEventResponseData exists with 'type=${value['type']}'`);
    }

}
export function MoneySpendingItemEventResponseDataCreateEmpty(): MoneySpendingItemEventResponseData {
    const ret: MoneySpendingItemEventResponseData = MoneySpendingEventCashInCreateEmpty() as MoneySpendingItemEventResponseData;
    ret.type = 'cashIn'
      return ret;
}
export function MoneySpendingItemEventResponseDataCreateEmptyByType(type: string): MoneySpendingItemEventResponseDataSimple {
  switch (type) {
    case 'cashIn':
      return MoneySpendingEventCashInCreateEmpty();
    case 'cashOut':
      return MoneySpendingEventCashOutCreateEmpty();
    case 'created':
      return MoneySpendingEventItemCreatedCreateEmpty();
    case 'invoiceUploaded':
      return MoneySpendingEventInvoiceUploadedCreateEmpty();
    case 'spendingSettled':
      return MoneySpendingEventSpendingSettledCreateEmpty();
    case 'transferRegistered':
      return MoneySpendingEventTransferRegisteredCreateEmpty();
    case 'transferRequested':
      return MoneySpendingEventTransferRequestedCreateEmpty();
    case 'transferSent':
      return MoneySpendingEventTransferSentCreateEmpty();
    default:
      throw new Error(`No variant of MoneySpendingItemEventResponseData exists with 'type=${type}'`);
  }
}

