<template>
    <PegasusModal header="Költségvetés véglegesítés">
        <PegasusForm ref="form">
            A költségvetés véglegesítése után elküldésre kerül bírálásra a jóváhagyó szervhez és többé nem
            szerkeszthető.

            <hr>

            <TextareaField
                v-model="comment"
                :schema="MoneyBudgetProgramMilestoneAddSchema.comment"
            ></TextareaField>

            <PegasusButton
                @click="submit"
            >
                <i class="fa fa-paper-plane"></i> Véglegesítés
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import PegasusModal from '@Components/base/PegasusModal.vue';
import {MoneyBudgetProgramMilestoneAddSchema} from '@/api/models';
import {ref} from 'vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import toast from '@Utils/toast';
import type {BudgetProgram} from '@Models/money/BudgetProgram';
import {useReturn} from '@Utils/dialog';
import TextareaField from '@Components/base/form/types/TextareaField.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import type {FormRef} from '@/vue';

const {program} = defineProps<{
    program: BudgetProgram
}>();

const returnValue = useReturn();

const form = ref<FormRef>(null);

const comment = ref('');

async function submit() {
    if (!await toast.confirm('Biztosan véglegesíted a költségvetést?')) {
        return;
    }

    const request = program.submit(comment.value, form.value).then(() => {
        returnValue(true);
    });

    toast.loading(request, 'Véglegesítés');
}
</script>
