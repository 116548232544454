/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseMinimalUserResponse } from './BaseMinimalUserResponse';
    import {
    BaseMinimalUserResponseFromJSON,
    BaseMinimalUserResponseFromJSONTyped,
    BaseMinimalUserResponseToJSON,
    BaseMinimalUserResponseCreateEmpty,
    } from './BaseMinimalUserResponse';
    import type { MoneyBudgetProgramMilestoneType } from './MoneyBudgetProgramMilestoneType';
    import {
    MoneyBudgetProgramMilestoneTypeFromJSON,
    MoneyBudgetProgramMilestoneTypeFromJSONTyped,
    MoneyBudgetProgramMilestoneTypeToJSON,
    MoneyBudgetProgramMilestoneTypeCreateEmpty,
    } from './MoneyBudgetProgramMilestoneType';
import {DateTime} from '@Utils/DateTime';
import { MoneyBudgetProgramMilestoneTypeValues } from './MoneyBudgetProgramMilestoneType';

export interface MoneyBudgetProgramMilestoneResponse {
  id: number;
  type: MoneyBudgetProgramMilestoneType;
  user: BaseMinimalUserResponse;
  comment: string | null;
  createdAt: DateTime;
}

export const MoneyBudgetProgramMilestoneResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'type': {
    name: 'type',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "",
        enumValues: MoneyBudgetProgramMilestoneTypeValues,
  },
  'user': {
    name: 'user',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'comment': {
    name: 'comment',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'createdAt': {
    name: 'createdAt',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
}
export function instanceOfMoneyBudgetProgramMilestoneResponse(value: object): value is MoneyBudgetProgramMilestoneResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "createdAt" in value;

return isInstance;
}

export function MoneyBudgetProgramMilestoneResponseFromJSON(json: any): MoneyBudgetProgramMilestoneResponse {
return MoneyBudgetProgramMilestoneResponseFromJSONTyped(json, false);
}

export function MoneyBudgetProgramMilestoneResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyBudgetProgramMilestoneResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
              'type': MoneyBudgetProgramMilestoneTypeFromJSON(json['type']),
              'user': BaseMinimalUserResponseFromJSON(json['user']),
          'comment': json['comment'],
        'createdAt': (DateTime.fromISO(json['createdAt'])),
    };
  }

  export function MoneyBudgetProgramMilestoneResponseToJSON(value?: MoneyBudgetProgramMilestoneResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'type': MoneyBudgetProgramMilestoneTypeToJSON(value.type),
                'user': BaseMinimalUserResponseToJSON(value.user),
                'comment': value.comment,
            'createdAt': (value.createdAt.toISO()),
    };
  }

  export function MoneyBudgetProgramMilestoneResponseCreateEmpty(): MoneyBudgetProgramMilestoneResponse {
  return {
      'id': 0,
          'type': MoneyBudgetProgramMilestoneTypeCreateEmpty(),
          'user': BaseMinimalUserResponseCreateEmpty(),
      'comment': '',
      'createdAt': DateTime.now(),
  };
  }

