/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { KprCategoryResponse } from './KprCategoryResponse';
    import {
    KprCategoryResponseFromJSON,
    KprCategoryResponseFromJSONTyped,
    KprCategoryResponseToJSON,
    KprCategoryResponseCreateEmpty,
    } from './KprCategoryResponse';
import {DateTime} from '@Utils/DateTime';

export interface KprTenderResponse {
  id: number;
  spendingPeriodId: number;
  displayName: string;
  categories: Array<KprCategoryResponse>;
  claimsBudgetItemId: number | null;
  amendable: boolean;
}

export const KprTenderResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'spendingPeriodId': {
    name: 'spendingPeriodId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
  'displayName': {
    name: 'displayName',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'categories': {
    name: 'categories',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'claimsBudgetItemId': {
    name: 'claimsBudgetItemId',
    required: true,
    nullable: true,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'amendable': {
    name: 'amendable',
    required: true,
    nullable: false,
    label: '',
    type: 'boolean',
  },
}
export function instanceOfKprTenderResponse(value: object): value is KprTenderResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "spendingPeriodId" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "categories" in value;
    isInstance = isInstance && "claimsBudgetItemId" in value;
    isInstance = isInstance && "amendable" in value;

return isInstance;
}

export function KprTenderResponseFromJSON(json: any): KprTenderResponse {
return KprTenderResponseFromJSONTyped(json, false);
}

export function KprTenderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KprTenderResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'spendingPeriodId': json['spendingPeriodId'],
          'displayName': json['displayName'],
            'categories': ((json['categories'] as Array<any>).map(KprCategoryResponseFromJSON)),
          'claimsBudgetItemId': json['claimsBudgetItemId'],
          'amendable': json['amendable'],
    };
  }

  export function KprTenderResponseToJSON(value?: KprTenderResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'spendingPeriodId': value.spendingPeriodId,
                'displayName': value.displayName,
              'categories': ((value.categories as Array<any>).map(KprCategoryResponseToJSON)),
                'claimsBudgetItemId': value.claimsBudgetItemId,
                'amendable': value.amendable,
    };
  }

  export function KprTenderResponseCreateEmpty(): KprTenderResponse {
  return {
      'id': 0,
      'spendingPeriodId': 0,
      'displayName': '',
      'categories': [],
      'claimsBudgetItemId': 0,
      'amendable': false,
  };
  }

