<template>
    <PegasusHeading>Költhető tételek - {{ itemGroup.name }}</PegasusHeading>

    <div class="row">
        <div v-for="group in itemGroup.alternativeChildren" :key="group.id" class="col-lg-6">
            <PegasusPanel :header="group.name" class="mb-2">
                <div v-for="item in group.allItems()" :key="item.id">{{ item.displayName }}</div>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>Költhető tételek</title>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {ItemGroup} from '@Models/money/ItemGroup';
import {ref} from 'vue';
import PegasusPanel from '@Components/base/PegasusPanel.vue';

const {itemGroupId} = defineProps<{
    itemGroupId: number
}>();

const itemGroup = ref(await ItemGroup.getById(itemGroupId));
</script>
