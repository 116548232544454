<template>
    <PegasusHeading>{{ group.name }}</PegasusHeading>

    <div class="row">
        <div class="col-md-6 mb-3">
            <PegasusPanel header="Kör adatai">
                <dl>
                    <dt v-if="group.resort">Körvezető</dt>
                    <dt v-else>Reszortvezető</dt>
                    <dd v-if="summary.leader">{{ summary.leader.name }}</dd>
                    <dd v-else>Ismeretlen</dd>

                    <dt v-if="group.resort">Gazdaságis</dt>
                    <dt v-else>Reszortgazdaságis</dt>
                    <dd v-if="summary.money">{{ summary.money.name }}</dd>
                    <dd v-else>Ismeretlen</dd>

                    <dt v-if="group.resort">Reszort</dt>
                    <dd v-if="group.resort">{{ group.getResort().name }}</dd>

                    <dt>
                        <a :href="'https://pek.sch.bme.hu/groups/' + group.virId" class="float-end">
                            <img alt="PéK" src="@Assets/images/pek_logo.png" width="50px">
                        </a>
                    </dt>

                    <dd v-if="user.hasGroupPermission(group, BaseGroupSpecificPermission.READ_MONEY) || user.hasPermission(BaseGlobalPermission.MONEY)">
                        <PegasusButton :params="{group}" to="rvt.groups.archive" variant="primary">
                            <i class="fa fa-box-archive"></i> Archívum
                        </PegasusButton>
                    </dd>

                    <dd v-if="user.hasGroupPermission(group, BaseGroupSpecificPermission.MANAGE_PERMISSIONS)">
                        <PegasusButton :params="{group}" to="rvt.groups.settings" variant="success">
                            <i class="fa fa-unlock-keyhole"></i> Jogosultsági beállítások
                        </PegasusButton>
                    </dd>

                    <dd>
                        <PegasusButton :params="{group}" to="rvt.groups.statements" variant="success">
                            <i class="fa fa-file-word"></i> Beszámolók
                        </PegasusButton>
                    </dd>
                </dl>
            </PegasusPanel>
        </div>

        <div v-if="user.hasPermission(BaseGlobalPermission.GROUPS_CAPABILITIES)" class="col-md-6 mb-3">
            <PegasusPanel header="Beállítások">
                <table class="table">
                    <tbody>
                        <tr v-for="(capability, key) in GroupCapabilities" :key="key">
                            <th>{{ capability }}</th>
                            <td v-if="group[key]">Engedélyezve</td>
                            <td v-else>Letiltva</td>
                            <td>
                                <PegasusButton v-if="group[key]" size="sm" variant="danger" @click="toggle(key)">
                                    Letiltás
                                </PegasusButton>
                                <PegasusButton v-else size="sm" variant="success" @click="toggle(key)">
                                    Engedélyezés
                                </PegasusButton>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </PegasusPanel>
        </div>

        <div class="col-md-6 mb-3">
            <GroupAccess :access="access" :user="user"></GroupAccess>
        </div>

        <div v-for="periodData in summary.periods" :key="periodData.period.id" class="col-md-6 mb-3">
            <PegasusPanel>
                <template #header>
                    <PegasusLink :params="{group, period: periodData.period}" to="rvt.groups.money">
                        Gazdasági év {{ periodData.period.from.year }}
                    </PegasusLink>
                </template>

                <div v-if="periodData.tender" class="row">
                    <div class="col-md-6">
                        <div>
                            <h4 class="text-center">{{ periodData.tender.displayName }}</h4>
                            <dl v-if="group.kprEnabled">
                                <dt>Státusz</dt>
                                <dd>
                                    {{ periodData.tender.status }}
                                </dd>
                                <dd>
                                    <a :href="'/api/kpr/tenders/' + periodData.tender.id + '/rules'" target="_blank">
                                        Pályázati kiírás
                                    </a>
                                </dd>
                                <div v-if="periodData.application">
                                    <dd>
                                        <PegasusLink :params="{application: periodData.application}" to="rvt.kpr.application">
                                            Pályázat megnyitása
                                        </PegasusLink>
                                    </dd>

                                    <dt>Reszortvezetőre váró kérvények</dt>
                                    <dd>{{ periodData.pendingAmends }}</dd>

                                    <dt>RVT döntésre váró kérvények</dt>
                                    <dd>{{ periodData.waitingAmends }}</dd>
                                </div>
                                <dd v-else>
                                    Pályázat még nincsen leadva
                                </dd>
                            </dl>
                            <span v-else>A kör számára ez a funkció nem engedélyezett</span>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <h4 class="text-center">Keretigények</h4>
                        <dl v-if="group.kprEnabled">
                            <dt>Reszortvezetőre váró keretigények</dt>
                            <dd>{{ periodData.pendingClaims }}</dd>
                            <dt>RVT döntésre váró keretigények</dt>
                            <dd>{{ periodData.waitingClaims }}</dd>
                        </dl>

                        <span v-else>A kör számára ez a funkció nem engedélyezett</span>
                    </div>
                </div>

                <div v-else>Az időszakhoz nem tartozik KPR kiírás</div>
            </PegasusPanel>
        </div>

        <div class="col-md-3">
            <PegasusPanel>
                <template #header>
                    <PegasusLink :params="{group}" to="inventory.groups.index">Leltár</PegasusLink>
                </template>

                <dl v-if="group.inventoryEnabled && summary.inventory">
                    <GroupStatus :group-summary="summary.inventory"></GroupStatus>
                </dl>

                <span v-else>A kör számára ez a funkció nem engedélyezett</span>
            </PegasusPanel>
        </div>

        <div class="col-md-3">
            <PegasusPanel>
                <template #header>
                    <PegasusLink :params="{group}" to="viko.leader">VIKÖ</PegasusLink>
                </template>

                <dl v-if="group.vikoEnabled && summary.viko">
                    <dt>Pályázat</dt>
                    <dd>{{ summary.viko.name }}</dd>
                    <dt>Elbírálásra váró beszámolók</dt>
                    <dd>{{ summary.viko.waitingReports }}</dd>
                    <dt>Elbírált beszámolók</dt>
                    <dd>{{ summary.viko.doneReports }}</dd>
                </dl>

                <span v-else-if="group.vikoEnabled">Nincsen aktuális VIKÖ pályázat</span>

                <span v-else>A kör számára ez a funkció nem engedélyezett</span>
            </PegasusPanel>
        </div>
        <div class="col-md-3">
            <PegasusPanel>
                <template #header>
                    <PegasusLink :params="{group}" to="events.group">Rendezvények</PegasusLink>
                </template>

                <dl v-if="group.eventEnabled && summary.events">
                    <dt>Szerkesztés alatt lévő</dt>
                    <dd>{{ summary.events.drafting }}</dd>
                    <dt>Elfogadás alatt lévő</dt>
                    <dd>{{ summary.events.waiting }}</dd>
                    <dt>Bejelentett jövőbeli rendezvények</dt>
                    <dd>{{ summary.events.future }}</dd>
                </dl>

                <span v-else>A kör számára ez a funkció nem engedélyezett</span>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>Kör adatlap</title>

<script lang="ts" setup>
import {GroupSummary} from "@Models/rvt/GroupSummary";
import {useAuthStore} from "@/stores/auth";
import toast from "@Utils/toast";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusLink from "@Components/base/PegasusLink.vue";
import {GroupCapabilities} from "@Models/base/Group";
import {computed} from 'vue';
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import GroupAccess from '@Components/rvt/groups/GroupAccess.vue';
import {BaseGlobalPermission, BaseGroupSpecificPermission} from '@/api/models';
import GroupStatus from '@Components/inventory/group/GroupStatus.vue';

const {groupId} = defineProps<{
    groupId: number
}>();

const user = useAuthStore().requireUser();

const summary = await GroupSummary.getById(groupId);
const group = summary.group;
const access = computed(() => user.value.getAccess(group) ?? null);

async function toggle(capability: keyof typeof GroupCapabilities) {
    if (!await toast.confirm('Biztos vagy benne?')) {
        return;
    }

    group[capability] = !group[capability];

    let request = group.update();

    toast.loading(request);
}
</script>
