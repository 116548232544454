<template>
    <PegasusModal header="Dokumentum feltöltése">
        <template v-if="currentImage">
            <PegasusImage :src="currentImage" image-class="img-fluid" preview></PegasusImage>

            <hr>

            <div class="text-center">
                <PegasusButton variant="warning" @click="currentImage = null">
                    <i class="fa fa-camera-rotate"></i> Új kép
                </PegasusButton>
            </div>
        </template>

        <template v-if="!currentImage">
            <FileField
                v-model="uploadFile"
                :schema="InventoryDocumentStoreRequestSchema.file"
                drop-placeholder="Ide húzható a fájl"
                placeholder="Fájl kiválasztása"
            ></FileField>
        </template>

        <PegasusForm v-if="currentImage" ref="form">
            <hr>

            <TextField v-model="newDocument.name" :schema="InventoryDocumentStoreRequestSchema.name"></TextField>
            <TextField v-model="newDocument.comment" :schema="InventoryDocumentStoreRequestSchema.comment"></TextField>

            <PegasusButton variant="primary" @click="save">
                Feltöltés
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {Item} from "@Models/inventory/Item";
import {Document} from "@Models/inventory/Document";
import {File} from "@Models/File";
import TextField from "@Components/base/form/types/TextField.vue";
import {ref, watch} from "vue";
import type {InventoryDocumentType} from "@/api/models";
import {InventoryDocumentStoreRequestSchema} from '@/api/models';
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusImage from "@Components/base/PegasusImage.vue";
import PegasusModal from "@Components/base/PegasusModal.vue";
import toast from "@Utils/toast";
import type {FormRef} from "@/vue";
import FileField from "@Components/base/form/types/FileField.vue";
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import {useReturn} from '@Utils/dialog';

const {item, documentType} = defineProps<{
    item: Item,
    documentType: InventoryDocumentType
}>();

const returnValue = useReturn();

const newDocument = ref(new Document());

const currentImage = ref<string | null>(null);

const uploadFile = ref<File | null>(null);

const form = ref<FormRef>(null);

function save() {
    if (currentImage.value === null) {
        throw new Error('Trying to save while having no picture');
    }

    newDocument.value.type = documentType;

    let file = uploadFile.value ?? File.createFromDataUri(currentImage.value, 'image.jpeg');

    let request = newDocument.value.store(item.id, file, form.value).then(() => {
        item.documents[newDocument.value.id] = newDocument.value;

        returnValue(true);
    });

    toast.loading(request);
}

watch(uploadFile, newValue => {
    if (newValue === null) {
        return;
    }

    newValue.getAsDataUrl().then(data => {
        currentImage.value = data;
    });
});
</script>
