import {BudgetCategory} from '@Models/money/BudgetCategory';
import type {SpendingPeriod} from '@Models/money/SpendingPeriod';
import {MoneyApi} from '@/api/apis';
import {Base} from '@Models/Base';
import type {MoneyBudgetCategoryListResponse} from '@/api/models';

export class Budget extends Base {
    categories: Record<number, BudgetCategory>;

    static getBySpendingPeriod(period: SpendingPeriod): Promise<Budget> {
        return MoneyApi.spendingPeriodsBudget(period.id).then(response => {
            return Budget.newSingle(response.data, Budget.parseResponse);
        });
    }

    static parseResponse(budget: Budget, data: MoneyBudgetCategoryListResponse): Budget {
        budget.categories = BudgetCategory.newRecords(data.categories, BudgetCategory.parseExtendedResponse);

        return budget;
    }
}
