import {BaseEntity} from "@Models/BaseEntity";
import {User} from "@Models/base/User";
import {DateTime} from "@Utils/DateTime";
import {MoneySpendingEventType, MoneySpendingEventTypeValues, type MoneySpendingItemEventResponse} from "@/api/api";

export abstract class SpendingItemEvent extends BaseEntity {
    type: MoneySpendingEventType;

    comment: string = '';

    user: User;

    createdAt: DateTime;

    get text(): {key: string, value: string}[] {
        return [];
    }

    static parseResponse(update: SpendingItemEvent, data: MoneySpendingItemEventResponse): SpendingItemEvent {
        update.id = data.id;
        update.type = data.type;
        update.user = User.newSingle(data.user, User.parseMinimalResponse);
        update.comment = data.comment;
        update.createdAt = data.createdAt;

        return update;
    }

    static getIcons(): Record<MoneySpendingEventType, [string, string]> {
        return {
            [MoneySpendingEventType.CREATED]: ['fa fa-plus', 'bg-primary'],
            [MoneySpendingEventType.CASH_IN]: ['fa fa-money-bill-trend-up', 'bg-success'],
            [MoneySpendingEventType.CASH_OUT]: ['fa fa-right-from-bracket', 'bg-success'],
            [MoneySpendingEventType.INVOICE_UPLOADED]: ['fa fa-heart-circle-minus', 'bg-warning'],
            [MoneySpendingEventType.TRANSFER_REQUESTED]: ['fa fa-right-left', 'bg-success'],
            [MoneySpendingEventType.TRANSFER_REGISTERED]: ['fa fa-pen-nib', 'bg-danger'],
            [MoneySpendingEventType.TRANSFER_SENT]: ['fa fa-check', 'bg-success'],
            [MoneySpendingEventType.SPENDING_SETTLED]: ['fa fa-file-circle-check', 'bg-success'],
        };
    }

    public getName(): string {
        return MoneySpendingEventTypeValues[this.type];
    }
}
