import {Base} from "@Models/Base";
import type {VikoReviewerStatisticsResponse} from "@/api/api";
import {User} from '@Models/base/User';

export class ReviewerStatistics extends Base {
    user: User;

    reportsReviewed: number;

    avgReviewTime: number;

    shortReviewsCount: number;

    static parseResponse(problems: ReviewerStatistics, data: VikoReviewerStatisticsResponse): ReviewerStatistics {
        problems.user = User.newSingle(data.reviewer, User.parseMinimalResponse);
        problems.reportsReviewed = data.reportsReviewed;
        problems.avgReviewTime = data.avgReviewTime;
        problems.shortReviewsCount = data.shortReviewsCount;

        return problems;
    }
}
