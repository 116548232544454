<template>
    <PegasusForm ref="form" :visible="visible">
        <TextField
            v-model="item.name"
            :disabled="!permissions.canEdit"
            :schema="MoneyItemStoreSchema.name"
            @update:model-value="dirty = true"
        ></TextField>

        <TextareaField
            v-model="item.description"
            :disabled="!permissions.canEdit"
            :schema="MoneyItemStoreSchema.description"
            @update:model-value="dirty = true"
        ></TextareaField>

        <div v-if="item.type === 'k'">
            <div class="row">
                <div class="col-lg-4">
                    <NumberField
                        v-model="item.price"
                        :disabled="!permissions.canEdit"
                        :schema="MoneyItemStoreSchema.price"
                        number-suffix="HUF"
                        @update:model-value="dirty = true"
                    ></NumberField>
                </div>

                <div class="col-lg-4">
                    <NumberField
                        v-model="item.contribution"
                        :disabled="!permissions.canEdit"
                        :schema="MoneyItemStoreSchema.contribution"
                        number-suffix="HUF"
                        @update:model-value="dirty = true"
                    ></NumberField>
                </div>

                <div class="col-lg-4">
                    <StaticField :value="item.totalPrice.huf()" label="Teljes költség"></StaticField>
                </div>
            </div>
        </div>

        <div v-else>
            <NumberField
                v-model="item.price"
                :disabled="!permissions.canEdit"
                :schema="MoneyItemStoreSchema.price"
                @update:model-value="dirty = true"
            ></NumberField>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <span class="fw-bold me-2">Cimkék</span>
                <PegasusHelp title="Cimkék" wide>
                    <p v-for="flag in flags" :key="flag.id">
                        <b>{{ flag.name }}</b>
                        {{ flag.description }}
                    </p>
                </PegasusHelp>
                <FlagSelector v-model="item.flags" @update:model-value="dirty = true"></FlagSelector>
            </div>

            <div class="col-lg-6">
                <template v-if="!isCamp">
                    <SelectField
                        v-model="item.inventoryType"
                        :disabled="!permissions.canEdit"
                        :options="numberKeyedDict(MoneyInventoryTypeValues)"
                        :schema="MoneyItemUpdateSchema.inventoryType"
                        label="Leltár típus"
                        @update:model-value="dirty = true"
                    ></SelectField>
                </template>

                <template v-else>
                    <NumberField
                        v-model="item.campNumber"
                        :disabled="!permissions.canEdit"
                        :schema="MoneyItemStoreSchema.campNumber"
                        number-suffix="fő"
                    ></NumberField>
                </template>
            </div>
        </div>

        <div v-if="item.type !== null" class="my-2">
            <ItemAssets :can-edit="permissions.canEdit" :item="item"></ItemAssets>
        </div>

        <div v-if="permissions.canConfidential" :id="'confidential-' + item.id" class="row confidential-row">
            <div class="col-sm-8">
                <dd>
                    <TextareaField
                        v-model="item.comment"
                        :disabled="!permissions.canEdit"
                        :schema="MoneyItemStoreSchema.comment"
                        @update:model-value="dirty = true"
                    ></TextareaField>
                </dd>
            </div>

            <div class="col-sm-3">
                <dt></dt>
                <dd>
                    <PegasusForm part="priority">
                        <table class="table table-bordered table-sm">
                            <thead>
                                <tr>
                                    <th class="text-nowrap">
                                        Prioritás
                                        <PegasusHelp title="Prioritás tábla">
                                            Amennyiben a reszortvezető kéri itt lehet megjelölni egy tétel
                                            fontosságát
                                        </PegasusHelp>
                                    </th>
                                    <th>Összeg</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(value, prio) in item.priority" :key="prio">
                                    <td>
                                        {{ prio === "one" ? "1" : prio === "two" ? "2" : "3" }}
                                    </td>

                                    <td>
                                        <TextField
                                            v-model="item.priority[prio]"
                                            :disabled="!permissions.canEdit"
                                            :schema="MoneyPrioritySchema.one"
                                            size="small"
                                            without-group
                                            @update:model-value="dirty = true"
                                        ></TextField>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </PegasusForm>
                </dd>
            </div>
        </div>
    </PegasusForm>
</template>

<script lang="ts" setup>
import {numberKeyedDict} from '@Models/Utils';
import StaticField from '@Components/base/form/types/StaticField.vue';
import ItemAssets from '@Components/money/item/ItemAssets.vue';
import SelectField from '@Components/base/form/types/SelectField.vue';
import PegasusHelp from '@Components/base/PegasusHelp.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import TextareaField from '@Components/base/form/types/TextareaField.vue';
import FlagSelector from '@Components/money/item/FlagSelector.vue';
import TextField from '@Components/base/form/types/TextField.vue';
import NumberField from '@Components/base/form/types/NumberField.vue';
import type {Item} from '@Models/money/Item';
import type {KprPermissions} from '@Models/rvt/kpr/Application';
import {computed} from 'vue';
import {some} from 'lodash-es';
import {useConstantsStore} from '@/stores/constants';
import type {FormRef} from '@/vue';
import {MoneyInventoryTypeValues, MoneyItemStoreSchema, MoneyItemUpdateSchema, MoneyPrioritySchema} from '@/api/models';

const {item, permissions, visible = true} = defineProps<{
    item: Item,
    permissions: KprPermissions,
    visible?: boolean
}>();

const dirty = defineModel<boolean>('dirty');
const form = defineModel<FormRef>('form');

const flags = useConstantsStore().constants.money.flags;

const isCamp = computed(() => {
    return some(item.flags, flag => flag.name === "szállás") && item.type === "k";
});
</script>
